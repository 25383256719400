import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const SafeSetup = ({ show, handleShow, id, personList }) => {
  const { t } = useTranslation();
  const { tab16, tab17, tab18 } = t("extra");
  const navigate = useNavigate();
  const cookee = new Cookies();
  const token = cookee.get("token");
  const [setupName, setSetupName] = useState("");
  const handleChange = (e) => {
    setSetupName(e.target.value);
  };
  const handleSafesetup = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      data: {
        set_up_name: setupName,
        event_type: 1,
        person: personList,
        band_staff: [
          {
            name: "abc",
            country_code: "+91",
            phone_number: 6495644488,
            email: "bhup@gmail.com",
            role: "tabla",
            description: "abv",
          },
        ],
      },
      url: `${process.env.REACT_APP_URL}/event-save-setup`,
    })
      .then((res) => {
        //console.log(res.data)
        if (res.data.message === "Musician Added Successfully!.") {
          toast.success("Added", {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          handleShow();
        }
      })
      .catch((error) => {
        if (error.response.data.message === "Internal server Error!") {
          toast.error(tab18, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };
  return (
    <>
      <Modal show={show} onHide={handleShow} size="md">
        <Modal.Body className="p-0">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close close-Icon"
              onClick={handleShow}
            ></button>
            <h4 className="mt-2">{tab16}</h4>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div className="d-flex align-items-center lg-justify-content-end mx-3">
                  <input
                    type="text"
                    className="form-control p-0 ml-3"
                    value={setupName}
                    onChange={handleChange}
                    placeholder="Setup Name"
                    style={{ height: "40px", borderRadius: "10px" }}
                  />
                </div>
                <div className="align-items-center my-3">
                  <button
                    className="btn add-new"
                    style={{ marginLeft: "160px" }}
                    onClick={handleSafesetup}
                  >
                    {tab17}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default SafeSetup;
