import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { getSongs } from "../../app_redux/slice/songList";
import { ToastContainer, toast } from "react-toastify";
import ButtonLoader from "../Loader/loading";

const DeleteSongModal = ({
  show,
  handleShow,
  id,
  pageNo,
  tab11,
  tab12,
  tab13,
  tab26,
}) => {
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const token = cookee.get("token");
  const [loader, setLoader] = useState(false);

  const handleDeleteEvent = () => {
    setLoader(true);
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/delete-band-leader-song`,
      data: {
        song_id: id?.toString(),
      },
    })
      .then((res) => {
        //console.log("res", res.data)
        if (res.status === 200) {
          toast.success(tab26, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoader(false);
          dispatch(getSongs({ pageno: pageNo }));
          handleShow();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoader(false);
      });
  };
  return (
    <>
      <Modal show={show} onHide={handleShow} size="md" centered>
        <Modal.Body className="p-0">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close close-Icon"
              onClick={handleShow}
            ></button>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div className="text-center mx-4">
                  <h5>{tab11}</h5>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                <div className=" text-center my-3 mt-5">
                  <button className="btn add-new-cancel" onClick={handleShow}>
                    {tab12}
                  </button>
                  {loader ? (
                    <ButtonLoader view="btn add-new-del" />
                  ) : (
                    <button
                      className="btn add-new-del"
                      onClick={handleDeleteEvent}
                    >
                      {tab13}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default DeleteSongModal;
