import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEventChatList } from "../../app_redux/slice/eventChatlist";
import { getEventChats } from "../../app_redux/slice/eventChat";
import { getBandChats } from "../../app_redux/slice/bandChat";
import { getTaskChatList } from "../../app_redux/slice/taskChatlist";
import { getTaskChats } from "../../app_redux/slice/taskChat";
import { useTranslation } from "react-i18next";

const ChatListItemsTask = ({
  setEventId,
  band,
  event,
  task,
  setTaskid,
  setTaskName,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { tab3, tab4 } = t("chat");
  const { allTaskChatList } = useSelector((state) => state.taskchatlist);

  useEffect(() => {
    dispatch(getEventChatList());
    dispatch(getTaskChatList());
  }, []);

  const handleChatTaskClick = (taskid, name) => {
    setTaskid(taskid);
    setTaskName(name);
    dispatch(getTaskChats({ taskid: taskid }));
    // setInterval(function () { dispatch(getTaskChats({ taskid: taskid })) }, 3000)
  };

  return (
    <>
      {allTaskChatList?.length > 0 ? (
        allTaskChatList?.map((res, i) => {
          return (
            <div className="chatList px-2 MsgActive" key={i}>
              <div
                className="d-flex align-items-center gap-2"
                onClick={() =>
                  handleChatTaskClick(res.task_id, res.task_chat_name)
                }
              >
                <div className="position-relative">
                  {/* <img src="assets/images/profile.png" alt="" className="chatProfile" /> */}
                  <span className="activeUser"></span>
                </div>
                <div className="">
                  <h6 className="text-capitalize mb-0 notranslate">
                    {res?.task_chat_name}
                  </h6>
                  <p className="ellip-2 sortMsg notranslate">
                    {res?.last_message}
                  </p>
                </div>
              </div>
              {/* <div className="text-center">
                            <small className="white-space">{res?.created_at
                                ? new Date(
                                    res?.last_message_send_receive_at
                                ).toLocaleString()
                                : "-"}</small>
                        </div> */}
            </div>
          );
        })
      ) : (
        <p>{tab3}</p>
      )}
    </>
  );
};

export default ChatListItemsTask;
