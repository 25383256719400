import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import Textfile from "./textfiles";
import Musicfile from "./musicfiles";
import Audiofile from "./audiofile";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { getSongDetail } from "../../app_redux/slice/songDetail";
import { useTranslation } from "react-i18next";

const EditSong = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const token = cookee.get("token");
  const lng = cookee.get("googtrans");
  const navigate = useNavigate();
  const { songDetails } = useSelector((state) => state.songDetail);
  const [numPages, setNumPages] = useState(null);
  const [copyrightCover, setCopyrightCover] = useState("");
  const [showCover, setShowCover] = useState(false);
  const [showCopyright, setShowCopyright] = useState(false);
  const [person, setPerson] = useState([]);
  const [status, setStatus] = useState(songDetails.is_copyright);
  const [showTextfiles, setShowTextfiles] = useState(false);
  const [showMusicfiles, setShowMusicfiles] = useState(false);
  const [showAudiofile, setShowAudiofile] = useState(false);
  const {
    tab1,
    tab2,
    tab3,
    tab4,
    tab5,
    tab6,
    tab7,
    tab8,
    tab9,
    tab10,
    tab11,
    tab14,
    tab15,
    tab16,
    tab17,
  } = t("songs");

  useEffect(() => {
    if (state.data.id) {
      dispatch(getSongDetail({ id: state.data.id }));
    }
  }, [state.data.id]);

  useEffect(() => {
    if (songDetails.is_copyright === 1) {
      setShowCopyright(true);
      setCopyrightCover(1);
    } else {
      setShowCover(true);
      setCopyrightCover(0);
    }
    getPersons();
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const handleShowTextfile = () => {
    setShowTextfiles(!showTextfiles);
  };
  const handleShowMusicfile = () => {
    setShowMusicfiles(!showMusicfiles);
  };
  const handleShowAudiofile = () => {
    setShowAudiofile(!showAudiofile);
  };
  const handleTextfiles = (e) => {
    e.preventDefault();
    handleShowTextfile();
  };
  const handleMusicfiles = (e) => {
    e.preventDefault();
    handleShowMusicfile();
  };
  const handleAudiofile = (e) => {
    e.preventDefault();
    handleShowAudiofile();
  };
  const initialValues = {
    songName: songDetails.song_name || "",
    songTime: songDetails?.time_length?.substr(3, 6) || "",
    bpm: songDetails.bpm || "",
    gemaNumber: songDetails.gemma_number || "",
    isrc: songDetails.isrc_code || "",
    texter:
      songDetails.is_copyright == 1
        ? songDetails.texter + " " + songDetails?.texter_last_name
        : songDetails.texter,
    texterLastname: songDetails.texter_last_name || "",
    composer:
      songDetails.is_copyright == 1
        ? songDetails.composer + " " + songDetails?.composer_last_name
        : songDetails.composer,
    composerLastname: songDetails.composer_last_name || "",
    copyrightsCover: songDetails.is_copyright || "",
    audio: songDetails.song_url || "",
    musicfile: songDetails.music_sheet_url || "",
    textfile: songDetails.text_sheet_url || "",
    url: songDetails.external_url || "",
  };
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      onSubmit: (values, action) => {
        handleUpdateSong();
      },
    });

  const handleCopyright = () => {
    setShowCopyright(true);
    setShowCover(false);
    setStatus(1);
  };
  const handleCover = () => {
    setShowCover(true);
    setShowCopyright(false);
    setStatus(0);
  };
  const getPersons = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-staff-members-with-membership`,
      data: {
        page_no: 1,
      },
    }).then((res) => {
      // console.log(res.data)
      setPerson(res.data.data);
    });
  };

  console.log("values@@ composer", values.textfile);
  console.log("values@@ texter", values.musicfile);
  console.log("url", values.url);

  const handleUpdateSong = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-band-leader-song`,
      data: {
        song_id: songDetails.m_song_id || "",
        user_type: 2,
        song_name: values.songName,
        time_length: "00:" + values.songTime,
        is_copyright: copyrightCover,
        bpm: values.bpm,
        gemma_number: values.gemaNumber,
        isrc_code: values.isrc,
        texter: values.texter?.split(" ")[0],
        texter_last_name:
          copyrightCover == 1
            ? values.texter?.split(" ")[1]
            : values?.texterLastname,
        composer: values.composer?.split(" ")[0],
        composer_last_name:
          copyrightCover == 1
            ? values.composer?.split(" ")[1]
            : values?.composerLastname,
        song_url: values.audio,
        text_sheet_url: values.textfile,
        music_sheet_url: values.musicfile,
        external_url: values.url,
      },
    })
      .then((res) => {
        // console.log("res", res.data)
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          navigate("/songs");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row mt-3 align-items-center">
            <div className="col-sm-12">
              <form className="row my-5 py-5 addNAme mx-width">
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <input
                    type="text"
                    name="songName"
                    value={values.songName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    placeholder="Name Of Song"
                  />
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <input
                    type="text"
                    name="songTime"
                    value={values.songTime}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    placeholder="Time Of Song [00:00]"
                  />
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <input
                    type="text"
                    name="bpm"
                    value={values.bpm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    placeholder="Bpm"
                  />
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <input
                    type="text"
                    name="gemaNumber"
                    value={values.gemaNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    placeholder={
                      lng === "/auto/de" || "/en/de"
                        ? "GEMA Werknummer"
                        : "Gema number"
                    }
                  />
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <input
                    type="text"
                    name="isrc"
                    value={values.isrc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    placeholder="ISRC"
                  />
                </div>
                <div
                  className="col-sm-6 col-md-3 col-lg-3 mb-5 my-4"
                  onClick={handleCopyright}
                >
                  <img
                    src="assets/images/Infoblack.png"
                    alt=""
                    className="mb-5"
                    title={
                      lng === "/auto/de" || "/en/de"
                        ? "Wählen Sie diese Option, wenn Urheberechte des Songs bei einem oder mehreren Bandmitgliedern liegen. Anschließend können Sie für die Rechte am Text und der Komposition ein Bandmtiglied als Urheber auswählen. Bei einem bestehen GEMA-Abwicklungsvertrag mit CleverMusik, werden diese Informationen, bei Konzertmeldungen, an die GEMA übermittelt."
                        : "Select this option if the song is owned by one or more band members. You can then select a band member as the author for the rights to the text and the composition. If there is a GEMA processing agreement with CleverMusik, this information will be sent to GEMA for concert reports."
                    }
                  />
                  <input
                    type="radio"
                    name="song status"
                    value="1"
                    checked={status == 1}
                    style={{ width: "50px", height: "20px" }}
                    onChange={(e) => setCopyrightCover(e.target.value)}
                  />
                  <label className="">Copyrights</label>
                </div>
                <div
                  className="col-sm-6 col-md-3 col-lg-3 mb-5 my-4"
                  onClick={handleCover}
                >
                  <img
                    src="assets/images/Infoblack.png"
                    alt=""
                    className="mb-5"
                    title={
                      lng === "/auto/de" || "/en/de"
                        ? "Wählen Sie diese Option, wenn keine Urheberechte des Songs bei einem Bandmitglied liegen. Anschließend können Sie die Namen Urheber für die Rechte am Text und der Komposition eintragen. Bei einem bestehen GEMA-Abwicklungsvertrag mit CleverMusik, werden diese Informationen, bei Konzertmeldungen, an die GEMA übermittelt."
                        : "Select this option if no band member owns the copyright to the song. You can then enter the names of the authors for the rights to the text and the composition. If there is a GEMA processing agreement with CleverMusik, this information will be sent to GEMA for concert reports."
                    }
                  />
                  <input
                    type="radio"
                    name="song status"
                    value="0"
                    checked={status == 0}
                    style={{ width: "50px", height: "20px" }}
                    onChange={(e) => setCopyrightCover(e.target.value)}
                  />
                  <label>Coversong</label>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <img
                    src="assets/images/Infoblack.png"
                    alt=""
                    className="mx-2"
                    title="Any Text file for a song."
                  />
                  <div className="upload-btn-wrapper Attachment">
                    <button className="btn" onClick={handleTextfiles}>
                      {tab7}
                    </button>
                    <img
                      src="assets/images/live-file.png"
                      alt=""
                      className="inputImgRight"
                    />
                    {values?.textfile ? (
                      <a href={values.textfile}>
                        {" "}
                        <img
                          src="assets/images/gallery.png"
                          width="25px"
                          alt=""
                          className="mt-2"
                        />{" "}
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {showCover ? (
                  <>
                    <div className="col-sm-3 col-md-3 col-lg-3 mb-5 mt-2">
                      <div className="upload-btn-wrapper Attachment">
                        <p>
                          <img
                            src="assets/images/Infoblack.png"
                            alt=""
                            className=""
                            title={
                              lng === "/auto/de" || "/en/de"
                                ? "Wenn Sie die Option „Urheberechte in der Band“ ausgewählt haben, können Sie ein Bandmitglied im Dropdown-Menü auswählen. Wenn Sie die Option „Coversong“ ausgewählt haben, können Sie den Namen eines Textdichters hier manuell eintragen."
                                : "If you selected the Copyrights in the Band option, you can select a band member from the drop-down menu. If you selected the Cover Song option, you can enter the name of a lyricist here manually"
                            }
                          />
                          Texter
                        </p>
                        <input
                          type="text"
                          name="texter"
                          value={values.texter}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 mb-5 ">
                      <div className="upload-btn-wrapper Attachment">
                        <p className="notranslate mt-5"></p>
                        <input
                          type="text"
                          name="texterLastname"
                          value={values.texterLastname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {showCopyright ? (
                  <>
                    <div className="col-sm-6 col-md-6 col-lg-6 mb-5 ">
                      <div className="upload-btn-wrapper Attachment">
                        <p className="noTranslate">
                          {" "}
                          <img
                            src="assets/images/Infoblack.png"
                            alt=""
                            className=""
                            title={
                              lng === "/auto/de" || "/en/de"
                                ? "Wenn Sie die Option „Urheberechte in der Band“ ausgewählt haben, können Sie ein Bandmitglied im Dropdown-Menü auswählen. Wenn Sie die Option „Coversong“ ausgewählt haben, können Sie den Namen eines Textdichters hier manuell eintragen."
                                : "If you selected the Copyrights in the Band option, you can select a band member from the drop-down menu. If you selected the Cover Song option, you can enter the name of a lyricist here manually"
                            }
                          />
                          Texter
                        </p>
                        <select
                          className="form-control p-0"
                          name="texter"
                          value={values.texter}
                          onChange={handleChange}
                        >
                          <option>{tab14}</option>
                          {person.map((res, key) => {
                            return (
                              <option
                                value={`${res.first_name} ${res?.last_name}`}
                                className="notranslate"
                              >{`${res.first_name} ${res?.last_name}`}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* <div className="col-sm-3 col-md-3 col-lg-3 mb-5 mt-4 ">
                                            <div className="upload-btn-wrapper Attachment">
                                                <p></p>
                                                <input className="form-control p-0" name="texterLastname" value={values.texter} />
                                            </div>
                                        </div> */}
                  </>
                ) : (
                  ""
                )}

                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <img
                    src="assets/images/Infoblack.png"
                    alt=""
                    className="mx-2"
                    title="Any music sheet for a song."
                  />
                  <div className="upload-btn-wrapper Attachment">
                    <button
                      className="btn"
                      onClick={handleMusicfiles}
                      style={{ cursor: "pointer" }}
                    >
                      {tab8}
                    </button>
                    <img
                      src="assets/images/attachment.png"
                      alt=""
                      className="inputImgRight"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  {values?.musicfile ? (
                    <a href={values.musicfile}>
                      {" "}
                      <img
                        src="assets/images/gallery.png"
                        width="25px"
                        alt=""
                        className="mt-2"
                      />{" "}
                    </a>
                  ) : (
                    ""
                  )}
                </div>
                {showCover ? (
                  <>
                    <div className="col-sm-3 col-md-3 col-lg-3 mb-5 mt-2">
                      <div className="upload-btn-wrapper Attachment">
                        <p>
                          <img
                            src="assets/images/Infoblack.png"
                            alt=""
                            className=""
                            title={
                              lng === "/auto/de" || "/en/de"
                                ? "Wenn Sie die Option „Urheberechte in der Band“ ausgewählt haben, können Sie ein Bandmitglied im Dropdown-Menü auswählen. Wenn Sie die Option „Coversong“ ausgewählt haben, können Sie den Namen eines Komponisten hier manuell eintragen."
                                : "If you selected the Copyrights in the Band option, you can select a band member from the drop-down menu. If you selected the Cover Song option, you can enter the name of a lyricist here manually"
                            }
                          />
                          {tab10}
                        </p>
                        <input
                          type="text"
                          name="composer"
                          value={values.composer}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 mb-5 ">
                      <div className="upload-btn-wrapper Attachment">
                        <p className="notranslate mt-5"></p>
                        <input
                          type="text"
                          name="composerLastname"
                          value={values.composerLastname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {showCopyright ? (
                  <>
                    <div className="col-sm-6 col-md-6 col-lg-6 mb-5 ">
                      <div className="upload-btn-wrapper Attachment">
                        <p className="noTranslate">
                          <img
                            src="assets/images/Infoblack.png"
                            alt=""
                            className=""
                            title={
                              lng === "/auto/de" || "/en/de"
                                ? "Wenn Sie die Option „Urheberechte in der Band“ ausgewählt haben, können Sie ein Bandmitglied im Dropdown-Menü auswählen. Wenn Sie die Option „Coversong“ ausgewählt haben, können Sie den Namen eines Komponisten hier manuell eintragen."
                                : "If you selected the Copyrights in the Band option, you can select a band member from the drop-down menu. If you selected the Cover Song option, you can enter the name of a lyricist here manually"
                            }
                          />
                          {tab15}
                        </p>
                        <select
                          className="form-control p-0"
                          name="composer"
                          value={values.composer}
                          onChange={handleChange}
                        >
                          <option>{tab14}</option>
                          {person.map((res, key) => {
                            return (
                              <option
                                value={`${res.first_name} ${res?.last_name}`}
                                className="notranslate"
                              >{`${res.first_name} ${res?.last_name}`}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* <div className="col-sm-3 col-md-3 col-lg-3 mb-5 mt-4 ">
                                            <div className="upload-btn-wrapper Attachment">
                                                <p></p>
                                                <input className="form-control p-0" name="composerLastname" value={values.composer} />


                                            </div>
                                        </div> */}
                  </>
                ) : (
                  ""
                )}

                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <img
                    src="assets/images/Infoblack.png"
                    alt=""
                    className="mx-2"
                    title="Any audio file for a song."
                  />
                  <div className="upload-btn-wrapper Attachment">
                    <button
                      className="btn"
                      onClick={handleAudiofile}
                      style={{ cursor: "pointer" }}
                    >
                      {tab9}
                    </button>
                    <img
                      src="assets/images/live-mic.png"
                      alt=""
                      className="inputImgRight"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
                {/* <Document file={songDetails.music_sheet_url} onLoadSuccess={onDocumentLoadSuccess}>
                                <Page pageNumber={pageNumber} />
                            </Document> */}

                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <div className="upload-btn-wrapper Attachment"></div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 mb-5">
                  <img
                    src="assets/images/Infoblack.png"
                    alt=""
                    className="mx-2"
                    title="Any link or url for a song."
                  />
                  <input
                    type="text"
                    name="url"
                    value={values.url}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    placeholder="Link"
                  />
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                  <div className="d-flex justify-content-center my-3">
                    <button className="btn add-new" onClick={handleSubmit}>
                      {lng === "/auto/de" || "/en/de" ? "SPEICHERN" : "safe"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </article>
      {showTextfiles ? (
        <Textfile
          show={showTextfiles}
          handleShow={handleShowTextfile}
          id={songDetails?.m_song_id}
          name={songDetails.song_name}
        />
      ) : (
        ""
      )}
      {showMusicfiles ? (
        <Musicfile
          show={showMusicfiles}
          handleShow={handleShowMusicfile}
          id={songDetails?.m_song_id}
          name={songDetails.song_name}
        />
      ) : (
        ""
      )}
      {showAudiofile ? (
        <Audiofile
          show={showAudiofile}
          handleShow={handleShowAudiofile}
          name={songDetails.song_name}
          audio={songDetails?.song_url}
          id={songDetails?.m_song_id}
          songName={values.songName}
          songTime={values.songTime}
          copyrightCover={copyrightCover}
          bpm={values.bpm}
          gemaNumber={values.gemaNumber}
          isrc={values.isrc}
          texter={values.texter}
          texterLastname={values.texterLastname}
          composer={values.composer}
          composerLastname={values.composerLastname}
          url={values.url}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default EditSong;
