import React, { useState, useMemo, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import Select from "react-select";
import SetListModal from "./setListModal";
import CalendarView from "../../Calendar/calendar";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SafeSetup from "./safeSetupModal";
import LoadSetup from "./loadsetupModal";
import { getSongs } from "../../../app_redux/slice/setlistSong";
import AddPassengers from "./addPassengers";
import OutgoingInvoice from "./outgoingInvoice";
import IncomingInvoice from "./incomingInvoice";
import IncomingContract from "./incomingContract";
import DocumentBooker from "./documentBooker";
import SoundEngineer from "./soundEngineer";
import OtherAttachments from "./otherAttachments";
import InvitationModal from "./invitationModal";
import ButtonLoader from "../../Loader/loading";
import { useTranslation } from "react-i18next";
import { getPermissionInfo } from "../../../app_redux/slice/permissionInfo";

window.Buffer = window.Buffer || require("buffer").Buffer;

const EventDates = () => {
  const { t } = useTranslation();
  const cookee = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const lng = cookee.get("googtrans");
  const [event_id, setEvent_Id] = useState("");
  const [info, setInfo] = useState(true);
  const [band, setBand] = useState(false);
  const [transport, setTransport] = useState(false);
  const [setlist, setSetList] = useState(false);
  const [attachment, setAttachemnt] = useState(false);
  const [eventName, setEventName] = useState("");
  const [addBandStaff, setAddBandStaff] = useState(false);
  const [calendar, setCalendar] = useState(false);

  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [file3, setFile3] = useState("");
  const [file4, setFile4] = useState("");
  const [file5, setFile5] = useState("");
  const [file6, setFile6] = useState("");

  const [getInStart, setGetInStart] = useState("");
  const [getInEnd, setGetInEnd] = useState("");

  const [loadStart, setLoadStart] = useState("");
  const [loadEnd, setLoadEnd] = useState("");
  const [soundStart, setSoundStart] = useState("");
  const [soundEnd, setSoundEnd] = useState("");
  const [stageStart, setStageStart] = useState("");
  const [stageEnd, setStageEnd] = useState("");
  const [locationName, setLocationName] = useState("");
  const [locationStreet, setLocationStreet] = useState("");
  const [locationHouseNo, setLocationHouseNo] = useState("");
  const [locationPostal, setLocationPostal] = useState("");
  const [locationTown, setLocationTown] = useState("");
  const [locationContactPerson, setLocationContactPerson] = useState("");
  const [locationEmail, setLocationEmail] = useState("");
  const [locationPhone, setLocationPhone] = useState("");
  const [locationOtherInfo, setLocationOtherInfo] = useState("");
  const [eventOtherInfo, setEventOtherInfo] = useState("");
  const [infoOrganizerName, setInfoOrganizerName] = useState("");
  const [infoOrganizerStreet, setInfoOrganizerStreet] = useState("");
  const [infoOrganizerHouseNo, setInfoOrganizerHouseNo] = useState("");
  const [infoOrganizerPostal, setInfoOrganizerPostal] = useState("");
  const [infoOrganizerTown, setInfoOrganizerTown] = useState("");
  const [infoOrganizerNameContact, setInfoOrganizerNameContact] = useState("");
  const [infoOrganizerEmail, setInfoOrganizerEmail] = useState("");
  const [infoOrganizerPhone, setInfoOrganizerPhone] = useState("");
  const [infoOrganizerGemaNo, setInfoOrganizerGemaNo] = useState("");
  const [infoOrganizerCountry, setInfoOrganizerCountry] = useState("");
  const [infoOrganizerInfo, setInfoOrganizerInfo] = useState("");

  const [infoAccommodationName, setInfoAccommodationName] = useState("");
  const [infoAccommodationStreet, setInfoAccommodationStreet] = useState("");
  const [infoAccommodationPostal, setInfoAccommodationPostal] = useState("");
  const [infoAccommodationHouseNo, setInfoAccommodationHouseNo] = useState("");
  const [infoAccommodationTown, setInfoAccommodationTown] = useState("");
  const [infoAccommodationEmail, setInfoAccommodationEmail] = useState("");
  const [infoAccommodationPhone, setInfoAccommodationPhone] = useState("");
  const [infoAccommodationOther, setInfoAccommodationOther] = useState("");
  const [parking, setParking] = useState("");
  const [guestList, setGuestList] = useState("");
  const [catering, setCatering] = useState("");
  const [sonitageInfo, setSonitageInfo] = useState("");

  const [passenger, setPassenger] = useState([{ passenger_name: "" }]);
  const [driverList, setDriverList] = useState([
    { driver: "", vehicle: "", available_seats: "", passengers: [] },
  ]);
  const [personList, setPersonList] = useState([
    {
      role: "",
      person: [
        { user_id: "", priority: 1, amount: "", additional_information: "" },
      ],
    },
  ]);
  // const [bandStaffList, setBandStaffList] = useState([{ role: "", staff: [{ user_id: "", priority: "", amount: "", additional_information: "" }] }])
  const [bandStaffList, setBandStaffList] = useState([
    { user_id: "", amount: "", role: "", description: "" },
  ]);
  const [setListData, setSetListData] = useState([]);
  const [priorityShow, setPriorityShow] = useState(false);
  const [personInputId, setPersonId] = useState("");
  const [date, setDate] = useState("");
  const [priorityCount, setPriorityCount] = useState(2);

  const [file, setFile] = useState("");
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const [show, setShow] = useState(false);
  // const options = useMemo(() => countryList().getData(), [])
  const [selectSetlist, setSelectSetlist] = useState(false);
  const [bandPerson, setBandPerson] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showPassengerModal, setShowPassengerModal] = useState(false);

  const [view, setView] = useState(false);
  let { allSetlistSongs, loading } = useSelector((state) => state.setlistsong);
  const token = cookee.get("token");
  const [identity, setIdentity] = useState("");
  const [pageNo, setPageNo] = useState(10);
  const [setlistId, setSetlistId] = useState("");
  const [showLoadsetup, setShowLoadsetup] = useState(false);
  const [loadsetupPersonList, setLoadsetupPersonList] = useState([]);
  const [deleteShow, setDeletedShow] = useState(false);
  const [eventType, setEventType] = useState("");
  const [performanceType, setPerformanceType] = useState("");
  const [bandmember, setBandMember] = useState([]);
  const [bandmember2, setBandMember2] = useState([]);
  const [disableButton, setDisableButton] = useState("");
  const [setlistLength, setSetlistLength] = useState("");
  const [disabled, setDisabled] = useState([]);
  const [OutgoingInvoiceFile, setOutgoingInvoiceFile] = useState(false);
  const [incomingInvoiceFile, setIncomingInvoiceFile] = useState(false);
  const [incomingContractFile, setIncomingContractFile] = useState(false);
  const [documentBookerFile, setDocumentBookerFile] = useState(false);
  const [documentSoundFile, setDocumentSoundFile] = useState(false);
  const [otherFile, setOtherFile] = useState(false);
  const [invitationShow, setInvitationShow] = useState(false);
  const [addMusicianShow, setAddMusicianshow] = useState(true);
  const [personSelectedList, setPersonSelectedList] = useState([]);
  const [bandStaffSelectedList, setBandStaffSelectedList] = useState([]);
  const [newLoad, setNewLoad] = useState([
    {
      role: "",
      person: [
        { user_id: "", priority: 1, amount: "", additional_information: "" },
      ],
    },
  ]);
  const [load1, setLoad1] = useState(false);
  const [showtext, setShowtext] = useState("");
  const {
    tab1,
    tab2,
    tab3,
    tab4,
    tab5,
    tab6,
    tab7,
    tab8,
    tab9,
    tab10,
    tab11,
    tab12,
    tab13,
    tab14,
    tab15,
    tab16,
    tab17,
    tab18,
    tab19,
    tab20,
    tab21,
    tab22,
    tab23,
    tab24,
    tab25,
    tab26,
    tab27,
    tab28,
    tab29,
    tab30,
    tab31,
    tab32,
    tab33,
    tab34,
    tab35,
    tab36,
    tab43,
    tab44,
    tab45,
    tab46,
    tab47,
    tab48,
    tab49,
    tab50,
    tab51,
    tab60,
    tab52,
    tab61,
    tab62,
    tab80,
    tab84,
  } = t("event");

  var german = require("localized-countries")(
    require("localized-countries/data/de")
  );
  const options = german.array();

  const { permissionDetail } = useSelector((state) => state.permission);

  const handleCalendarView = () => {
    setView(!view);
  };

  useEffect(() => {
    getEvents();
    dispatch(getSongs({ id: "0" }));
    dispatch(getPermissionInfo({ id: cookee.get("redirect_userid") }));
  }, [pageNo]);

  const handleEventType = (e) => {
    setEventType(e.target.value);
  };
  const handlePerformanceType = (e) => {
    setPerformanceType(e.target.value);
  };
  const handleShowOutgoingInvoiceFile = () => {
    setOutgoingInvoiceFile(!OutgoingInvoiceFile);
  };
  const handleShowIncomingInvoiceFile = () => {
    setIncomingInvoiceFile(!incomingInvoiceFile);
  };
  const handleShowIncomingContractFile = () => {
    setIncomingContractFile(!incomingContractFile);
  };
  const handleShowDocumentBookerFile = () => {
    setDocumentBookerFile(!documentBookerFile);
  };
  const handleShowDocumentSoundFile = () => {
    setDocumentSoundFile(!documentSoundFile);
  };
  const handleShowOtherFile = () => {
    setOtherFile(!otherFile);
  };
  const handleShowInvitation = () => {
    setInvitationShow(!invitationShow);
  };
  const handleOutgoingfiles = (t) => {
    setShowtext(t);
    handleShowOutgoingInvoiceFile();
  };
  const handleBookerfiles = (t) => {
    setShowtext(t);
    handleShowDocumentBookerFile();
  };
  const handleSoundfiles = (t) => {
    handleShowDocumentSoundFile();
    setShowtext(t);
  };
  const handleOtherfiles = (t) => {
    handleShowOtherFile();
    setShowtext(t);
  };
  const handleContractfiles = (t) => {
    setShowtext(t);
    handleShowIncomingContractFile();
  };
  const handleIncomingfiles = (t) => {
    setShowtext(t);
    handleShowIncomingInvoiceFile();
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...driverList];
    list[index][name] = value;
    setDriverList(list);
  };

  const handleAddClick = () => {
    setDriverList([
      ...driverList,
      { driver: "", vehicle: "", available_seats: "", passengers: [] },
    ]);
  };

  const handleInputPerson = (e, i, index, n) => {
    const { name, value } = e.target;
    const list = [...personList];

    if (name === "role") {
      list[i][name] = value;
    } else {
      list[i].person[index][name] = value;
    }
    setPersonList(list);
    if (n === 1) {
      setPersonSelectedList([...personSelectedList, e.target.value]);
    }
  };

  const handleInputLoadPerson = (e, i, index) => {
    const { name, value } = e.target;
    const old = loadsetupPersonList[i];
    const updated = { ...old, [name]: value };
    const list = [...loadsetupPersonList];
    list[i] = updated;
    setLoadsetupPersonList(list);
  };

  const handleInputBand = (e, i, n) => {
    const { name, value } = e.target;
    const list = [...bandStaffList];
    list[i][name] = value;
    setBandStaffList(list);
    if (n === 1) {
      setBandStaffSelectedList([...bandStaffSelectedList, e.target.value]);
    }
  };
  const handleAddPerson = () => {
    setPriorityCount(2);
    setPersonList([
      ...personList,
      {
        role: "",
        person: [
          { user_id: "", priority: 1, amount: "", additional_information: "" },
        ],
      },
    ]);
  };
  const handleAddPriority = (e, role, i) => {
    setPriorityCount(priorityCount + 1);
    const { name, value } = e.target;
    setPriorityShow(true);
    setIdentity(i);
    let list = [...personList];
    list[i].person = [
      ...personList[i].person,
      {
        user_id: "",
        priority: priorityCount,
        amount: "",
        additional_information: "",
      },
    ];

    // setPersonList([ { person: [{ user_id: "", priority: "", amount: "", additional_information: "" }] }])
    setPersonList(list);
  };

  const handleAddBandPerson = () => {
    setBandStaffList([
      ...bandStaffList,
      { user_id: "", amount: "", role: "", description: "" },
    ]);
  };

  const handleShowCancel = () => {
    setShowCancelModal(!showCancelModal);
  };
  const handleShowPassenger = () => {
    setShowPassengerModal(!showPassengerModal);
  };
  const handleShowLoadsetup = () => {
    setShowLoadsetup(!showLoadsetup);
  };
  const handleRemove = (index) => {
    const list = [...driverList];
    list.splice(index, 1);
    setDriverList(list);
  };
  const handleRemoveLoadperson = (i, index) => {
    const list = [...loadsetupPersonList];
    list.splice(i, 1);
    setLoadsetupPersonList(list);
  };

  const handleRemovePerson = (personListIndex, personIndex, id) => {
    setPersonList((prevPersonList) => {
      return prevPersonList.map((item, i) => {
        if (i === personListIndex) {
          return {
            ...item,
            person: item.person.filter((_, index) => index !== personIndex),
          };
        }
        return item;
      });
    });
    setPersonSelectedList((prevPersonList) => {
      return prevPersonList.filter((res) => res !== id);
    });
  };
  const handleRemovePersonList = (personListIndex, id) => {
    setPersonList((prevPersonList) => {
      return prevPersonList.filter((_, index) => index !== personListIndex);
    });
    setPersonSelectedList((prevPersonList) => {
      return prevPersonList.filter((res) => res !== id);
    });
  };

  const handleRemoveStaff = (i, index) => {
    const list = [...bandStaffList];
    list.splice(i, 1);
    setBandStaffList(list);
  };

  const getEvents = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-all-musicians-list`,
      data: {
        page_no: pageNo,
      },
    }).then((res) => {
      // console.log(res.data)
      setBandPerson(res.data.data);
    });
  };
  const getBandMembers = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-staff-members-with-membership`,
      data: {
        page_no: 1,
        is_staff: 0,
        event_id: event_id,
      },
    }).then((res) => {
      // console.log(res.data)
      setBandMember(res.data.data);
    });
  };
  const getBandMembersTwo = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-staff-members-with-membership`,
      data: {
        page_no: 1,
        is_staff: 1,
        event_id: event_id,
      },
    }).then((res) => {
      // console.log(res.data)
      setBandMember2(res.data.data);
    });
  };

  const showSetlist = () => {
    setSelectSetlist(!selectSetlist);
  };

  const changeHandler = (value) => {
    setValue1(value.label);
  };
  // console.log("22",value1)
  const changeHandler1 = (value) => {
    setValue2(value.label);
  };
  const changeHandler2 = (value) => {
    setValue3(value.label);
  };
  const handleCancelEvent = () => {
    handleShowCancel();
  };
  const handlePassengers = () => {
    handleShowPassenger();
  };

  const handleInfo = () => {
    setInfo(true);
    setBand(false);
    setTransport(false);
    setSetList(false);
    setAttachemnt(false);
  };

  function checkDate() {
    var selectedDate = new Date(date);
    var now = new Date();
    if (selectedDate < now) {
      toast.error("Date must be in future", {
        theme: "colored",
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    } else {
      return true;
    }
  }

  const handleEventInfo = (e) => {
    e.preventDefault();
    if (checkDate()) {
      setLoad1(true);
      axios({
        method: "post",
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          auth_token: token,
        },
        url: `${process.env.REACT_APP_URL}/create-event-info`,
        data: {
          user_id: permissionDetail?.user_id,
          event_name: eventName,
          info_organizer: [
            {
              organizer_name: infoOrganizerName,
              organizer_street: infoOrganizerStreet,
              organizer_house_number: infoOrganizerHouseNo,
              organizer_postal: infoOrganizerPostal,
              organizer_town: infoOrganizerTown,
              contact_person_name: infoOrganizerNameContact,
              email: infoOrganizerEmail,
              phone_number: infoOrganizerPhone,
              gema_customer_number: infoOrganizerGemaNo,
              organizer_country: value2,
              organizer_other_information: infoOrganizerInfo,
            },
          ],
          info_accommodation: [
            {
              accomodation_name: infoAccommodationName,
              accomodation_street: infoAccommodationStreet,
              accomodation_house_number: infoAccommodationHouseNo,
              accomodation_postal: infoAccommodationPostal,
              accomodation_town: infoAccommodationTown,
              accomodation_country: value3,
              accomodation_phone_number: infoAccommodationPhone,
              accomodation_email: infoAccommodationEmail,
              accomodation_other_information: infoAccommodationOther,
            },
          ],
          start_time: getInStart,
          end_time: "",
          load_start_time: loadStart,
          load_end_time: loadEnd,
          sound_start_time: soundStart,
          sound_end_time: soundEnd,
          stage_start_time: stageStart,
          stage_end_time: stageEnd,
          type_of_event: eventType,
          type_of_performance: performanceType,
          start_date: date,
          latitude: 15,
          longitude: 109.77,
          location_name: locationName,
          location_street: locationStreet,
          loc_name_of_person: locationContactPerson,
          location_email: locationEmail,
          location_phone_number: locationPhone,
          location_other_information: locationOtherInfo,
          town: locationTown,
          event_country: value1,
          event_zip_code: locationPostal,
          event_house_number: locationHouseNo,
          event_type: 1,
          event_other_info: eventOtherInfo,
          parking: parking,
          guest_list: guestList,
          catering: catering,
          sonstige_other_info: sonitageInfo,
          is_without_appointment: 0,
          total_number_of_works: 0,
          number_of_works_with_copyright: 0,
          length_set: "00:00:00",
          is_band:
            permissionDetail?.permissionDetails?.permission_type == 5 ? 0 : 1,
        },
      })
        .then((res) => {
          //  console.log("RESPONSE", res.data)
          if (res.status === 200) {
            setLoad1(false);
            toast.success(tab84, {
              theme: "colored",
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if (permissionDetail?.permissionDetails?.permission_type === 5) {
              navigate("/unconfirmed-events");
            }

            setEvent_Id(res.data.data.event_id);
            setBand(true);
            setInfo(false);
            setTransport(false);
            setSetList(false);
            setAttachemnt(false);
            getBandMembers();
            getBandMembersTwo();
          }
        })
        .catch((error) => {
          setLoad1(false);
          toast.error(error.response.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };
  const handleAddMusicians = (e, id, i) => {
    setDisabled([...disabled, i]);
    e.preventDefault();
    // handleLoad()
    if (
      personList[0].role !== "" ||
      bandStaffList[0].role !== "" ||
      loadsetupPersonList?.length !== 0
    ) {
      axios({
        method: "post",
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          auth_token: token,
        },
        url: `${process.env.REACT_APP_URL}/add-musicians-to-event`,
        data: {
          event_id: event_id,
          event_type: 1,
          person: newLoad[0].role !== "" ? newLoad : personList,
          band_staff: bandStaffList[0].role === "" ? [] : bandStaffList,
        },
      })
        .then((res) => {
          //  console.log("RESPONSE", res.data)
          if (res.status === 200) {
            toast.success(res.data.message, {
              theme: "colored",
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setAddMusicianshow(false);
            getBandMembers();
            getBandMembersTwo();
            handleShowInvitation();
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else if (personList[0].role === "" && bandStaffList[0].role === "") {
      setTransport(true);
      setBand(false);
      setInfo(false);
      setSetList(false);
      setAttachemnt(false);
    }
  };

  const handleAddTransport = (e) => {
    e.preventDefault();
    if (driverList[0].driver !== "" && driverList[0].vehicle !== "") {
      axios({
        method: "post",
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          auth_token: token,
        },
        url: `${process.env.REACT_APP_URL}/add-transport-to-event`,
        data: {
          event_id: event_id,
          event_type: 1,
          transport: driverList,
        },
      })
        .then((res) => {
          //  console.log("RESPONSE", res.data)
          if (res.status === 200) {
            toast.success(res.data.message, {
              theme: "colored",
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTransport(false);
            setBand(false);
            setInfo(false);
            setSetList(true);
            setAttachemnt(false);
          }
        })
        .catch((error) => {
          toast.error("Eingabe erforderlich", {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else if (driverList[0].driver === "" && driverList[0].vehicle === "") {
      setTransport(false);
      setBand(false);
      setInfo(false);
      setSetList(true);
      setAttachemnt(false);
    }
  };

  const handleAddSetlist = (e) => {
    e.preventDefault();
    if (setlistId !== "") {
      axios({
        method: "post",
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          auth_token: token,
        },
        url: `${process.env.REACT_APP_URL}/add-setlist-to-event`,
        data: {
          event_id: event_id,
          event_type: 1,
          set_list: [
            {
              set_list_id: setlistId,
            },
          ],
        },
      })
        .then((res) => {
          //  console.log("RESPONSE", res.data)
          if (res.status === 200) {
            toast.success("Setlist Added", {
              theme: "colored",
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTransport(false);
            setBand(false);
            setInfo(false);
            setSetList(false);
            setAttachemnt(true);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else if (setlistId === "") {
      setTransport(false);
      setBand(false);
      setInfo(false);
      setSetList(false);
      setAttachemnt(true);
    }
  };

  const handleBand = () => {
    setBand(true);
    setInfo(false);
    setTransport(false);
    setSetList(false);
    setAttachemnt(false);
  };
  // console.log("Id", event_id)
  const handleTransport = () => {
    setTransport(true);
    setBand(false);
    setInfo(false);
    setSetList(false);
    setAttachemnt(false);
  };
  const handleSetlist = () => {
    setSetList(true);
    setBand(false);
    setInfo(false);
    setTransport(false);
    setAttachemnt(false);
  };
  const handleAttachment = () => {
    setAttachemnt(true);
    setBand(false);
    setInfo(false);
    setTransport(false);
    setSetList(false);
  };
  const handleChange = (e, setstate) => {
    setstate(e.target.value);
  };

  const handleAdd = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/add-attachment-to-event`,
      data: {
        event_id: event_id,
        event_type: 1,
        event_attachments: [
          {
            attachment_type: 1,
            attachment_url: file1 ? file1 : "",
          },
          {
            attachment_type: 2,
            attachment_url: file2 ? file2 : "",
          },
          {
            attachment_type: 3,
            attachment_url: file3 ? file3 : "",
          },
          {
            attachment_type: 4,
            attachment_url: file4 ? file4 : "",
          },
          {
            attachment_type: 5,
            attachment_url: file5 ? file5 : "",
          },
          {
            attachment_type: 6,
            attachment_url: file6 ? file6 : "",
          },
        ],
      },
    }).then((res) => {
      //  console.log("RESPONSE", res.data)
      if (res.data.message === "Attachements Added Successfully!.") {
        alert("Added!!");
        navigate("/unconfirmed-events");
      }
    });
  };

  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row mt-3 ">
            <div className="col-12 col-sm-9 col-md-9 col-lg-10">
              <ul
                className="nav nav-pills group-nav tabNabs"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <Link
                    to="#"
                    className={info ? "nav-link  active" : "nav-link"}
                    onClick={handleInfo}
                  >
                    information
                  </Link>
                </li>
                {permissionDetail?.permissionDetails?.permission_type == 5 ? (
                  ""
                ) : (
                  <>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={band ? "nav-link  active" : "nav-link"}
                        onClick={handleBand}
                      >
                        band
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={transport ? "nav-link  active" : "nav-link"}
                        onClick={handleTransport}
                      >
                        transport
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={setlist ? "nav-link  active" : "nav-link"}
                        onClick={handleSetlist}
                      >
                        setlist
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#"
                        className={attachment ? "nav-link  active" : "nav-link"}
                        onClick={handleAttachment}
                      >
                        {tab9}
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>

            <div className="col-12 col-sm-3 col-md-3 col-lg-2">
              <div className="d-flex align-items-center lg-justify-content-end">
                <Link to="#" className="btn notiIcon filterIcon">
                  <img
                    src={
                      view
                        ? "assets/images/calendar.png"
                        : "assets/images/calendarGrey.png"
                    }
                    alt=""
                    onClick={handleCalendarView}
                  />
                </Link>
                {/* <Link to="#" className="btn notiIcon filterIcon" ><img src="assets/images/chatBlackRed.png" alt="" />
                                </Link> */}
              </div>
            </div>
            {view ? <CalendarView /> : ""}

            {info ? (
              <div className="col-sm-12">
                <div className="pt-3 px-1 bg-white">
                  <div className="table-responsive">
                    <table className="table storetable infoEventTable">
                      <thead className="">
                        <tr>
                          <th
                            scope="col"
                            title={
                              lng === "/auto/de" || "/en/de"
                                ? "Zum erstellen einer Veranstaltung, müssen Sie den Namen und Datum eintragen. Alle weiteren Informationen können Sie zu einem späteren Zeitpunkt ergänzen. Den zu der Veranstaltung eingeladenen Bandmitglieder werden diese Informationen angezeigt."
                                : "To create an event, you must enter the name and date. You can add any further information at a later date. Band members invited to the event will see this information."
                            }
                          >
                            info event
                            <img
                              src="assets/images/Infoblack.png"
                              alt=""
                              className="mx-1 mb-4"
                            />
                          </th>
                          <th
                            scope="col"
                            title={
                              lng === "/auto/de" || "/en/de"
                                ? "Den zu der Veranstaltung eingeladenen Personen werden diese Informationen angezeigt. Ausgenommen hiervon sind der Name der Kontaktperson, Emailadresse sowie Telefon."
                                : "The people invited to the event will see this information. Exceptions to this are the name of the contact person, email address and telephone."
                            }
                          >
                            {tab13}{" "}
                            <img
                              src="assets/images/Infoblack.png"
                              alt=""
                              className="mx-1 mb-4"
                            />
                          </th>
                          <th
                            scope="col"
                            title={
                              lng === "/auto/de" || "/en/de"
                                ? "Den zu der Veranstaltung eingeladenen Personen werden diese Informationen NICHT angezeigt."
                                : "This information will NOT be visible to those invited to the event."
                            }
                          >
                            {tab14}
                            <img
                              src="assets/images/Infoblack.png"
                              alt=""
                              className="mx-1 mb-4"
                            />
                          </th>
                          <th
                            scope="col"
                            title={
                              lng === "/auto/de" || "/en/de"
                                ? "Den zu der Veranstaltung eingeladenen Personen werden diese Informationen angezeigt"
                                : "The people invited to the event will see this information"
                            }
                          >
                            {tab15}
                            <img
                              src="assets/images/Infoblack.png"
                              alt=""
                              className="mx-1 mb-4"
                            />
                          </th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="form-group mb-3">
                              <label className="header-label">Name</label>
                              <div className="input-container">
                                <input
                                  type="text"
                                  value={eventName}
                                  onChange={(e) =>
                                    handleChange(e, setEventName)
                                  }
                                  required
                                  className="form-control p-0"
                                  placeholder="Name"
                                  name="confirmpassword"
                                  style={{ height: "40px" }}
                                />
                              </div>
                            </div>
                            <div className="form-group mb-3">
                              <label className="header-label">{tab5}</label>
                              <div className="input-container">
                                <input
                                  type="date"
                                  value={date}
                                  onChange={(e) => handleChange(e, setDate)}
                                  className="form-control p-0"
                                  required
                                  placeholder="Date"
                                  name="confirmpassword"
                                  style={{ height: "40px" }}
                                />
                              </div>
                            </div>

                            <p className="notranslate header-label">
                              Get in
                              <div className="d-flex justify-content-between">
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={getInStart}
                                  onChange={(e) =>
                                    handleChange(e, setGetInStart)
                                  }
                                  placeholder="start"
                                  style={{ height: "40px", width: "100px" }}
                                />{" "}
                              </div>
                            </p>

                            <p className="notranslate header-label">
                              Load{" "}
                              <div className="d-flex justify-content-between">
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={loadStart}
                                  onChange={(e) =>
                                    handleChange(e, setLoadStart)
                                  }
                                  placeholder="start"
                                  style={{ height: "40px", width: "100px" }}
                                />{" "}
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={loadEnd}
                                  onChange={(e) => handleChange(e, setLoadEnd)}
                                  placeholder={tab80}
                                  style={{ height: "40px", width: "100px" }}
                                />
                              </div>
                            </p>

                            <p className="notranslate header-label">
                              Soundcheck{" "}
                              <div className="d-flex justify-content-between">
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={soundStart}
                                  onChange={(e) =>
                                    handleChange(e, setSoundStart)
                                  }
                                  placeholder="start"
                                  style={{ height: "40px", width: "100px" }}
                                />{" "}
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={soundEnd}
                                  onChange={(e) => handleChange(e, setSoundEnd)}
                                  placeholder={tab80}
                                  style={{ height: "40px", width: "100px" }}
                                />
                              </div>
                            </p>

                            <p className="notranslate header-label">
                              Stagetime{" "}
                              <div className="d-flex justify-content-between">
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={stageStart}
                                  onChange={(e) =>
                                    handleChange(e, setStageStart)
                                  }
                                  placeholder="start"
                                  style={{ height: "40px", width: "100px" }}
                                />{" "}
                                <input
                                  type="text"
                                  className="form-control p-0"
                                  value={stageEnd}
                                  onChange={(e) => handleChange(e, setStageEnd)}
                                  placeholder={tab80}
                                  style={{ height: "40px", width: "100px" }}
                                />
                              </div>
                            </p>

                            <p>
                              <label className="header-label">{tab6}</label>
                              <select
                                className="form-control p-0 notranslate"
                                onChange={(e) => handleEventType(e)}
                                style={{ height: "40px" }}
                              >
                                <option value="Fasching/Karneval">
                                  Fasching/Karneval
                                </option>
                                <option value="Fest-/Straßenumzüge">
                                  Fest-/Straßenumzüge
                                </option>
                                <option value="Hintergrundmusik">
                                  Hintergrundmusik
                                </option>
                                <option value="Kabarett">Kabarett</option>
                                <option value=" Klassisches Konzert">
                                  {" "}
                                  Klassisches Konzert
                                </option>
                                <option value="Konzert">Konzert</option>
                                <option value="OpenAir Konzert">
                                  OpenAir Konzert
                                </option>
                                <option value="Party/Feier">Party/Feier</option>
                                <option value="Sonstiges">Sonstiges</option>
                                <option value="Tanz/Bälle">Tanz/Bälle</option>
                                <option value="Bühnen-/Theatermusik">
                                  Bühnen-/Theatermusik
                                </option>
                              </select>
                            </p>

                            <p>
                              <label className="header-label">{tab44}</label>
                              <select
                                className="form-control p-0 notranslate"
                                onChange={(e) => handlePerformanceType(e)}
                                style={{ height: "40px", width: "200px" }}
                              >
                                <option value="Hauptgruppe">Hauptgruppe</option>
                                <option value="Vorgruppe">Vorgruppe</option>
                                <option value="Alleinige Band / Esemble">
                                  Alleinige Band
                                </option>
                                <option value="Mehrer gleichberechtigte Bands / Künstler">
                                  Mehrer gleichberechtigte Bands / Künstler
                                </option>
                                <option value="DJ-Live Act">DJ-Live Act</option>
                                <option value="Bühnen-/Theateraufführung">
                                  Theateraufführung
                                </option>
                              </select>
                            </p>
                            <p>
                              <label className="header-label">
                                Information
                              </label>
                              <textarea
                                className="form-control p-0"
                                placeholder={tab45}
                                value={eventOtherInfo}
                                onChange={(e) =>
                                  handleChange(e, setEventOtherInfo)
                                }
                                style={{ height: "100px" }}
                              />
                            </p>
                          </td>
                          <td>
                            <p>
                              <label className="header-label">Name</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationName}
                                onChange={(e) =>
                                  handleChange(e, setLocationName)
                                }
                                placeholder="Name"
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab16}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationStreet}
                                onChange={(e) =>
                                  handleChange(e, setLocationStreet)
                                }
                                placeholder={tab16}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab17}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationHouseNo}
                                onChange={(e) =>
                                  handleChange(e, setLocationHouseNo)
                                }
                                placeholder={tab17}
                                style={{ height: "40px" }}
                              />
                            </p>

                            <p>
                              <label className="header-label">{tab18}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationPostal}
                                onChange={(e) =>
                                  handleChange(e, setLocationPostal)
                                }
                                placeholder={tab18}
                                style={{ height: "40px" }}
                              />{" "}
                            </p>
                            <p>
                              <label className="header-label">{tab19}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationTown}
                                onChange={(e) =>
                                  handleChange(e, setLocationTown)
                                }
                                placeholder={tab19}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label notranslate">
                                Land
                              </label>
                              <Select
                                className="form-control p-0 notranslate "
                                options={options}
                                placeholder="Land"
                                onChange={changeHandler}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab43}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationContactPerson}
                                onChange={(e) =>
                                  handleChange(e, setLocationContactPerson)
                                }
                                placeholder={tab43}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">Email</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationEmail}
                                onChange={(e) =>
                                  handleChange(e, setLocationEmail)
                                }
                                placeholder="Email"
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab60}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={locationPhone}
                                onChange={(e) =>
                                  handleChange(e, setLocationPhone)
                                }
                                placeholder={tab60}
                                style={{ height: "40px" }}
                              />
                            </p>

                            <p>
                              <label className="header-label">
                                information
                              </label>
                              <textarea
                                className="form-control p-0"
                                value={locationOtherInfo}
                                onChange={(e) =>
                                  handleChange(e, setLocationOtherInfo)
                                }
                                placeholder={tab45}
                                style={{ height: "150px" }}
                              />
                            </p>
                          </td>
                          <td>
                            <p>
                              <label className="header-label">Name</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoOrganizerName}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerName)
                                }
                                placeholder="Name"
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab16}</label>
                              <input
                                type="text"
                                value={infoOrganizerStreet}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerStreet)
                                }
                                className="form-control p-0"
                                placeholder={tab16}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab17}</label>
                              <input
                                type="text"
                                value={infoOrganizerHouseNo}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerHouseNo)
                                }
                                className="form-control p-0"
                                placeholder={tab17}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab18}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                placeholder={tab18}
                                value={infoOrganizerPostal}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerPostal)
                                }
                                style={{ height: "40px" }}
                              />{" "}
                            </p>
                            <p>
                              <label className="header-label">{tab19}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                placeholder={tab19}
                                value={infoOrganizerTown}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerTown)
                                }
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label notranslate">
                                Land
                              </label>
                              <Select
                                className="form-control p-0 notranslate"
                                options={options}
                                placeholder="Land"
                                onChange={changeHandler1}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab43}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoOrganizerNameContact}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerNameContact)
                                }
                                placeholder={tab43}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">Email</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoOrganizerEmail}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerEmail)
                                }
                                placeholder="Email"
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab60}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoOrganizerPhone}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerPhone)
                                }
                                placeholder={tab60}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab25}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoOrganizerGemaNo}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerGemaNo)
                                }
                                placeholder="Gema  no"
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">
                                information
                              </label>
                              <textarea
                                className="form-control p-0"
                                value={infoOrganizerInfo}
                                onChange={(e) =>
                                  handleChange(e, setInfoOrganizerInfo)
                                }
                                placeholder={tab45}
                                style={{ height: "100px" }}
                              />
                            </p>
                          </td>
                          <td>
                            <p>
                              <label className="header-label">Name</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoAccommodationName}
                                onChange={(e) =>
                                  handleChange(e, setInfoAccommodationName)
                                }
                                placeholder="Name"
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab16}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoAccommodationStreet}
                                onChange={(e) =>
                                  handleChange(e, setInfoAccommodationStreet)
                                }
                                placeholder={tab16}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab17}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoAccommodationHouseNo}
                                onChange={(e) =>
                                  handleChange(e, setInfoAccommodationHouseNo)
                                }
                                placeholder={tab17}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab18}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoAccommodationPostal}
                                onChange={(e) =>
                                  handleChange(e, setInfoAccommodationPostal)
                                }
                                placeholder={tab18}
                                style={{ height: "40px" }}
                              />{" "}
                            </p>
                            <p>
                              <label className="header-label">{tab19}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoAccommodationTown}
                                onChange={(e) =>
                                  handleChange(e, setInfoAccommodationTown)
                                }
                                placeholder={tab19}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">Land</label>{" "}
                              <Select
                                className="form-control p-0 notranslate"
                                options={options}
                                placeholder="Land"
                                onChange={changeHandler2}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab60}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoAccommodationPhone}
                                onChange={(e) =>
                                  handleChange(e, setInfoAccommodationPhone)
                                }
                                placeholder={tab60}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">Email</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={infoAccommodationEmail}
                                onChange={(e) =>
                                  handleChange(e, setInfoAccommodationEmail)
                                }
                                placeholder="Email"
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">
                                information
                              </label>
                              <textarea
                                className="form-control p-0"
                                value={infoAccommodationOther}
                                onChange={(e) =>
                                  handleChange(e, setInfoAccommodationOther)
                                }
                                placeholder={tab45}
                                style={{ height: "80px" }}
                              />
                            </p>
                            <p>
                              <b
                                title={
                                  lng === "/auto/de" || "/en/de"
                                    ? "Den zu der Veranstaltung eingeladenen Personen werden diese Informationen angezeigt"
                                    : "The people invited to the event will see this information"
                                }
                              >
                                sonstige info
                                <img
                                  src="assets/images/Infoblack.png"
                                  alt=""
                                  className="mx-1 mb-4"
                                />
                              </b>
                            </p>
                            <p>
                              <label className="header-label">{tab20}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                placeholder={tab20}
                                value={parking}
                                onChange={(e) => handleChange(e, setParking)}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">{tab21}</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={guestList}
                                onChange={(e) => handleChange(e, setGuestList)}
                                placeholder={tab21}
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              <label className="header-label">Catering</label>
                              <input
                                type="text"
                                className="form-control p-0"
                                value={catering}
                                onChange={(e) => handleChange(e, setCatering)}
                                placeholder="Catering"
                                style={{ height: "40px" }}
                              />
                            </p>
                            <p>
                              {" "}
                              <label className="header-label">
                                Information
                              </label>
                              <textarea
                                className="form-control p-0"
                                value={sonitageInfo}
                                onChange={(e) =>
                                  handleChange(e, setSonitageInfo)
                                }
                                placeholder={tab45}
                                style={{ height: "80px" }}
                              />
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <div className="d-flex my-3">
                                    <button className="btn add-new" onClick={handleCancelEvent}>cancel event</button>
                                </div> */}
                <div className="d-flex justify-content-end my-3">
                  {load1 ? (
                    <ButtonLoader view="btn add-new" />
                  ) : (
                    <button className="btn add-new" onClick={handleEventInfo}>
                      {tab24}
                    </button>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            {band ? (
              <>
                <div className="col-sm-12">
                  <div className="pt-3 px-1 bg-white">
                    <div className="table-responsive">
                      <table className="table storetable">
                        <thead className="">
                          <tr>
                            <th
                              scope="col"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Benenen Sie hier die Rolle, in welcher Sie das Bandmitglied, für diese Veranstaltungen einladen möchten. Zum Beispiel Gesang, Bass, Gitarre usw. Die Rolle wir dem jeweiligen Bandmitglied angezeigt."
                                  : "Name the role in which you want to invite the band member for this events. For example, vocals, bass, guitar, etc. The role is displayed to each band member."
                              }
                            >
                              {tab22}
                              <img
                                src="assets/images/Infoblack.png"
                                alt=""
                                className="mx-1 mb-4"
                              />
                            </th>
                            <th
                              scope="col"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Wählen Sie die Person aus, welche Sie für diese Rolle einladen möchten. Jede Person kann nur für jeweils eine Rolle eingeladen werden."
                                  : "Select the person you want to invite for this role. Each person can only be invited to one role at a time."
                              }
                            >
                              Person
                              <img
                                src="assets/images/Infoblack.png"
                                alt=""
                                className="mx-1 mb-4"
                              />
                            </th>
                            <th
                              scope="col"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Sie können für eine Rolle mehrere Bandmitglieder nach priorisierung einladen, indem Sie mit dem Button „Musiker hinzufügen“ weitere Personen hinzufügen - dieses Bandmitglied wird nun mit Priorität 2 für diese Rolle angezeigt. Die Einladung wird vorerst nur an die Person mit der Priorisierung 1 versendet. Erst wenn diese Person die Terminanfrage ablehnt, erhält die Person mit Priorisierung 2 eine Einladung."
                                  : "You can invite several band members for a role by prioritizing them by adding more people with the Add musicians button - this band member is now displayed with priority 2 for this role. Initially, the invitation will only be sent to the person with priority 1. Only when this person rejects the appointment request does the person with priority 2 receive an invitation."
                              }
                            >
                              {tab46}
                              <img
                                src="assets/images/Infoblack.png"
                                alt=""
                                className="mx-1 mb-4"
                              />
                            </th>
                            <th
                              scope="col"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Geben Sie hier die Gage für dieses Bandmitglied ein. Diese Information ist nur für Sie und das jeweiligen Bandmitglied sichtbar."
                                  : "Enter the fee for this band member here. This information is only visible to you and the respective band member."
                              }
                            >
                              {tab23}
                              <img
                                src="assets/images/Infoblack.png"
                                alt=""
                                className="mx-1 mb-4"
                              />
                            </th>
                            <th
                              scope="col"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Hier haben Sie die Möglichkeit, weitere Informationen mit der Terminanfrage, an die jeweiligen Bandmitglieder zu versenden. Diese Information ist nur für Sie und das jeweilige Bandmitglied sichtbar."
                                  : "Here you have the opportunity to send further information with the appointment request to the respective band members. This information is only visible to you and the respective band member."
                              }
                            >
                              {tab45}
                              <img
                                src="assets/images/Infoblack.png"
                                alt=""
                                className="mx-1 mb-4"
                              />
                            </th>
                            <th scope="col">{tab52}</th>
                            <th scope=""></th>
                          </tr>
                          <tr className="border-0 bg-none invisible">
                            <td className="border-0 bg-none"></td>
                          </tr>
                        </thead>
                        <tbody>
                          {loadsetupPersonList?.length !== 0
                            ? loadsetupPersonList.map((res, i) => {
                                return (
                                  <>
                                    {" "}
                                    <tr key={i}>
                                      {res?.role === "" || res?.role ? (
                                        <td className="white-space tdWidth">
                                          <p>
                                            <input
                                              type="text"
                                              className="form-control p-0"
                                              name={tab46}
                                              data-id="test"
                                              data-nested=""
                                              value={res.role}
                                              onChange={(e) =>
                                                handleInputLoadPerson(e, i)
                                              }
                                              placeholder={tab22}
                                              style={{
                                                height: "40px",
                                                width: "250px",
                                              }}
                                            />
                                          </p>
                                        </td>
                                      ) : (
                                        <td>
                                          <p></p>
                                        </td>
                                      )}
                                      <td className="white-space tdWidth">
                                        <p>
                                          <input
                                            type="text"
                                            className="form-control p-0"
                                            name="role"
                                            data-id="test"
                                            data-nested=""
                                            value={res.first_name}
                                            onChange={(e) =>
                                              handleInputLoadPerson(e, i)
                                            }
                                            placeholder={tab22}
                                            style={{
                                              height: "40px",
                                              width: "250px",
                                            }}
                                          />
                                        </p>
                                      </td>
                                      <td className="">
                                        <div className="d-flex">
                                          {" "}
                                          <input
                                            type="text"
                                            className="form-control p-0"
                                            name="priority"
                                            data-id="test"
                                            data-nested="nested"
                                            value={res.priority}
                                            onChange={(e) =>
                                              handleInputLoadPerson(e, i)
                                            }
                                            placeholder="1"
                                            style={{
                                              height: "40px",
                                              width: "150px",
                                              border: "none",
                                            }}
                                          />{" "}
                                          <div className=""></div>
                                        </div>
                                      </td>
                                      <td className="white-space tdWidth">
                                        <div>
                                          <input
                                            type="text"
                                            className="form-control p-0"
                                            name="amount"
                                            value={res.amount}
                                            onChange={(e) =>
                                              handleInputLoadPerson(e, i)
                                            }
                                            placeholder="150 Euro"
                                            style={{
                                              height: "40px",
                                              width: "250px",
                                            }}
                                          />
                                        </div>
                                      </td>

                                      <td className="tdWidth">
                                        <div>
                                          {" "}
                                          <input
                                            type="text"
                                            className="form-control p-0"
                                            name="additional_information"
                                            value={res.additional_information}
                                            onChange={(e) =>
                                              handleInputLoadPerson(e, i)
                                            }
                                            placeholder="additional information"
                                            style={{
                                              height: "40px",
                                              width: "250px",
                                            }}
                                          />
                                        </div>
                                      </td>
                                      <td
                                        onClick={() =>
                                          handleRemoveLoadperson(i)
                                        }
                                      >
                                        <img
                                          src="assets/images/delete.png"
                                          width="25px"
                                          alt=""
                                        />
                                      </td>
                                    </tr>
                                  </>
                                );
                              })
                            : ""}
                          {personList.map((res, i) => {
                            return (
                              <>
                                {" "}
                                <tr key={i}>
                                  {res?.role === "" || res?.role ? (
                                    <td className="white-space tdWidth">
                                      <p>
                                        <input
                                          type="text"
                                          className="form-control p-0"
                                          name="role"
                                          data-id="test"
                                          data-nested=""
                                          value={res.role}
                                          onChange={(e) =>
                                            handleInputPerson(e, i)
                                          }
                                          placeholder={tab22}
                                          style={{
                                            height: "40px",
                                            width: "250px",
                                          }}
                                        />
                                      </p>
                                    </td>
                                  ) : (
                                    <td>
                                      <p></p>
                                    </td>
                                  )}
                                  {res.person.map((item, index) => {
                                    if (index === 0) {
                                      return (
                                        <>
                                          <td className="white-space tdWidth">
                                            <p>
                                              <select
                                                className="form-control p-0 notranslate"
                                                data-id="test"
                                                data-nested="nested"
                                                name="user_id"
                                                value={item.user_id}
                                                onChange={(e) =>
                                                  handleInputPerson(
                                                    e,
                                                    i,
                                                    index,
                                                    1
                                                  )
                                                }
                                                style={{
                                                  height: "40px",
                                                  width: "250px",
                                                }}
                                              >
                                                <option>
                                                  {lng === "/auto/de" ||
                                                  "/en/de"
                                                    ? "--Auswählen--"
                                                    : "Select"}
                                                </option>
                                                {bandmember.map((res, key) => {
                                                  return (
                                                    <option
                                                      value={res.user_id}
                                                      disabled={personSelectedList?.includes(
                                                        res.user_id
                                                      )}
                                                    >
                                                      {res.first_name}
                                                    </option>
                                                  );
                                                })}
                                              </select>
                                            </p>
                                          </td>
                                          <td className="">
                                            <div className="d-flex">
                                              {" "}
                                              <input
                                                type="text"
                                                className="form-control p-0"
                                                name="priority"
                                                data-id="test"
                                                data-nested="nested"
                                                value={item.priority}
                                                onChange={(e) =>
                                                  handleInputPerson(e, i, index)
                                                }
                                                placeholder="1"
                                                style={{
                                                  height: "40px",
                                                  width: "150px",
                                                  border: "none",
                                                }}
                                              />{" "}
                                              <div className="">
                                                <button
                                                  className="btn add-new notiIcon filterIcon"
                                                  style={{ width: "200px" }}
                                                  onClick={(e) =>
                                                    handleAddPriority(
                                                      e,
                                                      res.role,
                                                      i
                                                    )
                                                  }
                                                >
                                                  {tab48}
                                                </button>
                                              </div>
                                            </div>
                                          </td>

                                          <td className="white-space tdWidth">
                                            <div>
                                              <input
                                                type="text"
                                                className="form-control p-0"
                                                name="amount"
                                                value={item.amount}
                                                onChange={(e) =>
                                                  handleInputPerson(e, i, index)
                                                }
                                                placeholder="150 Euro"
                                                style={{
                                                  height: "40px",
                                                  width: "250px",
                                                }}
                                              />
                                            </div>
                                          </td>

                                          <td className="tdWidth">
                                            <div>
                                              {" "}
                                              <input
                                                type="text"
                                                className="form-control p-0"
                                                name="additional_information"
                                                value={
                                                  item.additional_information
                                                }
                                                onChange={(e) =>
                                                  handleInputPerson(e, i, index)
                                                }
                                                placeholder={tab45}
                                                style={{
                                                  height: "40px",
                                                  width: "250px",
                                                }}
                                              />
                                            </div>
                                          </td>
                                          <td
                                            onClick={() =>
                                              handleRemovePersonList(
                                                i,
                                                item?.user_id
                                              )
                                            }
                                          >
                                            <img
                                              src="assets/images/delete.png"
                                              width="25px"
                                              alt=""
                                            />
                                          </td>
                                          <td></td>
                                        </>
                                      );
                                    }
                                  })}
                                </tr>
                                {res.person.map((item, index) => {
                                  if (index > 0) {
                                    return (
                                      <tr>
                                        <td></td>
                                        <td className="white-space tdWidth">
                                          <p>
                                            {" "}
                                            <select
                                              className="form-control p-0 notranslate"
                                              data-id="test"
                                              data-nested="nested"
                                              name="user_id"
                                              value={item.user_id}
                                              onChange={(e) =>
                                                handleInputPerson(
                                                  e,
                                                  i,
                                                  index,
                                                  1
                                                )
                                              }
                                              style={{
                                                height: "40px",
                                                width: "250px",
                                              }}
                                            >
                                              <option>
                                                {lng === "/auto/de" || "/en/de"
                                                  ? "--Auswählen--"
                                                  : "--Select--"}
                                              </option>
                                              {bandmember.map((res, key) => {
                                                return (
                                                  <option
                                                    value={res.user_id}
                                                    disabled={personSelectedList?.includes(
                                                      res.user_id
                                                    )}
                                                  >
                                                    {res.first_name}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                          </p>
                                        </td>
                                        <td className="">
                                          <div className="d-flex">
                                            {" "}
                                            <input
                                              type="text"
                                              className="form-control p-0"
                                              name="priority"
                                              data-id="test"
                                              data-nested="nested"
                                              value={item.priority}
                                              onChange={(e) =>
                                                handleInputPerson(e, i, index)
                                              }
                                              placeholder="1"
                                              style={{
                                                height: "40px",
                                                width: "150px",
                                                border: "none",
                                              }}
                                            />{" "}
                                            <div className="">
                                              {/* <button className="btn add-new notiIcon filterIcon" style={{ width: "180px" }} onClick={(e) => handleAddPriority(e, res.role, i)}>add musician</button> */}
                                            </div>
                                          </div>
                                        </td>

                                        <td className="white-space tdWidth">
                                          <div>
                                            <input
                                              type="text"
                                              className="form-control p-0"
                                              name="amount"
                                              value={item.amount}
                                              onChange={(e) =>
                                                handleInputPerson(e, i, index)
                                              }
                                              placeholder="150 Euro"
                                              style={{
                                                height: "40px",
                                                width: "250px",
                                              }}
                                            />
                                          </div>
                                        </td>

                                        <td className="tdWidth">
                                          <div>
                                            {" "}
                                            <input
                                              type="text"
                                              className="form-control p-0"
                                              name="additional_information"
                                              value={
                                                item.additional_information
                                              }
                                              onChange={(e) =>
                                                handleInputPerson(e, i, index)
                                              }
                                              placeholder={tab45}
                                              style={{
                                                height: "40px",
                                                width: "250px",
                                              }}
                                            />
                                          </div>
                                        </td>
                                        <td
                                          onClick={() =>
                                            handleRemovePerson(
                                              i,
                                              index,
                                              item?.user_id
                                            )
                                          }
                                          // onClick={() =>
                                          //   deletePerson(res?.user_id)
                                          // }
                                        >
                                          <img
                                            src="assets/images/delete.png"
                                            width="25px"
                                            alt=""
                                          />
                                        </td>
                                        <td>
                                          {/* <div className="d-flex justify-content-end my-3">
                                                                        <button className="btn add-new" onClick={(e) => handleSendInvite(res.user_id)} style={{ width: "200px" }}>Send Invitation</button>
                                                                    </div> */}
                                        </td>
                                      </tr>
                                    );
                                  }
                                })}
                              </>
                            );
                          })}

                          <button
                            className="btn add-new notiIcon filterIcon"
                            onClick={handleAddPerson}
                          >
                            {tab47}
                          </button>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 align-items-center">
                  <div className="col-8 col-sm-9 col-md-9 col-lg-9">
                    <ul
                      className="nav nav-pills group-nav tabNabs"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a className="nav-link active">{tab49}</a>
                      </li>
                    </ul>
                  </div>

                  <div className="col-sm-12">
                    <div className="pt-3 px-1 bg-white">
                      <div className="table-responsive">
                        <table className="table storetable">
                          <thead className="">
                            <tr>
                              <th
                                scope="col"
                                title={
                                  lng === "/auto/de" || "/en/de"
                                    ? "Benenen Sie hier die Rolle, in welcher Sie das Bandmitglied, für diese Veranstaltungen einladen möchten. Zum Beispiel Gesang, Bass, Gitarre usw. Die Rolle wir dem jeweiligen Bandmitglied angezeigt."
                                    : "Name the role in which you want to invite the band member for this events. For example, vocals, bass, guitar, etc. The role is displayed to each band member."
                                }
                              >
                                {tab22}
                                <img
                                  src="assets/images/Infoblack.png"
                                  alt=""
                                  className="mx-1 mb-4"
                                />
                              </th>
                              <th
                                scope="col"
                                title={
                                  lng === "/auto/de" || "/en/de"
                                    ? "Wählen Sie die Person aus, welche Sie für diese Rolle einladen möchten. Jede Person kann nur für jeweils eine Rolle eingeladen werden."
                                    : "Select the person you want to invite for this role. Each person can only be invited to one role at a time."
                                }
                              >
                                Person
                                <img
                                  src="assets/images/Infoblack.png"
                                  alt=""
                                  className="mx-1 mb-4"
                                />
                              </th>

                              <th
                                scope="col"
                                title={
                                  lng === "/auto/de" || "/en/de"
                                    ? "Geben Sie hier die Gage für dieses Bandmitglied ein. Diese Information ist nur für Sie und das jeweiligen Bandmitglied sichtbar."
                                    : "Enter the fee for this band member here. This information is only visible to you and the respective band member."
                                }
                              >
                                {tab23}
                                <img
                                  src="assets/images/Infoblack.png"
                                  alt=""
                                  className="mx-1 mb-4"
                                />
                              </th>

                              <th
                                scope="col"
                                title={
                                  lng === "/auto/de" || "/en/de"
                                    ? "Hier haben Sie die Möglichkeit, weitere Informationen mit der Terminanfrage, an die jeweiligen Bandmitglieder zu versenden. Diese Information ist nur für Sie und das jeweilige Bandmitglied sichtbar."
                                    : "Here you have the opportunity to send further information with the appointment request to the respective band members. This information is only visible to you and the respective band member."
                                }
                              >
                                {tab45}
                                <img
                                  src="assets/images/Infoblack.png"
                                  alt=""
                                  className="mx-1 mb-4"
                                />
                              </th>
                              <th scope="col">{tab52}</th>
                              <th scope=""></th>
                            </tr>
                            <tr className="border-0 bg-none invisible">
                              <td className="border-0 bg-none"></td>
                            </tr>
                          </thead>
                          <tbody>
                            {bandStaffList.map((res, i) => {
                              return (
                                <>
                                  <tr>
                                    {res?.role === "" || res?.role ? (
                                      <td className="white-space tdWidth">
                                        <input
                                          type="text"
                                          name="role"
                                          value={res.role}
                                          onChange={(e) =>
                                            handleInputBand(e, i)
                                          }
                                          className="form-control p-0"
                                          style={{
                                            height: "40px",
                                            width: "250px",
                                          }}
                                          placeholder={tab22}
                                        />
                                      </td>
                                    ) : (
                                      <td>
                                        <p></p>
                                      </td>
                                    )}
                                    <td className="white-space tdWidth">
                                      <select
                                        className="form-control p-0 notranslate"
                                        data-id="test"
                                        data-nested="nested"
                                        name="user_id"
                                        value={res.user_id}
                                        onChange={(e) =>
                                          handleInputBand(e, i, 1)
                                        }
                                        style={{
                                          height: "40px",
                                          width: "250px",
                                        }}
                                      >
                                        <option>
                                          {lng === "/auto/de" || "/en/de"
                                            ? "--Auswählen--"
                                            : "Select"}
                                        </option>
                                        {bandmember2.map((res, key) => {
                                          return (
                                            <option
                                              value={res.user_id}
                                              disabled={bandStaffSelectedList?.includes(
                                                res.user_id
                                              )}
                                            >
                                              {res.first_name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </td>
                                    {/* <td className="white-space tdWidth"><input type="text" name="name" value={res.name} onChange={(e) => handleInputBand(e, i)} className="form-control p-0" style={{ height: "40px", width: "250px" }} placeholder="Name" /></td> */}
                                    {/* <td className="white-space tdWidth"><input type="text" name="email" value={res.email} onChange={(e) => handleInputBand(e, i)} className="form-control p-0" style={{ height: "40px", width: "250px" }} placeholder="Email" /></td> */}
                                    <td className="white-space tdWidth">
                                      <input
                                        type="text"
                                        name="amount"
                                        value={res.amount}
                                        onChange={(e) => handleInputBand(e, i)}
                                        className="form-control p-0"
                                        style={{
                                          height: "40px",
                                          width: "250px",
                                        }}
                                        placeholder="150 Euro"
                                      />
                                    </td>
                                    <td className="tdWidth">
                                      <div>
                                        {" "}
                                        <input
                                          type="text"
                                          className="form-control p-0"
                                          name="description"
                                          value={res.description}
                                          onChange={(e) =>
                                            handleInputBand(e, i)
                                          }
                                          placeholder={tab45}
                                          style={{
                                            height: "40px",
                                            width: "250px",
                                          }}
                                        />
                                      </div>
                                    </td>
                                    <td onClick={() => handleRemoveStaff(i)}>
                                      <img
                                        src="assets/images/delete.png"
                                        width="25px"
                                        alt=""
                                      />
                                    </td>
                                    {/* <div className="d-flex justify-content-end my-3">
                                                                    <button className="btn add-new" onClick={() => handleSendInvite(res.user_id)} disabled style={{ width: "200px" }}>Send Invitation</button>
                                                                </div> */}
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                          <button
                            className="btn add-new notiIcon filterIcon mt-3"
                            onClick={handleAddBandPerson}
                          >
                            {tab61}
                          </button>
                        </table>
                      </div>
                    </div>
                    <div className="d-flex my-3">
                      <button
                        className="btn add-new1"
                        onClick={handleCancelEvent}
                      >
                        {lng === "/auto/de" || "/en/de"
                          ? "Setup speichern"
                          : "Safe setup"}
                      </button>
                      <button
                        className="btn add-new1"
                        onClick={handleShowLoadsetup}
                      >
                        {tab50}
                      </button>
                    </div>

                    {addMusicianShow ? (
                      <div className="d-flex justify-content-end my-3">
                        <button
                          className="btn add-new"
                          onClick={handleAddMusicians}
                        >
                          {lng === "/auto/de" || "/en/de"
                            ? "SPEICHERN"
                            : "Safe"}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {transport ? (
              <div className="col-sm-12">
                <div className="pt-3 px-1 bg-white">
                  <div className="table-responsive">
                    <table className="table storetable">
                      <thead className="">
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">
                            {lng === "/auto/de" || "/en/de"
                              ? "Fahrer"
                              : "Driver"}{" "}
                            <img
                              src="assets/images/Infoblack.png"
                              alt=""
                              className="mx-1 mb-4"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Tragen Sie den Namen des Fahrers ein. Alle eingeladenen Bandmitglieder können diese Information einsehen."
                                  : "Enter the driver's name. All invited band members can see this information."
                              }
                            />
                          </th>
                          <th scope="col">
                            {tab30}{" "}
                            <img
                              src="assets/images/Infoblack.png"
                              alt=""
                              className="mx-1 mb-4"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Tragen Sie den Namen des Fahrzeuges ein."
                                  : "Enter the name of the vehicle."
                              }
                            />
                          </th>
                          <th scope="col">
                            {tab31}{" "}
                            <img
                              src="assets/images/Infoblack.png"
                              alt=""
                              className="mx-1 mb-4"
                              title={
                                lng === "/auto/de" || "/en/de"
                                  ? "Tragen Sie hier die Anzahl der für Passagiere verfügbaren Plätze ein. Dies ist die maximale Anzahl der Mitfahrer, welche sich für dieses Fahrzeug eintragen können."
                                  : "Enter the number of seats available for passengers here. This is the maximum number of passengers who can register for this vehicle."
                              }
                            />
                          </th>
                          {/* <th scope="col">passengers   <img src="assets/images/Infoblack.png" alt="" className="mx-1 mb-4" title={lng === "/auto/de" || "/en/de" ? "Hier finden Sie die Namen der Personen, welche sich bereits für das Fahrzeug als Passagier eingetragen haben." : "Here you will find the names of the people who have already registered for the vehicle as passengers."} /></th> */}
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {driverList.map((res, i) => {
                          return (
                            <tr>
                              {" "}
                              <td className="mx-4">
                                {driverList.length !== 1 && (
                                  <img
                                    src="assets/images/delete.png"
                                    alt=""
                                    onClick={() => handleRemove(i)}
                                  />
                                )}
                                {driverList.length - 1 === i && (
                                  <img
                                    src="assets/images/moregray.png"
                                    alt=""
                                    onClick={handleAddClick}
                                  />
                                )}
                              </td>
                              <td>
                                {" "}
                                <p>
                                  <input
                                    type="text"
                                    className="form-control p-0"
                                    name="driver"
                                    value={res.driver}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder={
                                      lng === "/auto/de" || "/en/de"
                                        ? "Fahrer"
                                        : "Driver"
                                    }
                                    style={{ height: "40px" }}
                                  />
                                </p>
                              </td>
                              <td>
                                <p>
                                  <input
                                    type="text"
                                    className="form-control p-0"
                                    name="vehicle"
                                    value={res.vehicle}
                                    onChange={(e) => handleInputChange(e, i)}
                                    placeholder={tab30}
                                    style={{ height: "40px" }}
                                  />
                                </p>
                              </td>
                              <td>
                                <p>
                                  {/* <input type="text" className="form-control p-0" name="available_seats" value={res.available_seats} onChange={(e) => handleInputChange(e, i)} placeholder="Available seats" style={{ height: "40px" }} /> */}
                                  <select
                                    className="form-control p-0"
                                    name="available_seats"
                                    onChange={(e) => handleInputChange(e, i)}
                                    style={{ height: "40px" }}
                                  >
                                    <option>{tab62}</option>
                                    <option values="1">1</option>
                                    <option values="2">2</option>
                                    <option values="3">3</option>
                                    <option values="4">4</option>
                                    <option values="5">5</option>
                                    <option values="6">6</option>
                                    <option values="7">7</option>
                                    <option values="8">8</option>
                                    <option values="9">9</option>
                                    <option values="10">10</option>
                                    <option values="11">11</option>
                                    <option values="12">12</option>
                                    <option values="13">13</option>
                                    <option values="14">14</option>
                                    <option values="15">15</option>
                                    <option values="16">16</option>
                                  </select>
                                </p>
                              </td>
                              {/* <td> <button className="btn add-new" onClick={handlePassengers}>add</button></td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="d-flex justify-content-end my-3">
                  <button className="btn add-new" onClick={handleAddTransport}>
                    {lng === "/auto/de" || "/en/de" ? "SPEICHERN" : "Safe"}
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}

            {setlist ? (
              <div className="col-sm-12">
                <div className="pt-3 px-1 bg-white">
                  <h4 className="notranslate">
                    {allSetlistSongs?.data?.set_list_name
                      ? allSetlistSongs?.data?.set_list_name
                      : "Name"}
                  </h4>
                  <div className="table-responsive setlistTable">
                    <table className="table storetable">
                      <thead className="">
                        <tr>
                          <th scope="col">name song</th>
                          <th className="text-center mx-5" scope="col">
                            {lng === "/auto/de" || "/en/de"
                              ? "Zeit"
                              : "time of song"}
                          </th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {allSetlistSongs?.data2 !== undefined &&
                        allSetlistSongs?.data2 !== null
                          ? allSetlistSongs?.data2.map((item, key) => {
                              return (
                                <tr>
                                  <td className="notranslate">
                                    <input
                                      type="text"
                                      value={item?.song_name}
                                      style={{ border: "none" }}
                                    />
                                  </td>
                                  <td className="mx-5">
                                    <input
                                      type="text"
                                      value={item?.time_length?.substr(3, 6)}
                                      style={{ border: "none" }}
                                    />
                                  </td>
                                </tr>
                              );
                            })
                          : ""}

                        <tr>
                          <td className="bg-td">{tab51}</td>
                          <td className="bg-td">
                            {lng === "/auto/de" || "/en/de"
                              ? "Gesamtzeit der Setlist"
                              : "time of setlist "}{" "}
                            {allSetlistSongs?.data?.total_length
                              ? allSetlistSongs?.data?.total_length
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="d-flex justify-content-end my-3">
                  {/* <button className="btn" onClick={showDelete}><img src="assets/images/deleteBlack1.png" alt="" /></button> */}
                  <button className="btn" onClick={showSetlist}>
                    <img src="assets/images/attachment.png" alt="" />{" "}
                    <img
                      src="assets/images/Infoblack.png"
                      alt=""
                      className="mx-1 mb-4"
                      title={
                        lng === "/auto/de" || "/en/de"
                          ? "Mit diesem Button öffnen Sie die Auflistung der von Ihnen erstellten Setlisten"
                          : "This button opens the list of set lists you have created"
                      }
                    />
                  </button>
                  {/* <button className="btn"><img src="assets/images/printing.png" alt="" /></button> */}
                </div>
                {selectSetlist ? (
                  <SetListModal
                    setListData={setListData}
                    setSetListData={setSetListData}
                    setSetlistId={setSetlistId}
                    setSetlistLength={setSetlistLength}
                  />
                ) : (
                  ""
                )}
                {allSetlistSongs?.data2 ? (
                  <div className="d-flex justify-content-end my-3">
                    <button className="btn add-new" onClick={handleAddSetlist}>
                      {lng === "/auto/de" || "/en/de" ? "SPEICHERN" : "Safe"}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            {attachment ? (
              <div className="col-sm-12">
                <div className="pt-3 px-1 bg-white">
                  <h6
                    className="text-center"
                    title={
                      lng === "/auto/de" || "/en/de"
                        ? "Klicken Sie auf die jeweiligen Dokumente ,um Freigaben für den Up- und Download zu erteilen"
                        : "Click on the respective documents to grant approval for uploading and downloading"
                    }
                  >
                    {tab9}
                    <img
                      src="assets/images/Infoblack.png"
                      alt=""
                      className="mx-1 mb-4"
                    />{" "}
                  </h6>
                  <div className="table-responsive">
                    <div className="col-sm-6 col-md-6 col-lg-6 mb-5 mt-5">
                      <div
                        className="upload-btn-wrapper Attachment"
                        onClick={() => handleOutgoingfiles(tab32)}
                      >
                        <button className="btn">{tab32}</button>
                        {/* <input type="file" onChange={handleFileUpload1} name="myfile" /> */}
                        <img
                          src="assets/images/invoice.png"
                          alt=""
                          className="inputImgRight"
                        />
                        <p style={{ fontWeight: "bold" }}>
                          {" "}
                          {file1 ? file1 : ""}
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 mb-5 mt-5">
                      <div
                        className="upload-btn-wrapper Attachment"
                        onClick={() => handleIncomingfiles(tab33)}
                      >
                        <button className="btn">{tab33}</button>

                        <img
                          src="assets/images/invoice.png"
                          alt=""
                          className="inputImgRight"
                        />
                        <p style={{ fontWeight: "bold" }}>
                          {" "}
                          {file2 ? file2 : ""}
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 mb-5 mt-5">
                      <div
                        className="upload-btn-wrapper Attachment"
                        onClick={() =>
                          handleContractfiles("Dokumente von Veranstalter")
                        }
                      >
                        <button className="btn">
                          {lng === "/auto/de" || "/en/de"
                            ? "Dokumente von Veranstalter"
                            : "Incoming Contact"}
                        </button>
                        <img
                          src="assets/images/attachment.png"
                          alt=""
                          className="inputImgRight"
                        />
                        <p style={{ fontWeight: "bold" }}>
                          {" "}
                          {file3 ? file3 : ""}
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 mb-5 mt-5">
                      <div
                        className="upload-btn-wrapper Attachment"
                        onClick={() => handleBookerfiles(tab34)}
                      >
                        <button className="btn">{tab34}</button>

                        <img
                          src="assets/images/attachment.png"
                          alt=""
                          className="inputImgRight"
                        />
                        <p style={{ fontWeight: "bold" }}>
                          {" "}
                          {file4 ? file4 : ""}
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 mb-5 mt-5">
                      <div
                        className="upload-btn-wrapper Attachment"
                        onClick={() => handleSoundfiles(tab35)}
                      >
                        <button className="btn">{tab35}</button>
                        <img
                          src="assets/images/attachment.png"
                          alt=""
                          className="inputImgRight"
                        />
                        <p style={{ fontWeight: "bold" }}>
                          {" "}
                          {file5 ? file5 : ""}
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 mb-5 mt-5">
                      <div
                        className="upload-btn-wrapper Attachment"
                        onClick={() => handleOtherfiles(tab36)}
                      >
                        <button className="btn">{tab36}</button>
                        <img
                          src="assets/images/attachment.png"
                          alt=""
                          className="inputImgRight"
                        />
                        <p style={{ fontWeight: "bold" }}>
                          {" "}
                          {file6 ? file6 : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end my-3">
                  <button className="btn add-new" onClick={handleAdd}>
                    Speichern
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </article>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showCancelModal ? (
        <SafeSetup
          show={showCancelModal}
          handleShow={handleShowCancel}
          personList={personList}
        />
      ) : (
        ""
      )}
      {showPassengerModal ? (
        <AddPassengers
          show={showPassengerModal}
          handleShow={handleShowPassenger}
          driverList={driverList}
        />
      ) : (
        ""
      )}
      {showLoadsetup ? (
        <LoadSetup
          show={showLoadsetup}
          handleShow={handleShowLoadsetup}
          setLoadsetupPersonList={setLoadsetupPersonList}
          setNewLoad={setNewLoad}
        />
      ) : (
        ""
      )}
      {OutgoingInvoiceFile ? (
        <OutgoingInvoice
          show={OutgoingInvoiceFile}
          handleShow={handleShowOutgoingInvoiceFile}
          eventId={event_id}
          showtext={showtext}
        />
      ) : (
        ""
      )}
      {incomingInvoiceFile ? (
        <IncomingInvoice
          show={incomingInvoiceFile}
          handleShow={handleShowIncomingInvoiceFile}
          eventId={event_id}
          showtext={showtext}
        />
      ) : (
        ""
      )}
      {incomingContractFile ? (
        <IncomingContract
          show={incomingContractFile}
          handleShow={handleShowIncomingContractFile}
          eventId={event_id}
          showtext={showtext}
        />
      ) : (
        ""
      )}
      {documentBookerFile ? (
        <DocumentBooker
          show={documentBookerFile}
          handleShow={handleShowDocumentBookerFile}
          eventId={event_id}
          showtext={showtext}
        />
      ) : (
        ""
      )}
      {documentSoundFile ? (
        <SoundEngineer
          show={documentSoundFile}
          handleShow={handleShowDocumentSoundFile}
          eventId={event_id}
          showtext={showtext}
        />
      ) : (
        ""
      )}
      {otherFile ? (
        <OtherAttachments
          show={otherFile}
          handleShow={handleShowOtherFile}
          eventId={event_id}
          showtext={showtext}
        />
      ) : (
        ""
      )}
      {invitationShow ? (
        <InvitationModal
          show={invitationShow}
          handleShow={handleShowInvitation}
          eventid={event_id}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default EventDates;
