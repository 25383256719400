import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { getGemaSubmitted } from "../../app_redux/slice/gemaSubmitted";
import { useNavigate } from "react-router-dom";
import DeleteEventModal from "./deleteEvent";
import { getEvents } from "../../app_redux/slice/gemaEvents";
import ReportEventModal from "./reportEvent";
import AddSongModal from "./addSong";
import LoadListModal from "./gemaLoadlist";
import AddSongSetlist from "./addSongSetlist";
import WithoutAppointmentModal from "./withoutAppointment";
import GemaEdit from "./gemaEdit";
import InformationModal from "./informationModal";
import { useTranslation } from "react-i18next";

const Gema = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const lng = cookee.get("googtrans");
  const token = cookee.get("token");
  const [events, setEvents] = useState([]);
  const [eventId, setEventId] = useState("");
  const { submittedEvents, loading } = useSelector(
    (state) => state.submittedgema
  );
  const { allEvents } = useSelector((state) => state.events);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showWithoutAppointmentModal, setShowWithoutAppointmentModal] =
    useState(false);
  const { gemaDetails } = useSelector((state) => state.gemaevents);
  const [eventName, setEventName] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showLoadlistModal, setShowLoadlistModal] = useState(false);
  const [showSetlistSong, setShowSetlistSong] = useState(false);
  const [showGemaEdit, setShowGemaEdit] = useState(false);
  const [setlistIdentity, setSetlistIdentity] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [pageNo1, setPageNo1] = useState(1);
  const [gemaSongs, setGemaSongs] = useState([]);
  const [setlistId, setSetlistId] = useState(gemaDetails[0]?.set_list_id);
  const [show, setshow] = useState(false);
  const [showId, setShowId] = useState("");
  const [informationShow, setInformationShow] = useState();
  const [information, setInformation] = useState();
  const {
    tab1,
    tab2,
    tab3,
    tab4,
    tab5,
    tab6,
    tab7,
    tab8,
    tab9,
    tab10,
    tab11,
    tab12,
    tab13,
  } = t("gema");

  useEffect(() => {
    // getSubmittedList()
    dispatch(getEvents({ pageno: pageNo }));
    dispatch(getGemaSubmitted({ pageno: pageNo }));
    setEventName(gemaDetails[0]?.event_name);
  }, []);
  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];

    return day + "." + month + "." + year;
  }
  const handleNext = (e) => {
    e.preventDefault();
    setPageNo(pageNo + 1);
    dispatch(getEvents({ pageno: pageNo + 1 }));
  };
  const handlePrevious = (e) => {
    e.preventDefault();
    setPageNo(pageNo - 1);
    dispatch(getEvents({ pageno: pageNo - 1 }));
  };
  const handleNextGema = (e) => {
    e.preventDefault();
    setPageNo1(pageNo1 + 1);
    dispatch(getGemaSubmitted({ pageno: pageNo1 + 1 }));
  };
  const handlePreviousGema = (e) => {
    e.preventDefault();
    setPageNo1(pageNo1 - 1);
    dispatch(getGemaSubmitted({ pageno: pageNo1 - 1 }));
  };
  const handleShowDelete = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  const handleShowReport = () => {
    setShowReportModal(!showReportModal);
  };
  const handleShowWithoutAppointment = () => {
    setShowWithoutAppointmentModal(!showWithoutAppointmentModal);
  };
  const handleShowSetlistSong = () => {
    setShowSetlistSong(!showSetlistSong);
  };
  const handleShowGemaEdit = () => {
    setShowGemaEdit(!showGemaEdit);
  };
  const handleDelete = (id) => {
    setEventId(id);
    handleShowDelete();
  };
  const handleInfoShow = () => {
    setInformationShow(!informationShow);
  };
  const handleInformationModal = (res) => {
    setInformation(res);
    handleInfoShow();
  };
  const getEvent = () => {
    axios({
      method: "get",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: "http://3.131.5.153:4050/v1/band-leader-dashboard-upcoming-event",
    }).then((res) => {
      // console.log(res.data)
      setEvents(res.data.data);
    });
  };
  const handleSongDetails = (id) => {
    setShowId(id);
    setshow(!show);
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-event-details-event-id`,
      data: {
        event_id: id,
      },
    }).then((res) => {
      setGemaSongs(res.data.data[0].set_list_details);
    });
  };
  const handleShowAdd = () => {
    setShowAddModal(!showAddModal);
  };
  const handleShowLoadlist = () => {
    setShowLoadlistModal(!showLoadlistModal);
  };

  const handleAppointment = () => {
    handleShowWithoutAppointment();
  };

  const handleReport = (id) => {
    setEventId(id);
    localStorage.setItem("gema_event_id", id);
    handleShowReport();
  };
  const handleEditClick = (id) => {
    setEventId(id);
    // dispatch(getEventDetail({eventid:id}))
    handleShowGemaEdit();
    // localStorage.setItem("gema_event_id", id)
    // dispatch(gemaEventDetails())
    // getData()

    //navigate("/gema-edit")
  };
  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row mt-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
              <div className="d-flex align-items-center lg-justify-content-end">
                <button className="btn add-new" onClick={handleAppointment}>
                  {tab7}
                </button>
                <img
                  src="assets/images/Infoblack.png"
                  alt=""
                  className="mx-1 mb-4"
                  title={
                    lng === "/auto/de" || "/en/de"
                      ? "Hier können Sie ein Konzert und Musikfolge manuell übermitteln, sollten Sie zu einem Konzert keine Veranstaltung angelegt haben."
                      : "Here you can manually transmit a concert and music sequence if you have not created an event for a concert."
                  }
                />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <h4>
                {tab1}
                <img
                  src="assets/images/Infoblack.png"
                  alt=""
                  className="mx-1 mb-4"
                  title={
                    lng === "/auto/de" || "/en/de"
                      ? "Hier finden Sie die Auflistung aller Konzerte, welche endgültig bestätigt wurden. Alle bereits an CleverMusik überspielten Konzertinformationen, sind in der Liste nicht mehr zu finden. Die sind nun unter der Übersicht der eingereichten Konzerte aufgelistet."
                      : "Here you will find a list of all concerts that have been finally confirmed. All concert information already transferred to CleverMusik can no longer be found in the list. They are now listed under the overview of the submitted concerts"
                  }
                />
              </h4>
              <div className="pt-3 mt-3 px-1 bg-white shadow">
                <div className="table-responsive">
                  <table className="table storetable">
                    <tbody>
                      {allEvents?.length !== 0 ? (
                        allEvents.map((res, i) => {
                          return (
                            <tr>
                              <td>
                                <img
                                  src="assets/images/calendarGrey.png"
                                  width="20px"
                                  alt=""
                                />{" "}
                                {formatDate(res.start_date?.substr(0, 10))}
                              </td>
                              <td className="notranslate">{res.event_name}</td>
                              <td className="notranslate">{res.town}</td>
                              <td>
                                <div
                                  className="d-flex gap-3 align-items-center"
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src="assets/images/edit.png"
                                    width="20px"
                                    alt=""
                                    onClick={() =>
                                      handleEditClick(res.event_id)
                                    }
                                  />
                                  <img
                                    src="assets/images/deleteBlack.png"
                                    width="20px"
                                    alt=""
                                    onClick={() => handleDelete(res.event_id)}
                                  />
                                  <img
                                    src="assets/images/arrobaBlack.png"
                                    width="20px"
                                    alt=""
                                    onClick={() => handleReport(res.event_id)}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <p>{tab12}</p>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {pageNo === 1 && allEvents?.length <= 9 ? (
              ""
            ) : (
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                <div className="d-flex align-items-center lg-justify-content-end">
                  <nav aria-label="...">
                    <ul className="pagination">
                      <li
                        className={
                          pageNo === 1 ? "page-item disabled" : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={handlePrevious}
                        >
                          Previous
                        </a>
                      </li>
                      <li class="page-item active">
                        <a className="page-link" href="#">
                          {pageNo} <span className="sr-only">(current)</span>
                        </a>
                      </li>
                      <li
                        class={
                          allEvents?.length <= 9
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <a className="page-link" href="#" onClick={handleNext}>
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
            <div className="col-sm-12 col-md-12 col-lg-12 mt-5">
              <h4>
                {tab2}
                <img
                  src="assets/images/Infoblack.png"
                  alt=""
                  className="mx-1 mb-4"
                  title={
                    lng === "/auto/de" || "/en/de"
                      ? "Hier finden Sie alle bereits eingereichten Konzerte. Nach den jeweiligen Ausschüttungsterminen wird der Status einer Aufführung angepasst. Bei einer Ausschüttung, änder sich der Status auf „ausbezahlt“ und das jeweilige Segment und ggf weitere Informationen sind eingetragen."
                      : "Here you will find all the concerts that have already been submitted. After the respective payment dates, the status of a performance will be adjusted. In the case of a distribution, the status changes to paid out and the respective segment and, if necessary, further information are entered."
                  }
                />
              </h4>
              <div className="pt-3 mt-3 px-1 bg-white shadow">
                <div className="table-responsive">
                  <table className="table storetable">
                    <thead className="">
                      <tr>
                        <th scope="col">{tab3}</th>
                        <th scope="col">{tab4}</th>
                        <th scope="col">{tab5}</th>
                        <th scope="col">{tab6}</th>
                        <th scope="col">status</th>
                        <th scope="col">segment</th>
                        <th scope="col">information</th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {submittedEvents?.length !== 0 ? (
                        submittedEvents.map((res, i) => {
                          return (
                            <>
                              <tr>
                                <td
                                  onClick={() =>
                                    handleSongDetails(res.event_id)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src="assets/images/down-filled-triangular-arrow.png"
                                    width="15px"
                                    alt=""
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#${res.event_id}`}
                                    aria-expanded="false"
                                  />{" "}
                                  {formatDate(res.start_date?.substr(0, 10))}
                                </td>
                                <td
                                  className="notranslate"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleSongDetails(res.event_id)
                                  }
                                >
                                  {res.event_name}
                                </td>
                                <td>{res.town}</td>
                                <td>
                                  {formatDate(
                                    res.gema_submitted_on.substr(0, 10)
                                  )}
                                </td>
                                <td>
                                  {res.status === 0 ? (
                                    <span className="orangeColor text-uppercase fw-bold">
                                      {tab11}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {res.status === 1 ? (
                                    <span className="greenColor text-uppercase fw-bold">
                                      {tab9}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {res.status === 2 ? (
                                    <span className="yollowColor text-uppercase fw-bold">
                                      {tab10}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td className="notranslate">
                                  {res.segment ? res.segment : "NA"}
                                </td>
                                <td
                                  className="notranslate"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleInformationModal(
                                      res?.text_information
                                    )
                                  }
                                >
                                  <div className="para_dot">
                                    {res.text_information || "NA"}{" "}
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={7}>
                                  <div
                                    className={
                                      show && showId === res.event_id
                                        ? "collapse multi-collapse show"
                                        : "collapse multi-collapse"
                                    }
                                    id={res.event_id}
                                  >
                                    <table className="table storetable w-100 ">
                                      <thead className="">
                                        <tr>
                                          <th
                                            scope="col"
                                            className="notranslate"
                                          >
                                            {tab13}
                                          </th>
                                          <th
                                            scope="col"
                                            className="notranslate"
                                          >
                                            Name
                                          </th>
                                          <th
                                            scope="col"
                                            className="notranslate"
                                          >
                                            {tab8}
                                          </th>
                                          <th
                                            scope="col"
                                            className="notranslate"
                                          >
                                            Status
                                          </th>
                                        </tr>
                                        <tr className="border-0 bg-none invisible">
                                          <td className="border-0 bg-none"></td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {gemaSongs?.length > 0 ? (
                                          gemaSongs.map((res, i) => {
                                            return (
                                              <>
                                                <tr>
                                                  <td>{res.gemma_number}</td>
                                                  <td className="notranslate">
                                                    {res.song_name}
                                                  </td>
                                                  <td>{res.time_length}</td>

                                                  <td>
                                                    {res?.is_copyright === 0 &&
                                                    res.payed_out_status ===
                                                      0 ? (
                                                      <span className="redcolor text-uppercase fw-bold notranslate">
                                                        Cover
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {res.payed_out_status ===
                                                      0 &&
                                                    res?.is_copyright === 1 ? (
                                                      <span className="orangeColor text-uppercase fw-bold">
                                                        {tab11}
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {res.payed_out_status ===
                                                    1 ? (
                                                      <span className="greenColor text-uppercase fw-bold">
                                                        {tab9}
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {res.payed_out_status ===
                                                    2 ? (
                                                      <span className="yollowColor text-uppercase fw-bold">
                                                        Partial Payed Out
                                                      </span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </td>
                                                </tr>
                                              </>
                                            );
                                          })
                                        ) : (
                                          <p>No Record Found !!</p>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <p>{tab12}</p>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {pageNo1 === 1 && submittedEvents?.length <= 9 ? (
              ""
            ) : (
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                <div className="d-flex align-items-center lg-justify-content-end">
                  <nav aria-label="...">
                    <ul className="pagination">
                      <li
                        className={
                          pageNo1 === 1 ? "page-item disabled" : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={handlePreviousGema}
                        >
                          Previous
                        </a>
                      </li>
                      <li class="page-item active">
                        <a className="page-link" href="#">
                          {pageNo1} <span className="sr-only">(current)</span>
                        </a>
                      </li>
                      <li
                        class={
                          submittedEvents?.length <= 9
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={handleNextGema}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </article>
      {showDeleteModal ? (
        <DeleteEventModal
          show={showDeleteModal}
          handleShow={handleShowDelete}
          id={eventId}
          pageNo={pageNo}
        />
      ) : (
        ""
      )}
      {showReportModal ? (
        <ReportEventModal
          show={showReportModal}
          handleShow={handleShowReport}
          id={eventId}
        />
      ) : (
        ""
      )}
      {showWithoutAppointmentModal ? (
        <WithoutAppointmentModal
          show={showWithoutAppointmentModal}
          handleShow={handleShowWithoutAppointment}
          id={eventId}
          pageNo={pageNo}
        />
      ) : (
        ""
      )}
      {showAddModal ? (
        <AddSongModal
          show={showAddModal}
          handleShow={handleShowAdd}
          id={setlistId}
        />
      ) : (
        ""
      )}
      {showLoadlistModal ? (
        <LoadListModal
          show={showLoadlistModal}
          handleShow={handleShowLoadlist}
          id={setlistId}
          eventid={eventId}
          setShowSetlistSong={setShowSetlistSong}
          setSetlistIdentity={setSetlistIdentity}
        />
      ) : (
        ""
      )}
      {showSetlistSong ? (
        <AddSongSetlist
          show={showSetlistSong}
          handleShow={handleShowSetlistSong}
          setSetlistIdentity={setSetlistIdentity}
          setlistIdentity={setlistIdentity}
          id={setlistId}
        />
      ) : (
        ""
      )}
      {showGemaEdit ? (
        <GemaEdit
          show={showGemaEdit}
          handleShow={handleShowGemaEdit}
          setSetlistIdentity={setSetlistIdentity}
          eventid={eventId}
          setlistIdentity={setlistIdentity}
          id={setlistId}
        />
      ) : (
        ""
      )}
      {informationShow ? (
        <InformationModal
          show={informationShow}
          handleShow={handleInfoShow}
          information={information}
        />
      ) : (
        ""
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Gema;
