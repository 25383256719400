import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import { getTaskChats } from "../../app_redux/slice/taskChat";
import { useTranslation } from "react-i18next";
import S3FileUpload from "react-s3";
window.Buffer = window.Buffer || require("buffer").Buffer;

const TaskChats = ({ eventId, taskId, allTaskChats, taskName, tab1, tab2 }) => {
  const { t } = useTranslation();
  const { tab3, tab4 } = t("chat");
  const [message2, setMessage2] = useState();
  const cookee = new Cookies();
  const dispatch = useDispatch();
  const token = cookee.get("token");
  const [loading1, setLoading1] = useState(false);

  useEffect(() => {
    dispatch(getTaskChats({ taskid: taskId }));
  }, []);

  const handleMessage2 = (e) => {
    setMessage2(e.target.value);
  };

  const handleFile1 = (e) => {
    setLoading1(true);
    let file = e.target.files[0];
    const config = {
      bucketName: "clever-band-assist-s3",
      region: "eu-west-3",
      accessKeyId: "AKIASTLKHDND66NQZXFJ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        console.log("RES", data.location);
        // setImage(data.location)
        setLoading1(false);
        handleTaskSendMessageImage(data.location);
      })
      .catch((err) => console.error("ERR", err));
  };

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];

    return day + "." + month + "." + year;
  }
  const validtask = () => {
    if (message2.length === 0) {
      return false;
    } else {
      return true;
    }
  };
  const handleTaskSendMessageImage = (image) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/band-leader-musician-task-chat-send-message`,
      data: {
        task_id: taskId,
        user_type: 2,
        message: "",
        message_type: "I",
        media_url: image,
      },
    }).then((res) => {
      //  console.log("res", res.data)
      if (res.status === 200) {
        dispatch(getTaskChats({ taskid: taskId }));
        setMessage2("");
      }
    });
  };

  const handleTaskSendMessage = () => {
    if (validtask()) {
      axios({
        method: "post",
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          auth_token: token,
        },
        url: `${process.env.REACT_APP_URL}/band-leader-musician-task-chat-send-message`,
        data: {
          task_id: taskId,
          user_type: 2,
          message: message2,
          message_type: "T",
          media_url: "",
        },
      }).then((res) => {
        //  console.log("res", res.data)
        if (res.status === 200) {
          dispatch(getTaskChats({ taskid: taskId }));
          setMessage2("");
        }
      });
    }
  };
  const onKeyPressTask = (e) => {
    if (e.which === 13) {
      handleTaskSendMessage();
    }
  };

  return (
    <div className="col-sm-12 col-md-12 col-lg-8 ps-0 position-relative">
      <h5 className="d-flex align-items-center justify-content-center theme-color px-2 pt3 mb-0">
        {tab1}
      </h5>
      <h6 className="text-center mt-2 notranslate">{taskName || ""}</h6>
      <hr />
      {allTaskChats?.length > 0 ? (
        allTaskChats?.map((res, i) => {
          return (
            <div className="">
              {res?.user_type === 1 ? (
                <div>
                  <img
                    src={
                      res?.profile_pic
                        ? res?.profile_pic
                        : "assets/images/dummy.png"
                    }
                    alt=""
                    className="chatImg mx-2"
                  />{" "}
                  <b className="notranslate">{res?.first_name}</b>
                  <div className="chatText chatTextWidthWhite">
                    {res.media_url ? (
                      <a href={res.media_url} target="_blank">
                        {" "}
                        <img
                          className="mb-0 d-flex align-items-center gap-3 justify-content-end"
                          src={res.media_url}
                          alt=""
                          style={{ width: "300px" }}
                        />
                      </a>
                    ) : (
                      ""
                    )}
                    <p className="notranslate">{res?.message}</p>
                    <p className="mb-0 d-flex align-items-center gap-3 justify-content-end">
                      {res?.created_at
                        ? new Date(res?.created_at).toLocaleString()
                        : "-"}{" "}
                      <img
                        src="assets/images/blackDots.png"
                        alt=""
                        className=""
                      />
                    </p>
                  </div>
                </div>
              ) : (
                <div className="chatText chatTextWidthRed mb-3">
                  <b
                    style={{ textTransform: "uppercase" }}
                    className="notranslate mb-3"
                  >
                    {res.band_name}{" "}
                  </b>
                  <p className="notranslate mt-3">{res?.message}</p>
                  {res.media_url ? (
                    <a href={res.media_url} target="_blank">
                      <img
                        className="mb-0 d-flex align-items-center gap-3 justify-content-end"
                        src={res.media_url}
                        alt=""
                        style={{ width: "300px" }}
                      />{" "}
                    </a>
                  ) : (
                    ""
                  )}
                  <p className="mb-0 d-flex align-items-center gap-3 justify-content-end">
                    {res?.created_at
                      ? new Date(res?.createdAt).toLocaleString()
                      : "-"}{" "}
                    <img
                      src="assets/images/whiteDots.png"
                      alt=""
                      className=""
                    />
                  </p>
                </div>
              )}
            </div>
          );
        })
      ) : (
        <p className="text-center">{tab4}</p>
      )}
      {loading1 ? (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        ""
      )}
      <div className="ChatSendInput">
        <div className="position-relative">
          <input
            type="text"
            className="form-control"
            value={message2}
            placeholder={tab2}
            onChange={handleMessage2}
            onKeyPress={onKeyPressTask}
          />
          {/* <img src="assets/images/laugh.png" alt="" className="laughIcon" /> */}
          <div className="d-flex InputIconRight">
            <div className="upload-btn-wrapper Attachment mx-2">
              <img src="assets/images/gallery.png" width="25px" alt="" />
              <input type="file" onChange={handleFile1} />
            </div>{" "}
            <img
              src="assets/images/send-message.png"
              alt=""
              onClick={handleTaskSendMessage}
              className="iconImages"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskChats;
