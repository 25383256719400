import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "jspdf-autotable";
import { getSetlistDetail } from "../../app_redux/slice/setlistDetail";
import * as XLSX from "xlsx"; // Import xlsx library
import { saveAs } from "file-saver";

const GemaSetlistPrint = () => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const token = cookee.get("token");
  const [details, setDetails] = useState(false);
  const { allSetlist } = useSelector((state) => state.setlist);
  const localsetlist = JSON.parse(localStorage.getItem("setlistdetails"));

  useEffect(() => {
    dispatch(getSetlistDetail());
  }, []);

  const handleDownload = () => {
    const wb = XLSX.utils.table_to_book(tableRef.current);
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    saveAs(blob, "setlist.xlsx");
  };

  // Convert string to array buffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="pt-3 px-1 bg-white">
              <div className="table-responsive">
                <div className="d-flex justify-content-end">
                  <button className="btn add-new" onClick={handleDownload}>
                    {" "}
                    Export excel{" "}
                  </button>
                </div>
                <table ref={tableRef}>
                  <thead>
                    <tr>
                      <th className="tdata text-center" colspan="2">
                        Excel-Vorlage zum Hochladen Von TiteIn
                      </th>
                      <td></td>
                    </tr>
                    <tr>
                      <td
                        rowspan="2"
                        colspan="5"
                        style={{
                          width: "900px",
                          fontSize: "18px",
                          height: "40px",
                        }}
                      >
                        {" "}
                        <span style={{ color: "#FF0000" }}>
                          1 PFLICHTFELDER (*):
                        </span>
                        Alle mit * gekennzeichneten Felder sind Pflichtfelder
                        und müssen für einen Upload ausgefüllt werden.
                      </td>
                    </tr>
                    <tr>
                      <td> </td>
                    </tr>
                    <tr>
                      <td
                        rowspan="2"
                        colspan="5"
                        style={{ width: "900px", fontSize: "18px" }}
                      >
                        <span style={{ color: "#FF0000" }}>2 TITEL :</span>
                        Titel die mehrfach aufgeführt wurden, bitte in der
                        entsprechenden Anzhal zur Setlist hinzufügen.
                      </td>
                    </tr>
                    <tr>
                      <td> </td>
                    </tr>

                    <tr>
                      <td
                        rowspan="2"
                        colspan="5"
                        style={{ width: "900px", fontSize: "18px" }}
                      >
                        <span style={{ color: "#FF0000" }}>
                          3. SATZANGABEN / SONSTIGER(R) TITEL:
                        </span>
                        Bei Werken mit mehreren Satzen tragen Sie bitte die
                        tatsachlich gespielten Satze in das Feld
                        "Satzangaben/Sonitage(r) Titel" ein.
                      </td>
                    </tr>
                    <tr>
                      <td> </td>
                    </tr>
                    <tr>
                      <td
                        rowspan="2"
                        colspan="5"
                        style={{ width: "900px", fontSize: "18px" }}
                      >
                        <span style={{ color: "#FF0000" }}>
                          4. SPIELDAUER / ANZAHL MUSIKER:
                        </span>
                        Bitte beachten Sie, dass die Spieldauer bei
                        Buhnen-/Theateraufführungen und bei klassischen
                        Konzerten eine Pflichtangabe darstellt. Weiterhin ist
                        bei klassischen Konzerten auch die Anzahl der
                        Musiker/Sanger Pflicht.
                      </td>
                    </tr>
                    <tr>
                      <td> </td>
                    </tr>
                    <tr>
                      <td
                        rowspan="2"
                        colspan="5"
                        style={{ width: "900px", fontSize: "18px" }}
                      >
                        <span style={{ color: "#FF0000" }}>
                          5. POTPOURRI/FRAGMENT:
                        </span>
                        Wenn Sie nur einen sehr kurzen Ausschnitt eines Werkes
                        gespielt haben, wahlen Sie bitte F (=Fragment) aus. Wenn
                        Sie mehrere kurze Ausschnitte verschiedener Werke
                        gespielt haben,wahlen Sie bitte P (=Potpourri) aus.
                      </td>
                    </tr>
                    <tr>
                      <td> </td>
                    </tr>
                    <tr>
                      <td
                        rowspan="2"
                        colspan="5"
                        style={{ width: "900px", fontSize: "18px" }}
                      >
                        <span style={{ color: "#FF0000" }}>
                          6. AUFTRITTSART BUHNEN-/ THEATERAUFFÜHRUNG:
                        </span>
                        Bitte beachten Sie, dass bei Theateraufführungen an
                        jedem Werk Angaben Zur Liveaufführung oder
                        Tonträgerwiedergabe (K19) verpflichtend sind. Im Falle
                        einer Tonträgerwiedergabe mussen zudem auch Angaben zur
                        Veroffentlichung / Verlegung des Werkes (L19) gemacht
                        werden. Verlegt ist der Tonträger in der Regel, wenn er
                        im Handel erhaltlich ist dies trifft auf eigens fur das
                        Theaterstuck komponierte und selbst aufgenommene Werke
                        haufig nicht zu. Bitte runden Sie auf ganze Minuten auf.
                      </td>
                    </tr>
                    <tr>
                      <th></th>
                    </tr>
                    <tr>
                      <th
                        style={{ backgroundColor: "#cc1412", color: "white" }}
                      >
                        Setlist
                      </th>
                      <th
                        style={{ backgroundColor: "#cc1412", color: "white" }}
                      ></th>
                    </tr>
                    <tr>
                      <th
                        style={{
                          backgroundColor: "#bfbfbf",
                          color: "black",
                          width: "100px",
                        }}
                      >
                        WERKNUMMER / WERKFASSUNGSNUMMER
                      </th>
                      <th
                        style={{
                          backgroundColor: "#bfbfbf",
                          color: "black",
                          width: "100px",
                        }}
                      >
                        TITEL /
                      </th>
                      <th
                        style={{
                          backgroundColor: "#bfbfbf",
                          color: "black",
                          width: "120px",
                        }}
                      >
                        SATZANGABE / SONSTIGE(R)
                      </th>
                      <th
                        style={{
                          backgroundColor: "#bfbfbf",
                          color: "black",
                          width: "120px",
                        }}
                      >
                        ANZAHL MUSIKER / SAENGER
                      </th>
                      <th
                        style={{
                          backgroundColor: "#bfbfbf",
                          color: "black",
                          width: "120px",
                        }}
                      >
                        SPIELDAUER (MM:SS)
                      </th>
                      <th
                        style={{
                          backgroundColor: "#bfbfbf",
                          color: "black",
                          width: "120px",
                        }}
                      >
                        INTERPRET/KOMPONIST
                      </th>
                      <th
                        style={{
                          backgroundColor: "#bfbfbf",
                          color: "black",
                          width: "120px",
                        }}
                      >
                        LIVET/ON TRAGER
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {localsetlist ? (
                      localsetlist.map((res, key) => {
                        return (
                          <tr>
                            <td>{res.gemma_number}</td>
                            <td>{res.song_name}</td>
                            <td></td>
                            <td></td>
                            <td>{res.time_length?.substr(3, 6)}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <p className="mt-5">Song Not Available !!</p>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </article>
  );
};

export default GemaSetlistPrint;
