import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getNotification } from "../../app_redux/slice/notification";
import { useTranslation } from "react-i18next";

const AllNotification = () => {
  const { t } = useTranslation();
  const cookee = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = cookee.get("token");
  const [pageNo, setPageNo] = useState(1);
  const { allNotification, loading } = useSelector(
    (state) => state.notification
  );
  const { tab34, tab35, tab36 } = t("extra");

  useEffect(() => {
    dispatch(getNotification({ pageno: pageNo }));
  }, []);
  const handleNext = (e) => {
    e.preventDefault();
    setPageNo(pageNo + 1);
    dispatch(getNotification({ pageno: pageNo + 1 }));
  };
  const handlePrevious = (e) => {
    e.preventDefault();
    setPageNo(pageNo - 1);
    dispatch(getNotification({ pageno: pageNo - 1 }));
  };

  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row mt-3">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <h3>{tab34}</h3>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12">
            {allNotification.map((item, i) => {
              return (
                <div className="mt-4">
                  <div className="d-flex justify-content-around">
                    <h5>{item.title}</h5>
                    <p className="notranslate">
                      {" "}
                      {item?.created_at
                        ? new Date(item?.created_at).toLocaleString()
                        : "-"}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <nav aria-label="...">
                <ul className="pagination">
                  <li
                    className={
                      pageNo === 1 ? "page-item disabled" : "page-item"
                    }
                  >
                    <a className="page-link" href="#" onClick={handlePrevious}>
                      {tab36}
                    </a>
                  </li>
                  <li class="page-item active">
                    <a className="page-link" href="#">
                      {pageNo} <span className="sr-only">(current)</span>
                    </a>
                  </li>
                  <li
                    class={
                      allNotification?.length <= 9
                        ? "page-item disabled"
                        : "page-item"
                    }
                  >
                    <a className="page-link" href="#" onClick={handleNext}>
                      {tab35}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default AllNotification;
