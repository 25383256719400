import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";

const BandstaffDetail = () => {
  const cookee = new Cookies();
  const [bandstaffDetail, setBandStaffDetail] = useState("");
  const token = cookee.get("token");

  useEffect(() => {
    getBandStaff();
  }, []);
  const getBandStaff = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-staff-member-details`,
      data: {
        staff_id: localStorage.getItem("staffid"),
      },
    }).then((res) => {
      setBandStaffDetail(res.data.data);
    });
  };
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <form className="row mt-5 addNAme mx-width">
          <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
            <input
              type="text"
              className="form-control"
              value={bandstaffDetail?.staff_name}
              placeholder="Staff name"
            />
          </div>
          {/* <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
                        <input type="text" className="form-control" value={bandstaffDetail?.role} placeholder="Role" />
                    </div> */}
          <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
            <input type="text" className="form-control" placeholder="Street" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
            <input
              type="text"
              className="form-control"
              placeholder="Date of birth place"
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
            <input
              type="text"
              className="form-control"
              placeholder="GEMA membership number"
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
            <input
              type="text"
              className="form-control"
              placeholder="Location"
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
            <input
              type="text"
              className="form-control"
              value={bandstaffDetail?.phone_number}
              placeholder="Phone"
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
            <input type="text" className="form-control" placeholder="Country" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
            <input
              type="text"
              className="form-control"
              value={bandstaffDetail?.email}
              placeholder="Email"
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4 mb-5">
            <input
              type="text"
              className="form-control"
              placeholder="GEMA IPI number"
            />
          </div>
        </form>
      </div>
    </article>
  );
};

export default BandstaffDetail;
