import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import { useState } from "react";
import CalendarView from "../Calendar/calendar";
import { getEventDetail } from "../../app_redux/slice/eventDetail";
import { useDispatch, useSelector } from "react-redux";
import { getPermissionInfo } from "../../app_redux/slice/permissionInfo";
import { useTranslation } from "react-i18next";

const Dashboard = ({ switchtoken }) => {
  const { t } = useTranslation();
  const cookee = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [events, setEvents] = useState();
  const location = useLocation();
  const { pathname } = location;
  const [view, setView] = useState(false);
  const [newTask, setNewTask] = useState([]);
  const [assignTask, setAssignTask] = useState([]);
  const token = cookee.get("token");
  const { tab1, tab2 } = t("dashboard");

  const queryParams = new URLSearchParams(window.location.search);
  const tokens = queryParams.get("token");
  useEffect(() => {
    // if (queryParams.get("token")) {
    //     cookee.set('token', queryParams.get("token"))
    //     getMusicianInfo({ token: queryParams.get("token") })
    // }
    setTimeout(getEvents, 1000);
    setTimeout(getNewTasks, 1000);
    setTimeout(getAssignTasks, 1000);
  }, [queryParams.get("token")]);

  useEffect(() => {
    dispatch(getPermissionInfo({ id: cookee.get("redirect_userid") }));
  }, []);

  const handleCalendarView = () => {
    setView(!view);
  };
  const getEvents = () => {
    axios({
      method: "get",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: switchtoken ? switchtoken : token,
      },
      url: `${process.env.REACT_APP_URL}/band-leader-dashboard-upcoming-event`,
    }).then((res) => {
      // console.log(res.data)
      setEvents(res.data.data);
    });
  };
  const handleEventClick = (id) => {
    dispatch(getEventDetail({ eventid: id }));
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-event-details-event-id`,
      data: {
        event_id: id,
      },
    }).then((res) => {
      // localStorage.setItem("eventData", JSON.stringify(res.data.data))
      navigate("/edit-event");
    });
  };
  const getNewTasks = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: switchtoken ? switchtoken : token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-task-list`,
      data: {
        page_no: 1,
        is_self: 1,
        status: 1,
      },
    }).then((res) => {
      setNewTask(res.data.data);
    });
  };
  const getAssignTasks = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: switchtoken ? switchtoken : token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-task-list`,
      data: {
        page_no: 1,
        is_self: 0,
        status: 1,
      },
    }).then((res) => {
      setAssignTask(res.data.data);
    });
  };
  const handleClick = (id) => {
    localStorage.setItem("taskid", id);
    navigate("/task-overview");
  };
  const handleChat = (id) => {
    navigate("/chat-event", { state: { eventid: id || "" } });
  };
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row mt-3">
          <div className="col-sm-12 col-md-6 col-lg-8 col-xl-8">
            <div className="bg-white p-2">
              <h4>{tab1}</h4>
              <hr />
              <div className="row">
                {events?.length !== 0 ? (
                  events?.map((res, i) => {
                    return (
                      <div className="col-sm-6 col-md-12 col-lg-6 col-xl-6">
                        <div className="eventBoxBg">
                          <img
                            src="assets/images/Group329.png"
                            className="evntImages"
                            alt=""
                            onClick={() => handleEventClick(res.event_id)}
                          />
                          <div
                            className=""
                            onClick={() => handleEventClick(res.event_id)}
                          >
                            <h6 className="text-capitalize mb-0">
                              {res.event_type_name}
                            </h6>
                            <p className="text-capitalize notranslate">
                              {res.event_name}
                            </p>
                            <div className="d-flex align-items-center gap-2 text-capitalize mt-2">
                              <img
                                src="assets/images/deadline.png"
                                width=""
                                alt=""
                              />
                              <span className="fw-bold notranslate">
                                {res.start_date.substr(0, 10)}
                              </span>
                            </div>
                            <div className="d-flex align-items-center gap-2 text-capitalize mt-2">
                              <img
                                src="assets/images/gpsBlack.png"
                                width="18px"
                                alt=""
                              />
                              <span className="notranslate">{res.town}</span>
                            </div>
                          </div>
                          <div className="ChatDear">
                            <p>
                              <img
                                src="assets/images/deadlineBlackRed.png"
                                width="25px"
                                alt=""
                                onClick={handleCalendarView}
                              />
                            </p>
                            <p>
                              <img
                                src="assets/images/chatBlackRed.png"
                                width="30px"
                                className=""
                                alt=""
                                onClick={() => handleChat(res.event_id)}
                              />{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>{tab2}</p>
                )}
              </div>
            </div>
            {view ? <CalendarView /> : ""}
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <div className="bg-white">
              <h4 className="d-flex align-items-center justify-content-between px-2 pt-2 mb-0">
                Tasks
                <img src="assets/images/taskRedBlack.png" alt="" />
              </h4>
              <hr />
              {newTask?.length !== 0
                ? newTask.map((res, i) => {
                    return (
                      <div
                        className="chatList TaskList px-2"
                        onClick={() => handleClick(res.band_leader_task_id)}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src="assets/images/taskIconPink.png"
                            alt=""
                            className="TaskIcon"
                          />
                          <div className="">
                            <h5 className="text-capitalize mb-0 notranslate">
                              {res.task_name}
                            </h5>
                            <small className="fw-bold notranslate">
                              {res.dead_line?.substr(0, 10)}
                            </small>
                          </div>
                        </div>
                        <div className="">
                          <small className="white-space">
                            <b></b>
                          </small>
                        </div>
                      </div>
                    );
                  })
                : ""}
              {assignTask?.length !== 0
                ? assignTask.map((res, i) => {
                    return (
                      <div
                        className="chatList TaskList px-2"
                        onClick={() => handleClick(res.band_leader_task_id)}
                      >
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src="assets/images/taskIconPink.png"
                            alt=""
                            className="TaskIcon"
                          />
                          <div className="">
                            <h5 className="text-capitalize mb-0 notranslate">
                              {res.task_name}
                            </h5>
                            <small className="fw-bold">
                              {res.dead_line?.substr(0, 10)}
                            </small>
                          </div>
                        </div>
                        <div className="">
                          <small className="white-space">
                            <b></b>
                          </small>
                        </div>
                      </div>
                    );
                  })
                : ""}
              {assignTask?.length === 0 && newTask?.length === 0 ? (
                <p>{tab2}</p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Dashboard;
