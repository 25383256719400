import React from 'react'
import { ThreeDots } from 'react-loader-spinner'


const DataLoader = () => {
    return (
        <div>
            <ThreeDots
                height="80"
                width="80"
                color="#ab2421;"
                radius="20"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ display: "flex", justifyContent: "center",position: "fixed", top: "30%", left: "50%", transform: "translate(-50%, -50%)" }}
                wrapperClass=""
                visible={true}
            />
        </div>
    )
}

export default DataLoader