import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import OtpVerify from "./otpVerify";
import { useTranslation } from "react-i18next";

const DeleteAccount = ({ show, handleShow, id, pageNo }) => {
  const navigate = useNavigate();
  const cookee = new Cookies();
  const token = cookee.get("token");
  const email = cookee.get("email");
  const [otp, setOtp] = useState(false);
  const { t } = useTranslation();
  const { tab1, tab2, tab3 } = t("extra");

  const showOtp = () => {
    setOtp(!otp);
  };
  const handleDelete = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/delete-band-leader-account`,
    })
      .then((res) => {
        //console.log("res", res.data)
        if (res.status === 200) {
          cookee.remove("token");
          cookee.remove("email");
          cookee.remove("phonenumber");
          cookee.remove("bandname");
          cookee.remove("devicetoken");
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/login-email");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handleEmailOtp = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_URL}/send-musician-otp-with-email`,
      data: {
        email: email,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          showOtp();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <>
      <Modal show={show} onHide={handleShow} centered size="md">
        <Modal.Body className="p-0">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close close-Icon"
              onClick={handleShow}
            ></button>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 text-center mt-4">
                <h6>{tab1}</h6>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                <div className=" text-center my-3 mt-3">
                  <button className="btn add-new-cancel" onClick={handleShow}>
                    {tab2}
                  </button>
                  <button className="btn add-new-del" onClick={handleEmailOtp}>
                    {tab3}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {otp ? (
        <OtpVerify
          show={otp}
          handleShow={showOtp}
          handleDelete={handleDelete}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default DeleteAccount;
