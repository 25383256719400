import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getFiles } from "../../app_redux/slice/files";
import S3FileUpload from "react-s3";
window.Buffer = window.Buffer || require("buffer").Buffer;

const MusicfileUser = ({ show, handleShow, id, name, userid }) => {
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const token = cookee.get("token");
  const [members, setMembers] = useState([]);
  const [image, setImage] = useState("");
  const [loader, setLoader] = useState(false);
  const [userId, setUserId] = useState("");
  const { allFile } = useSelector((state) => state.file);
  const [filesize, setFileSize] = useState("");
  useEffect(() => {
    dispatch(getFiles({ songid: id, userid: userid }));
  }, []);
  function bytesToSize(bytes) {
    var sizes = ["B", "K", "M", "G", "T", "P"];
    for (var i = 0; i < sizes.length; i++) {
      if (bytes <= 1024) {
        return bytes + " " + sizes[i];
      } else {
        bytes = parseFloat(bytes / 1024).toFixed(2);
        setFileSize(`${bytes} kb`);
      }
    }
    return bytes + " P";
  }

  const handleFile = (e, ids) => {
    setLoader(true);
    bytesToSize(e.target.files[0].size);
    let file = e.target.files[0];
    const config = {
      bucketName: "media-appsinvo",
      region: "us-east-2",
      accessKeyId: "AKIA6LSDBEL3U2HOJWLW",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        // console.log("RES file", data)
        setImage(data.location);
        setLoader(false);
        uploadMusicfile(data.location, ids, filesize);
      })
      .catch((err) => console.error("ERR", err));
  };

  const handleUserClick = (ids) => {
    setUserId(ids);
    dispatch(getFiles({ songid: id, userid: ids }));
  };

  const uploadMusicfile = (i, ids, filesize) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/band-leader-upload-song-files-for-musician`,
      data: {
        m_song_id: id,
        user_id: userId,
        musician_song_url: "",
        musician_text_sheet_url: "",
        musician_music_sheet_url: i,
        musician_music_sheet_size: filesize ? filesize : "",
        musician_text_sheet_size: "",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch(getFiles({ songid: id, userid: ids }));
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const deleteMusicFile = (ids) => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/delete-band-leader-uploaded-sheet`,
      data: {
        m_song_id: id,
        user_id: ids,
        is_music_sheet: 1,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch(getFiles({ songid: id, userid: ids }));
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleShow} size="lg">
        <Modal.Body className="p-0">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close close-Icon"
              onClick={handleShow}
            ></button>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <h4 className=" text-center">{name}</h4>
                <p className=" text-center">Music Files</p>
                <div className="pt-3 px-1 bg-white">
                  <div className="table-responsive">
                    <table className="table storetable text-center">
                      <thead className="">
                        <tr>
                          {/* <th scope="col">name</th>
                                                    <th scope="col">upload</th> */}
                          <th scope="col">download</th>
                          <th scope="col">size</th>
                          <th scope="col">action</th>
                        </tr>
                        <tr className="border-0 bg-none invisible">
                          <td className="border-0 bg-none"></td>
                        </tr>
                      </thead>
                      {loader ? (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        ""
                      )}
                      <tbody>
                        {allFile?.length !== 0 ? (
                          allFile.map((res, key) => {
                            return (
                              <tr>
                                <td
                                  onClick={() => handleUserClick(res.user_id)}
                                >
                                  {" "}
                                  <div className="d-flex gap-3 justify-content-center">
                                    {allFile[0]?.musician_music_sheet_url ? (
                                      <a
                                        href={
                                          allFile[0]?.musician_music_sheet_url
                                        }
                                        target="_blank"
                                        download={
                                          allFile[0]?.musician_music_sheet_url
                                        }
                                      >
                                        <img
                                          src="assets/images/live-file.png"
                                          width="25px"
                                          alt=""
                                        />
                                      </a>
                                    ) : (
                                      "NA"
                                    )}
                                  </div>
                                </td>
                                <td>
                                  {(allFile[0]?.musician_music_sheet_size &&
                                    allFile[0]?.musician_music_sheet_url) ||
                                  "2.1kb"
                                    ? allFile[0]?.musician_music_sheet_size
                                    : "NA"}
                                </td>
                                <td>
                                  <div className="d-flex gap-3 justify-content-center">
                                    <img
                                      src="assets/images/delete.png"
                                      width="25px"
                                      alt=""
                                      onClick={() =>
                                        deleteMusicFile(res.user_id)
                                      }
                                    />{" "}
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <p className="mt-5">No File Found!!</p>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default MusicfileUser;
