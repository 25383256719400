import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { getBandStaff } from "../../app_redux/slice/bandstaffList";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "./deleteModal";
import StaffDetailModal from "./staffDetailModal";
import S3FileUpload from "react-s3";
import ChangePermissionModal from "./changePermission";
import { getBandleaderProfile } from "../../app_redux/slice/bandleaderProfile";
import { getMusicianInfo } from "../../app_redux/slice/getMusicianInfo";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import ButtonLoader from "../Loader/loading";
window.Buffer = window.Buffer || require("buffer").Buffer;

const Bandstaff = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cookee = new Cookies();
  // const [bandstaff, setBandStaff] = useState([])
  const token = cookee.get("token");
  const lng = cookee.get("googtrans");
  const { bandProfile } = useSelector((state) => state.bandleaderprofile);
  const [load, setLoad] = useState(false);
  const [profileData, setProfileData] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [town, setTown] = useState("");
  const [country, setCountry] = useState({
    value: "",
    label: bandProfile.country,
  });
  const [countryCode, setCountryCode] = useState("");
  const [language, setLanguage] = useState("");
  const [dob, setDob] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [freeseat, setFreeseat] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [housenumber, setHousenumber] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [staffId, setStaffId] = useState("");
  const [bandHousenumber, setBandHousenumber] = useState("");
  const [bandStreet, setBandStreet] = useState("");
  const [bandTown, setBandTown] = useState("");
  const [bandZipcode, setBandZipcode] = useState("");
  const [name, setName] = useState("");
  const [bandleaderName, setBandleaderName] = useState("");
  const [bandleaderSurname, setBandleaderSurname] = useState("");
  const [detailModal, setDetailModal] = useState(false);
  const [permissionModal, setPermissionModal] = useState(false);
  const [image, setImage] = useState("");
  const [loader, setLoader] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [data, setData] = useState([]);
  const [bandcountry, setBandCountry] = useState({
    value: "",
    label: bandProfile.band_country,
  });
  const [permissionId, setPermissionId] = useState("");
  const { tab1, tab2, tab3, tab4, tab5, tab6, tab7, tab8, tab9 } = t("profile");

  var german = require("localized-countries")(
    require("localized-countries/data/de")
  );
  const options = german.array();

  useEffect(() => {
    getBandstaffDetail();
    dispatch(getBandStaff({ pageno: pageNo }));
    getMusician();
    dispatch(getBandleaderProfile());
  }, []);

  const handleNext = (e) => {
    e.preventDefault();
    setPageNo(pageNo + 1);
    dispatch(getBandStaff({ pageno: pageNo + 1 }));
  };
  const changeHandler1 = (value) => {
    setBandCountry(value);
  };
  const changeHandler2 = (value) => {
    setCountry(value);
  };
  const handlePrevious = (e) => {
    e.preventDefault();
    setPageNo(pageNo - 1);
    dispatch(getBandStaff({ pageno: pageNo - 1 }));
  };
  const getBandstaffDetail = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      data: {
        page_no: 1,
      },
      url: `${process.env.REACT_APP_URL}/get-all-band-leader-staff-list`,
    }).then((res) => setData(res.data.data2));
  };
  const handleFile = (e) => {
    setLoader(true);
    let file = e.target.files[0];
    const config = {
      bucketName: "clever-band-assist-s3",
      region: "eu-west-3",
      accessKeyId: "AKIASTLKHDND66NQZXFJ",
      secretAccessKey: "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",
    };
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        //  console.log("RES", data)
        setImage(data.location);
        setLoader(false);
      })
      .catch((err) => console.error("ERR", err));
  };
  const handleChange = (e, setState) => {
    setState(e.target.value);
  };
  const handleShowDelete = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  const handleShowDetail = () => {
    setDetailModal(!detailModal);
  };
  const handleShowChange = () => {
    setPermissionModal(!permissionModal);
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    setLoad(true);
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/update-band-leader-profile`,
      data: {
        band_name: name,
        profile_pic: image ? image : profilePic,
        email: email,
        country_code: countryCode,
        phone_number: phone,
        city: town,
        country: country?.label,
        street: street,
        town: town,
        zip_code: zipcode,
        house_number: housenumber,
        dob: "04/12/1997",
        gema_ipi_number: "",
        gema_membership_number: "",
        language: language,
        band_street: bandStreet,
        band_zip_code: bandZipcode,
        band_country: bandcountry?.label,
        band_house_number: bandHousenumber,
        band_town: bandTown,
        band_leader_first_name: bandleaderName,
        band_leader_last_name: bandleaderSurname,
      },
    })
      .then((res) => {
        // console.log("RESPONSE", res.data)
        if (res.status === 200) {
          setLoad(false);
          cookee.set("token", res.data.data.auth_token);
          cookee.set("email", res.data.data.email);
          cookee.set("phonenumber", res.data.data.phone_number);
          cookee.set("bandname", res.data.data.band_name);
          dispatch(getMusicianInfo());
          toast.success(tab9, {
            theme: "colored",
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        setLoad(false);
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const getMusician = () => {
    axios({
      method: "get",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-profile`,
    }).then((res) => {
      setProfileData(res.data.data);
      //  setBandName(res.data.data.band_name)
      setBandleaderName(res.data.data.band_leader_first_name);
      setBandleaderSurname(res.data.data.band_leader_last_name);
      setEmail(res.data.data.email);
      setPhone(res.data.data.phone_number);
      setCity(res.data.data.city);
      setTown(res.data.data.town);
      // setCountry(res.data.data.country)
      // setBandCountry(res.data.data.band_country)
      setCountryCode(res.data.data.country_code);
      setDob(res.data.data.dob);
      setLanguage(res.data.data.language);
      //  setStreet(res.data.data.street)
      setFreeseat(res.data.data.free_seats);
      setVehicle(res.data.data.vehicle);
      setProfilePic(res.data.data.profile_pic);
      setStreet(res.data.data.street);
      setZipcode(res.data.data.zip_code);
      setHousenumber(res.data.data.house_number);
      setBandHousenumber(res.data.data.band_house_number);
      setBandStreet(res.data.data.band_street);
      setBandTown(res.data.data.band_town);
      setBandZipcode(res.data.data.band_zip_code);
      setName(res.data.data.band_name);
    });
  };

  const deleteBandStaff = (id) => {
    setStaffId(id);
    handleShowDelete();
  };
  const detailBandStaff = (id, ids) => {
    if (ids === 1) {
      setStaffId(id);
      handleShowDetail();
    }
  };

  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row mt-3">
            <form className="row mt-5 addNAme mx-width">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-5">
                {loader ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div className="profilePhoto position-static">
                    <div className="d-flex gap-2 align-items-center">
                      {profilePic ? (
                        <label for="image">
                          <input
                            type="file"
                            name="image"
                            id="image"
                            onChange={handleFile}
                            style={{ display: "none" }}
                          />
                          <img
                            src={image ? image : profilePic}
                            className="profileIMg"
                            alt=""
                          />
                        </label>
                      ) : (
                        <label for="image">
                          <input
                            type="file"
                            name="image"
                            id="image"
                            onChange={handleFile}
                            style={{ display: "none" }}
                          />
                          <img
                            src={
                              image ? image : "assets/images/Profile_pic.png"
                            }
                            className="profileIMg"
                            alt=""
                          />
                        </label>
                      )}
                    </div>

                    {/* <div className="file-input">
                                            <input type="file" name="file-input" id="file-input" className="file-input__input" onChange={handleFile} />
                                            <label className="file-input__label add-new" for="file-input">
                                                Update</label>
                                        </div> */}
                  </div>
                )}
              </div>

              <h4>
                {tab1}{" "}
                <img
                  src="assets/images/Infoblack.png"
                  alt=""
                  className="mx-1 mb-4"
                  title={
                    lng === "/auto/de" || "/en/de"
                      ? "Dieser Name und Adresse dient lediglich Ihren Informationszwecken und wird derzeit in keiner Schnittstellen verwendet"
                      : "This name and address is for your information only and is not currently used in any interfaces"
                  }
                />{" "}
              </h4>
              <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => handleChange(e, setName)}
                  placeholder={tab7}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                <input
                  type="text"
                  className="form-control"
                  value={bandStreet}
                  onChange={(e) => handleChange(e, setBandStreet)}
                  placeholder={tab2}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                <input
                  type="text"
                  className="form-control"
                  value={bandHousenumber}
                  onChange={(e) => handleChange(e, setBandHousenumber)}
                  placeholder={tab3}
                />
              </div>

              <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                <Select
                  className="form-control p-0 notranslate"
                  options={options}
                  value={bandcountry}
                  placeholder={tab6}
                  onChange={changeHandler1}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                <input
                  type="text"
                  className="form-control"
                  value={bandZipcode}
                  onChange={(e) => handleChange(e, setBandZipcode)}
                  placeholder={tab4}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                <input
                  type="text"
                  className="form-control"
                  value={bandTown}
                  onChange={(e) => handleChange(e, setBandTown)}
                  placeholder={tab5}
                />
              </div>
            </form>
            <form className="row mt-2 addNAme mx-width">
              <h4>
                Bandleader{" "}
                <img
                  src="assets/images/Infoblack.png"
                  alt=""
                  className="mx-2 mb-4"
                  title={
                    lng === "/auto/de" || "/en/de"
                      ? "Dieser Name und Adresse wird bei den an die GEMA zu übermittelnden Musikfolgenlisten, als „Musikalischer Leiter„ verwendet. Diese Schnittstelle wird nur bei einem bestehenden Abwicklungsvertrag mit CleverMusik aktiv."
                      : "This name and address will be used as musical director in the music sequence lists to be sent to GEMA. This interface is only active if there is an existing settlement agreement with CleverMusik"
                  }
                />
              </h4>
              <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                <input
                  type="text"
                  name="bandleaderName"
                  value={bandleaderName}
                  onChange={(e) => handleChange(e, setBandleaderName)}
                  className="form-control"
                  placeholder="Name"
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                <input
                  type="text"
                  name="bandleaderSurname"
                  value={bandleaderSurname}
                  onChange={(e) => handleChange(e, setBandleaderSurname)}
                  className="form-control"
                  placeholder={tab8}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                <input
                  type="text"
                  name="street"
                  value={street}
                  onChange={(e) => handleChange(e, setStreet)}
                  className="form-control"
                  placeholder={tab2}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                <input
                  type="text"
                  name="housenumber"
                  value={housenumber}
                  onChange={(e) => handleChange(e, setHousenumber)}
                  className="form-control"
                  placeholder={tab3}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                <input
                  type="text"
                  className="form-control"
                  value={zipcode}
                  onChange={(e) => handleChange(e, setZipcode)}
                  placeholder={tab4}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                <input
                  type="text"
                  className="form-control"
                  name="city"
                  value={town}
                  onChange={(e) => handleChange(e, setTown)}
                  placeholder={tab5}
                />
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
                <Select
                  className="form-control p-0 notranslate"
                  options={options}
                  value={country}
                  placeholder={tab6}
                  onChange={changeHandler2}
                />
              </div>
            </form>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
              <div className="d-flex align-items-center lg-justify-content-end">
                {load ? (
                  <ButtonLoader view="btn add-new" />
                ) : (
                  <button className="btn add-new" onClick={handleUpdateProfile}>
                    {lng === "/auto/de" || "/en/de" ? "SPEICHERN" : "safe"}
                  </button>
                )}
              </div>
            </div>
          </div>
          {/* <div className="row mt-3">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="pt-3 px-1 bg-white shadow">
                                <div className="table-responsive">
                                    <table className="table storetable text-center">
                                        <thead className="">
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Permission <img src="assets/images/Infoblack.png" alt="" className="mx-2" title="Permission of a particular user in a band will be shown here like bandleader,staff,musician etc and you can update the permission by clicking into it." /></th>
                                                <th scope="col">Membership Status <img src="assets/images/Infoblack.png" alt="" className="mx-2" title="Membership status will be shown here, user accept or reject the membership status." /></th>
                                                <th scope="col"></th>
                                            </tr>
                                            <tr className="border-0 bg-none invisible"><td className="border-0 bg-none"></td></tr>
                                        </thead>
                                        <tbody>
                                            {data?.length !== 0 ? data.map((res, i) => {
                                                return <>
                                                    <tr>
                                                        <td onClick={() => detailBandStaff(res.staff_id, res.is_joined_membership
                                                        )}><img src="assets/images/down-filled-triangular-arrow.png" width="" alt="" /></td>
                                                        <td><span className="greenColor  fw-bold">{res.email}</span></td>
                                                        <td>{res.country_code}{res.phone_number}</td>
                                                        <td>
                                                            {res.permission_type === 1 ? <h6>Musician</h6> : ""}
                                                            {res.permission_type === 2 ? <h6>Bandleader</h6> : ""}
                                                            {res.permission_type === 3 ? <h6>Staff</h6> : ""}
                                                            {res.permission_type === 4 ? <h6>Manager</h6> : ""}
                                                            {res.permission_type === 5 ? <h6>Booker</h6> : ""}
                                                        </td>
                                                        <td >
                                                            {res.is_joined_membership === 0 && res.is_rejected === 0 ?
                                                                <button className="btn add-new" >pending</button>
                                                                : ""}
                                                            {res.is_joined_membership === 1 ?
                                                                <button className="btn add-new" style={{ background: "#30ab21", border: "none" }} >accepted</button>
                                                                : ""}
                                                            {res.is_rejected === 1 && res.is_joined_membership === 0 ?
                                                                <button className="btn add-new" >rejected</button> : ""}
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                </>
                                            }) : ""}
                                            {allBandStaff?.length !== 0 ? allBandStaff.map((res, i) => {
                                                return <>
                                                    <tr>
                                                        <td onClick={() => detailBandStaff(res.staff_id, res.is_joined_membership
                                                        )}><img src="assets/images/down-filled-triangular-arrow.png" width="" alt="" /></td>
                                                        <td><span className="greenColor  fw-bold">{res.email}</span></td>
                                                        <td>{res.country_code}{res.phone_number}</td>
                                                        <td onClick={() => handlePermission(res.staff_id, res.permission_detail_id, res.permission_type)} style={{ cursor: "pointer" }}>
                                                            {res.permission_type === 1 ? <h6>Musician</h6> : ""}
                                                            {res.permission_type === 2 ? <h6>Bandleader</h6> : ""}
                                                            {res.permission_type === 3 ? <h6>Staff</h6> : ""}
                                                            {res.permission_type === 4 ? <h6>Manager</h6> : ""}
                                                            {res.permission_type === 5 ? <h6>Booker</h6> : ""}
                                                        </td>
                                                        <td >
                                                            {res.is_joined_membership === 0 && res.is_rejected === 0 ?
                                                                <button className="btn add-new" >pending</button>
                                                                : ""}
                                                            {res.is_joined_membership === 1 ?
                                                                <button className="btn add-new" style={{ background: "#30ab21", border: "none" }} >accepted</button>
                                                                : ""}
                                                            {res.is_rejected === 1 && res.is_joined_membership === 0 ?
                                                                <button className="btn add-new" >rejected</button> : ""}
                                                        </td>
                                                        <td onClick={() => deleteBandStaff(res.staff_id)}><img src="assets/images/deleteBlack.png" width="25px" alt="" style={{ cursor: "pointer" }} /></td>
                                                    </tr>
                                                </>
                                            }) : ""}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {pageNo === 1 && allBandStaff?.length <= 9 ? "" :
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                                <div className="d-flex align-items-center lg-justify-content-end">
                                    <nav aria-label="...">
                                        <ul className="pagination">
                                            <li className={pageNo === 1 ? "page-item disabled" : "page-item"}>
                                                <a className="page-link" href="#" onClick={handlePrevious}>Previous</a>
                                            </li>
                                            <li class="page-item active">
                                                <button className="page-link" href="#">{pageNo} <span className="sr-only">(current)</span></button>
                                            </li>
                                            <li class={allBandStaff?.length <= 9 ? "page-item disabled" : "page-item"}>
                                                <a className="page-link" href="#" onClick={handleNext}>Next</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>}
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                            <div className="d-flex align-items-center lg-justify-content-end">
                                <Link to="/add-band-staff" className="btn add-new">add person</Link>
                            </div>
                        </div>
                    </div> */}
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </article>
      {showDeleteModal ? (
        <DeleteModal
          show={showDeleteModal}
          handleShow={handleShowDelete}
          id={staffId}
        />
      ) : (
        ""
      )}
      {detailModal ? (
        <StaffDetailModal
          show={detailModal}
          handleShow={handleShowDetail}
          id={staffId}
        />
      ) : (
        ""
      )}
      {permissionModal ? (
        <ChangePermissionModal
          show={permissionModal}
          handleShow={handleShowChange}
          id={staffId}
          permissionId={permissionId}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Bandstaff;
