import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEventChatList } from "../../app_redux/slice/eventChatlist";
import { getEventChats } from "../../app_redux/slice/eventChat";
import { getBandChats } from "../../app_redux/slice/bandChat";
import { getTaskChatList } from "../../app_redux/slice/taskChatlist";
import { getTaskChats } from "../../app_redux/slice/taskChat";
import { useTranslation } from "react-i18next";

const ChatListItemsEvent = ({
  setEventId,
  band,
  event,
  task,
  setTaskid,
  setChatName,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { tab3, tab4 } = t("chat");

  const { allEventChatList } = useSelector((state) => state.eventchatlist);
  const { allTaskChatList } = useSelector((state) => state.taskchatlist);

  useEffect(() => {
    dispatch(getEventChatList());
    dispatch(getTaskChatList());
  }, []);

  const handleChatClick = (eventid, name) => {
    setEventId(eventid);
    setChatName(name);
    dispatch(getEventChats({ eventid: eventid }));
    // setInterval(function () { dispatch(getEventChats({ eventid: eventid })) }, 3000)
  };

  return (
    <>
      {allEventChatList?.length > 0 ? (
        allEventChatList?.map((res, i) => {
          return (
            <div className="chatList px-2 MsgActive">
              <div
                className="d-flex align-items-center gap-2"
                onClick={() => handleChatClick(res.event_id, res.chat_name)}
              >
                <div className="position-relative">
                  {/* <img src="assets/images/profile.png" alt="" className="chatProfile" /> */}
                  <span className="activeUser"></span>
                </div>
                <div className="">
                  <h6 className="text-capitalize mb-0 notranslate">
                    {res?.chat_name}
                  </h6>
                  <p className="ellip-2 sortMsg notranslate">
                    {res?.last_message}
                  </p>
                </div>
              </div>
              {/* <div className="text-center">
                            <small className="white-space">{res?.created_at
                                ? new Date(
                                    res?.last_message_send_receive_at
                                ).toLocaleString()
                                : "-"}</small>
                        </div> */}
            </div>
          );
        })
      ) : (
        <p className="text-center">{tab3}</p>
      )}
    </>
  );
};

export default ChatListItemsEvent;
