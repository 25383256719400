import React, { useState, useMemo, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import countryList from "react-select-country-list";
import CalendarView from "../../Calendar/calendar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SafeSetup from "./safeSetupModal";
import LoadSetup from "./loadsetupModal";
import InvitationModal from "./invitationModal";
import { getPermissionInfo } from "../../../app_redux/slice/permissionInfo";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const AddRehearealEvent = () => {
  const { t } = useTranslation();
  const cookee = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const lng = cookee.get("googtrans");
  const [info, setInfo] = useState(true);
  const [band, setBand] = useState(false);
  const [event_id, setEvent_Id] = useState("");
  const [transport, setTransport] = useState(false);
  const [setlist, setSetList] = useState(false);
  const [attachment, setAttachemnt] = useState(false);
  const [eventName, setEventName] = useState("");

  const [getInStart, setGetInStart] = useState("");
  const [getInEnd, setGetInEnd] = useState("");
  const [button, setButton] = useState(true);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showLoadsetup, setShowLoadsetup] = useState(false);

  const [locationName, setLocationName] = useState("");
  const [locationStreet, setLocationStreet] = useState("");
  const [locationPostal, setLocationPostal] = useState("");
  const [locationTown, setLocationTown] = useState("");
  const [locationContactPerson, setLocationContactPerson] = useState("");
  const [locationEmail, setLocationEmail] = useState("");
  const [locationPhone, setLocationPhone] = useState("");
  const [locationOtherInfo, setLocationOtherInfo] = useState("");
  const [locationHouseNo, setLocationHouseNo] = useState("");

  const [personList, setPersonList] = useState([
    {
      role: "",
      person: [
        { user_id: "", priority: 1, amount: "", additional_information: "" },
      ],
    },
  ]);
  const [bandStaffList, setBandStaffList] = useState([
    { user_id: "", amount: "", role: "", description: "" },
  ]);

  const [addBandStaff, setAddBandStaff] = useState(false);
  const [date, setDate] = useState("");
  const [role, setRole] = useState("");
  const [person, setPerson] = useState("");

  const [file, setFile] = useState("");
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  // const options = useMemo(() => countryList().getData(), [])
  var german = require("localized-countries")(
    require("localized-countries/data/de")
  );
  const options = german.array();
  const [bandmember, setBandMember] = useState([]);
  const [bandmember2, setBandMember2] = useState([]);
  const [priorityCount, setPriorityCount] = useState(2);
  const [loadsetupPersonList, setLoadsetupPersonList] = useState([]);
  const [view, setView] = useState(false);
  const [addMusicianShow, setAddMusicianshow] = useState(true);
  const [invitationShow, setInvitationShow] = useState(false);
  const [disabled, setDisabled] = useState([]);
  const [eventOtherInfo, setEventOtherInfo] = useState("");
  const [newLoad, setNewLoad] = useState([
    {
      role: "",
      person: [
        { user_id: "", priority: 1, amount: "", additional_information: "" },
      ],
    },
  ]);
  const [personSelectedList, setPersonSelectedList] = useState([]);
  const { permissionDetail } = useSelector((state) => state.permission);
  const {
    tab5,
    tab16,
    tab17,
    tab18,
    tab19,
    tab22,
    tab26,
    tab27,
    tab29,
    tab45,
    tab80,
    tab46,
    tab81,
    tab82,
    tab50,
    tab83,
  } = t("event");

  useEffect(() => {
    //  getEvents()
    dispatch(getPermissionInfo({ id: cookee.get("redirect_userid") }));
  }, []);

  const handleShowCancel = () => {
    setShowCancelModal(!showCancelModal);
  };
  const handleShowLoadsetup = () => {
    setShowLoadsetup(!showLoadsetup);
  };
  const handleShowInvitation = () => {
    setInvitationShow(!invitationShow);
  };
  const getBandMembers = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-staff-members-with-membership`,
      data: {
        page_no: 1,
        is_staff: 0,
        event_id: event_id,
      },
    }).then((res) => {
      // console.log(res.data)
      setBandMember(res.data.data);
    });
  };
  const getBandMembersTwo = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/get-band-leader-staff-members-with-membership`,
      data: {
        page_no: 1,
        is_staff: 1,
        event_id: event_id,
      },
    }).then((res) => {
      // console.log(res.data)
      setBandMember2(res.data.data);
    });
  };
  const handleInputPerson = (e, i, index) => {
    const { name, value } = e.target;
    const list = [...personList];

    if (name === "role") {
      list[i][name] = value;
    } else {
      list[i].person[index][name] = value;
    }
    setPersonList(list);
    setPersonSelectedList([...personSelectedList, e.target.value]);
  };
  const handleInputBand = (e, i, index) => {
    const { name, value } = e.target;
    const list = [...bandStaffList];
    list[i][name] = value;
    setBandStaffList(list);
  };
  const handleAddPriority = (e, role, i) => {
    setPriorityCount(priorityCount + 1);
    const { name, value } = e.target;
    // setPriorityShow(true)
    // setIdentity(i)
    let list = [...personList];
    list[i].person = [
      ...personList[i].person,
      {
        user_id: "",
        priority: priorityCount,
        amount: "",
        additional_information: "",
      },
    ];

    // setPersonList([ { person: [{ user_id: "", priority: "", amount: "", additional_information: "" }] }])
    setPersonList(list);
  };

  const handleRemoveStaff = (i, index) => {
    const list = [...bandStaffList];
    // if (index) {
    //     list[i].staff.splice(index, 1)
    // }
    // else {
    //     list.splice(i, 1)
    // }
    list.splice(i, 1);
    setBandStaffList(list);
  };
  const handleAddPerson = () => {
    setPriorityCount(2);
    // setPersonList([...personList, { user_id: "", role: "", priority: "", amount: "" }])
    setPersonList([
      ...personList,
      {
        role: "",
        person: [
          { user_id: "", priority: 1, amount: "", additional_information: "" },
        ],
      },
    ]);
  };
  const handleRemovePerson = (i, index) => {
    const list = [...personList];
    if (index) {
      list[i].person.splice(index, 1);
    } else {
      list.splice(i, 1);
    }
    setPersonList(list);
  };

  const handleAddBandPerson = () => {
    setBandStaffList([
      ...bandStaffList,
      { user_id: "", amount: "", role: "", description: "" },
    ]);
  };

  const handleCalendarView = () => {
    setView(!view);
  };
  const changeHandler = (value) => {
    setValue(value.label);
  };

  const handleCancelEvent = () => {
    handleShowCancel();
  };

  const handleEventInfo = (e) => {
    e.preventDefault();
    setButton(false);
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/create-event-info`,
      data: {
        user_id: permissionDetail?.user_id,
        event_name: eventName,
        info_organizer: [
          {
            organizer_name: "",
            organizer_street: "",
            organizer_house_number: "",
            organizer_postal: "",
            organizer_town: "",
            contact_person_name: "",
            email: "",
            phone_number: "",
            gema_customer_number: "",
            organizer_country: "",
            organizer_other_information: "",
          },
        ],
        info_accommodation: [
          {
            accomodation_name: "",
            accomodation_street: "",
            accomodation_house_number: "",
            accomodation_postal: "",
            accomodation_town: "",
            accomodation_country: "",
            accomodation_phone_number: "",
            accomodation_email: "",
            accomodation_other_information: "",
          },
        ],
        start_time: getInStart,
        end_time: getInEnd,
        load_start_time: "",
        load_end_time: "",
        sound_start_time: "",
        sound_end_time: "",
        stage_start_time: "",
        stage_end_time: "",
        type_of_event: "",
        type_of_performance: "",
        start_date: date,
        latitude: 15,
        longitude: 109.77,
        location_name: locationName,
        location_street: locationStreet,
        loc_name_of_person: locationContactPerson,
        location_email: locationEmail,
        location_phone_number: locationPhone,
        location_other_information: locationOtherInfo,
        town: locationTown,
        event_country: value,
        event_zip_code: locationPostal,
        event_house_number: locationHouseNo,
        event_type: 2,
        event_other_info: eventOtherInfo,
        parking: "",
        guest_list: "",
        catering: "",
        sonstige_other_info: "",
        is_without_appointment: 0,
        total_number_of_works: 0,
        number_of_works_with_copyright: 0,
        length_set: "00:00:00",
        is_band:
          permissionDetail?.permissionDetails?.permission_type == 5 ? 0 : 1,
      },
    })
      .then((res) => {
        //  console.log("RESPONSE", res.data)
        if (res.data.message === "Event Created Successfully!.") {
          setButton(true);
          toast.success("Event Created", {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          if (permissionDetail?.permissionDetails?.permission_type === 5) {
            navigate("/unconfirmed-events");
          }
          setEvent_Id(res.data.data.event_id);
          setBand(true);
          setInfo(false);
          setTransport(false);
          setSetList(false);
          setAttachemnt(false);
          getBandMembers();
          getBandMembersTwo();
        }
      })
      .catch((error) => {
        //console.log("Error", error.response)
        setButton(true);
        if (error.response.data.message === "Input Fields Cannot Be Empty!.") {
          setButton(true);
          toast.error("Event Name and Date Must be Added", {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const handleAddMusicians = (e, id, i) => {
    setDisabled([...disabled, i]);
    e.preventDefault();
    if (
      personList[0].role !== "" ||
      bandStaffList[0].role !== "" ||
      loadsetupPersonList?.length !== 0
    ) {
      axios({
        method: "post",
        headers: {
          Accept: "/",
          "Content-Type": "application/json",
          auth_token: token,
        },
        url: `${process.env.REACT_APP_URL}/add-musicians-to-event`,
        data: {
          event_id: event_id,
          event_type: 1,
          person: newLoad[0].role !== "" ? newLoad : personList,
          band_staff: bandStaffList[0].role === "" ? [] : bandStaffList,
        },
      })
        .then((res) => {
          //  console.log("RESPONSE", res.data)
          if (res.status === 200) {
            toast.success(res.data.message, {
              theme: "colored",
              position: "top-right",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setAddMusicianshow(false);
            handleShowInvitation();

            // setTransport(true)
            // setBand(false)
            // setInfo(false)
            // setSetList(false)
            // setAttachemnt(false)
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  const handleInfo = () => {
    setInfo(true);
    setBand(false);
    setTransport(false);
    setSetList(false);
    setAttachemnt(false);
  };
  const handleBand = () => {
    setBand(true);
    setInfo(false);
    setTransport(false);
    setSetList(false);
    setAttachemnt(false);
  };

  const handleChange = (e, setstate) => {
    setstate(e.target.value);
  };

  const token = cookee.get("token");

  return (
    <>
      <article className="col-lg-9">
        <div className="article-body store-bd-gy">
          <div className="row mt-3 align-items-center">
            <div className="col-12 col-sm-9 col-md-9 col-lg-10">
              <ul
                className="nav nav-pills group-nav tabNabs"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <Link
                    to="#"
                    className={info ? "nav-link  active" : "nav-link"}
                    onClick={handleInfo}
                  >
                    information
                  </Link>
                </li>

                {permissionDetail?.permissionDetails?.permission_type == 5 ? (
                  ""
                ) : (
                  <li className="nav-item">
                    <Link
                      to="#"
                      className={band ? "nav-link  active" : "nav-link"}
                      onClick={handleBand}
                    >
                      band
                    </Link>
                  </li>
                )}
              </ul>
            </div>

            <div class="col-12 col-sm-3 col-md-3 col-lg-2">
              <div class="d-flex align-items-center lg-justify-content-end">
                <Link to="#" className="btn notiIcon filterIcon">
                  <img
                    src={
                      view
                        ? "assets/images/calendar.png"
                        : "assets/images/calendarGrey.png"
                    }
                    onClick={handleCalendarView}
                    alt=""
                  />
                </Link>
              </div>
            </div>
            {view ? <CalendarView /> : ""}

            {!view ? (
              <div>
                {info ? (
                  <div className="col-sm-12">
                    <div className="pt-3 px-1 bg-white">
                      <div className="table-responsive">
                        <table className="table storetable infoEventTable">
                          <thead className="">
                            <tr>
                              <th
                                scope="col"
                                title={
                                  lng === "/auto/de" || "/en/de"
                                    ? "Zum erstellen einer Veranstaltung, müssen Sie den Namen und Datum eintragen. Alle weiteren Informationen können Sie zu einem späteren Zeitpunkt ergänzen. Den zu der Veranstaltung eingeladenen Bandmitglieder werden diese Informationen angezeigt."
                                    : "To create an event, you must enter the name and date. You can add any further information at a later date. Band members invited to the event will see this information."
                                }
                              >
                                {lng === "/auto/de" || "/en/de"
                                  ? "Info-Veranstaltung"
                                  : "info event"}
                                <img
                                  src="assets/images/Infoblack.png"
                                  alt=""
                                  className="mx-1 mb-4"
                                />
                              </th>
                              <th
                                scope="col"
                                title={
                                  lng === "/auto/de" || "/en/de"
                                    ? "Den zu der Veranstaltung eingeladenen Personen werden diese Informationen angezeigt. Ausgenommen hiervon sind der Name der Kontaktperson, Emailadresse sowie Telefon."
                                    : "The people invited to the event will see this information. Exceptions to this are the name of the contact person, email address and telephone."
                                }
                              >
                                info location{" "}
                                <img
                                  src="assets/images/Infoblack.png"
                                  alt=""
                                  className="mx-1 mb-4"
                                />
                              </th>
                              <th scope="col"></th>
                            </tr>
                            <tr className="border-0 bg-none invisible">
                              <td className="border-0 bg-none"></td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="form-group mb-3">
                                  <label className="header-label">Name</label>
                                  <div className="input-container">
                                    <input
                                      type="text"
                                      value={eventName}
                                      onChange={(e) =>
                                        handleChange(e, setEventName)
                                      }
                                      className="form-control p-0"
                                      placeholder="Name"
                                      name="confirmpassword"
                                      style={{ height: "40px" }}
                                    />
                                  </div>
                                </div>
                                <div className="form-group mb-3">
                                  <label className="header-label">{tab5}</label>
                                  <div className="input-container">
                                    <input
                                      type="date"
                                      value={date}
                                      onChange={(e) => handleChange(e, setDate)}
                                      className="form-control p-0"
                                      placeholder="Name"
                                      name="confirmpassword"
                                      style={{ height: "40px" }}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <p className="notranslate header-label">
                                    Get in :
                                    <input
                                      type="text"
                                      className="form-control p-0"
                                      value={getInStart}
                                      onChange={(e) =>
                                        handleChange(e, setGetInStart)
                                      }
                                      placeholder="start"
                                      style={{ height: "40px", width: "100px" }}
                                    />{" "}
                                  </p>
                                  <p className="notranslate header-label">
                                    Ende:
                                    <input
                                      type="text"
                                      className="form-control p-0"
                                      value={getInEnd}
                                      onChange={(e) =>
                                        handleChange(e, setGetInEnd)
                                      }
                                      placeholder={tab80}
                                      style={{ height: "40px", width: "100px" }}
                                    />
                                  </p>
                                </div>

                                <p>
                                  <label className="header-label">
                                    {tab45}
                                  </label>
                                  <textarea
                                    className="form-control p-0"
                                    placeholder={tab45}
                                    value={eventOtherInfo}
                                    onChange={(e) =>
                                      handleChange(e, setEventOtherInfo)
                                    }
                                    style={{ height: "100px" }}
                                  />
                                </p>
                              </td>
                              <td>
                                <p>
                                  <label className="header-label">Name</label>
                                  <input
                                    type="text"
                                    className="form-control p-0"
                                    value={locationName}
                                    onChange={(e) =>
                                      handleChange(e, setLocationName)
                                    }
                                    placeholder="Name"
                                    style={{ height: "40px" }}
                                  />
                                </p>
                                <p>
                                  <label className="header-label">
                                    {tab16}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control p-0"
                                    value={locationStreet}
                                    onChange={(e) =>
                                      handleChange(e, setLocationStreet)
                                    }
                                    placeholder={tab16}
                                    style={{ height: "40px" }}
                                  />
                                </p>
                                <p>
                                  <label className="header-label">
                                    {tab17}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control p-0"
                                    value={locationHouseNo}
                                    onChange={(e) =>
                                      handleChange(e, setLocationHouseNo)
                                    }
                                    placeholder={tab17}
                                    style={{ height: "40px" }}
                                  />
                                </p>

                                <p>
                                  <label className="header-label">
                                    {tab18}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control p-0"
                                    value={locationPostal}
                                    onChange={(e) =>
                                      handleChange(e, setLocationPostal)
                                    }
                                    placeholder={tab18}
                                    style={{ height: "40px" }}
                                  />{" "}
                                </p>
                                <p>
                                  <label className="header-label">
                                    {tab19}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control p-0"
                                    value={locationTown}
                                    onChange={(e) =>
                                      handleChange(e, setLocationTown)
                                    }
                                    placeholder={tab19}
                                    style={{ height: "40px" }}
                                  />
                                </p>
                                <p>
                                  <label className="header-label notranslate">
                                    Land
                                  </label>
                                  <Select
                                    className="form-control p-0 notranslate"
                                    options={options}
                                    placeholder="Land"
                                    onChange={changeHandler}
                                  />
                                </p>
                                {/* <p><input type="text" className="form-control p-0" value={locationContactPerson} onChange={(e) => handleChange(e, setLocationContactPerson)} placeholder="Name of contact person" style={{ height: "40px" }} /></p>
                                                            <p><input type="text" className="form-control p-0" value={locationEmail} onChange={(e) => handleChange(e, setLocationEmail)} placeholder="Email" style={{ height: "40px" }} /></p>
                                                            <p><input type="text" className="form-control p-0" value={locationPhone} onChange={(e) => handleChange(e, setLocationPhone)} placeholder="Phone" style={{ height: "40px" }} /></p>

                                                            <p><textarea className="form-control p-0" value={locationOtherInfo} onChange={(e) => handleChange(e, setLocationOtherInfo)} placeholder='Other information' style={{ height: '150px' }} /></p> */}
                              </td>
                              {/* <td> <p><textarea className="form-control p-0" placeholder='Other information' style={{ height: '150px' }} /></p></td> */}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* <div className="d-flex  my-3">
                                <button className="btn add-new" onClick={handleCancelEvent}>cancel event</button>
                            </div> */}
                    <div className="d-flex justify-content-end my-3">
                      {button ? (
                        <button
                          className="btn add-new"
                          onClick={handleEventInfo}
                        >
                          safe
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {band ? (
                  <>
                    <div className="col-sm-12">
                      <div className="pt-3 px-1 bg-white">
                        <div className="table-responsive">
                          <table className="table storetable">
                            <thead className="">
                              <tr>
                                <th
                                  scope="col"
                                  title={
                                    lng === "/auto/de" || "/en/de"
                                      ? "Benenen Sie hier die Rolle, in welcher Sie das Bandmitglied, für diese Veranstaltungen einladen möchten. Zum Beispiel Gesang, Bass, Gitarre usw. Die Rolle wir dem jeweiligen Bandmitglied angezeigt."
                                      : "Name the role in which you want to invite the band member for this events. For example, vocals, bass, guitar, etc. The role is displayed to each band member."
                                  }
                                >
                                  {tab22}
                                  <img
                                    src="assets/images/Infoblack.png"
                                    alt=""
                                    className="mx-1 mb-4"
                                  />
                                </th>
                                <th
                                  scope="col"
                                  title={
                                    lng === "/auto/de" || "/en/de"
                                      ? "Wählen Sie die Person aus, welche Sie für diese Rolle einladen möchten. Jede Person kann nur für jeweils eine Rolle eingeladen werden."
                                      : "Select the person you want to invite for this role. Each person can only be invited to one role at a time."
                                  }
                                >
                                  Person
                                  <img
                                    src="assets/images/Infoblack.png"
                                    alt=""
                                    className="mx-1 mb-4"
                                  />
                                </th>
                                <th
                                  scope="col"
                                  title={
                                    lng === "/auto/de" || "/en/de"
                                      ? "Sie können für eine Rolle mehrere Bandmitglieder nach priorisierung einladen, indem Sie mit dem Button „Musiker hinzufügen“ weitere Personen hinzufügen - dieses Bandmitglied wird nun mit Priorität 2 für diese Rolle angezeigt. Die Einladung wird vorerst nur an die Person mit der Priorisierung 1 versendet. Erst wenn diese Person die Terminanfrage ablehnt, erhält die Person mit Priorisierung 2 eine Einladung."
                                      : "You can invite several band members for a role by prioritizing them by adding more people with the Add musicians button - this band member is now displayed with priority 2 for this role. Initially, the invitation will only be sent to the person with priority 1. Only when this person rejects the appointment request does the person with priority 2 receive an invitation."
                                  }
                                >
                                  {tab46}
                                  <img
                                    src="assets/images/Infoblack.png"
                                    alt=""
                                    className="mx-1 mb-4"
                                  />
                                </th>

                                <th
                                  scope="col"
                                  title={
                                    lng === "/auto/de" || "/en/de"
                                      ? "Hier haben Sie die Möglichkeit, weitere Informationen mit der Terminanfrage, an die jeweiligen Bandmitglieder zu versenden. Diese Information ist nur für Sie und das jeweilige Bandmitglied sichtbar."
                                      : "Here you have the opportunity to send further information with the appointment request to the respective band members. This information is only visible to you and the respective band member."
                                  }
                                >
                                  {tab45}
                                  <img
                                    src="assets/images/Infoblack.png"
                                    alt=""
                                    className="mx-1 mb-4"
                                  />
                                </th>
                                <th scope="col">Action</th>
                                <th scope=""></th>
                              </tr>
                              <tr className="border-0 bg-none invisible">
                                <td className="border-0 bg-none"></td>
                              </tr>
                            </thead>
                            <tbody>
                              {loadsetupPersonList?.length !== 0
                                ? loadsetupPersonList.map((res, i) => {
                                    return (
                                      <>
                                        {" "}
                                        <tr key={i}>
                                          {res?.role === "" || res?.role ? (
                                            <td className="white-space tdWidth">
                                              <p>
                                                <input
                                                  type="text"
                                                  className="form-control p-0"
                                                  name="role"
                                                  data-id="test"
                                                  data-nested=""
                                                  value={res.role}
                                                  onChange={(e) =>
                                                    handleInputPerson(e, i)
                                                  }
                                                  placeholder={tab22}
                                                  style={{
                                                    height: "40px",
                                                    width: "250px",
                                                  }}
                                                />
                                              </p>
                                            </td>
                                          ) : (
                                            <td>
                                              <p></p>
                                            </td>
                                          )}
                                          <td className="white-space tdWidth">
                                            <p>
                                              <input
                                                type="text"
                                                className="form-control p-0"
                                                name="role"
                                                data-id="test"
                                                data-nested=""
                                                value={res.first_name}
                                                onChange={(e) =>
                                                  handleInputPerson(e, i)
                                                }
                                                placeholder={tab22}
                                                style={{
                                                  height: "40px",
                                                  width: "250px",
                                                }}
                                              />
                                            </p>
                                          </td>
                                          <td className="">
                                            <div className="d-flex">
                                              {" "}
                                              <input
                                                type="text"
                                                className="form-control p-0"
                                                name="priority"
                                                data-id="test"
                                                data-nested="nested"
                                                value={res.priority}
                                                onChange={(e) =>
                                                  handleInputPerson(e, i)
                                                }
                                                placeholder="1"
                                                style={{
                                                  height: "40px",
                                                  width: "150px",
                                                  border: "none",
                                                }}
                                              />{" "}
                                              <div className="">
                                                {/* <button className="btn add-new notiIcon filterIcon" style={{ width: "180px" }} onClick={(e) => handleAddPriority(e, res.role, i)}>add musician</button> */}
                                              </div>
                                            </div>
                                          </td>
                                          {/* <td className="white-space tdWidth"><div><input type="text" className="form-control p-0" name="amount" value={res.amount} onChange={(e) => handleInputPerson(e, i)} placeholder="150 Euro" style={{ height: "40px", width: "250px" }} /></div></td> */}

                                          <td className="tdWidth">
                                            <div>
                                              {" "}
                                              <input
                                                type="text"
                                                className="form-control p-0"
                                                name="additional_information"
                                                value={
                                                  res.additional_information
                                                }
                                                onChange={(e) =>
                                                  handleInputPerson(e, i)
                                                }
                                                placeholder={tab45}
                                                style={{
                                                  height: "40px",
                                                  width: "250px",
                                                }}
                                              />
                                            </div>
                                          </td>
                                          <td>
                                            {/* <div className="d-flex justify-content-end my-3">
                                                            <button className="btn add-new" onClick={() => handleSendInvite(res.user_id)} style={{ width: "200px" }}>Send Invitation</button>
                                                        </div> */}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })
                                : ""}
                              {personList.map((res, i) => {
                                return (
                                  <>
                                    {" "}
                                    <tr key={i}>
                                      {res?.role === "" || res?.role ? (
                                        <td className="white-space tdWidth">
                                          <p>
                                            <input
                                              type="text"
                                              className="form-control p-0"
                                              name="role"
                                              data-id="test"
                                              data-nested=""
                                              value={res.role}
                                              onChange={(e) =>
                                                handleInputPerson(e, i)
                                              }
                                              placeholder={tab22}
                                              style={{
                                                height: "40px",
                                                width: "250px",
                                              }}
                                            />
                                          </p>
                                        </td>
                                      ) : (
                                        <td>
                                          <p></p>
                                        </td>
                                      )}
                                      {res.person.map((item, index) => {
                                        if (index === 0) {
                                          return (
                                            <>
                                              <td className="white-space tdWidth">
                                                <p>
                                                  <select
                                                    className="form-control p-0 notranslate"
                                                    data-id="test"
                                                    data-nested="nested"
                                                    name="user_id"
                                                    value={item.first_name}
                                                    onChange={(e) =>
                                                      handleInputPerson(
                                                        e,
                                                        i,
                                                        index
                                                      )
                                                    }
                                                    style={{
                                                      height: "40px",
                                                      width: "250px",
                                                    }}
                                                  >
                                                    <option>
                                                      {lng === "/auto/de" ||
                                                      "/en/de"
                                                        ? "--Auswählen--"
                                                        : "--Select--"}
                                                    </option>
                                                    {bandmember.map(
                                                      (res, key) => {
                                                        return (
                                                          <option
                                                            value={res.user_id}
                                                            disabled={personSelectedList?.includes(
                                                              res.user_id
                                                            )}
                                                          >
                                                            {res.first_name}
                                                          </option>
                                                        );
                                                      }
                                                    )}
                                                  </select>
                                                </p>
                                              </td>
                                              <td className="">
                                                <div className="d-flex">
                                                  {" "}
                                                  <input
                                                    type="text"
                                                    className="form-control p-0"
                                                    name="priority"
                                                    data-id="test"
                                                    data-nested="nested"
                                                    value={item.priority}
                                                    onChange={(e) =>
                                                      handleInputPerson(
                                                        e,
                                                        i,
                                                        index
                                                      )
                                                    }
                                                    placeholder="1"
                                                    style={{
                                                      height: "40px",
                                                      width: "150px",
                                                      border: "none",
                                                    }}
                                                  />{" "}
                                                  <div className="">
                                                    <button
                                                      className="btn add-new notiIcon filterIcon"
                                                      style={{ width: "180px" }}
                                                      onClick={(e) =>
                                                        handleAddPriority(
                                                          e,
                                                          res.role,
                                                          i
                                                        )
                                                      }
                                                    >
                                                      {tab26}
                                                    </button>
                                                  </div>
                                                </div>
                                              </td>

                                              {/* <td className="white-space tdWidth"><div><input type="text" className="form-control p-0" name="amount" value={item.amount} onChange={(e) => handleInputPerson(e, i, index)} placeholder="150 Euro" style={{ height: "40px", width: "250px" }} /></div></td> */}

                                              <td className="tdWidth">
                                                <div>
                                                  {" "}
                                                  <input
                                                    type="text"
                                                    className="form-control p-0"
                                                    name="additional_information"
                                                    value={
                                                      item.additional_information
                                                    }
                                                    onChange={(e) =>
                                                      handleInputPerson(
                                                        e,
                                                        i,
                                                        index
                                                      )
                                                    }
                                                    placeholder={tab45}
                                                    style={{
                                                      height: "40px",
                                                      width: "250px",
                                                    }}
                                                  />
                                                </div>
                                              </td>
                                              <td
                                                onClick={() =>
                                                  handleRemovePerson(i, index)
                                                }
                                              >
                                                <img
                                                  src="assets/images/delete.png"
                                                  width="25px"
                                                  alt=""
                                                />
                                              </td>
                                              <td>
                                                {/* <div className="d-flex justify-content-end my-3">
                                                                            <button className="btn add-new" onClick={() => handleSendInvite(res.user_id)} style={{ width: "200px" }}>Send Invitation</button>
                                                                        </div> */}
                                              </td>
                                            </>
                                          );
                                        }
                                      })}
                                    </tr>
                                    {res.person.map((item, index) => {
                                      if (index > 0) {
                                        return (
                                          <tr>
                                            <td></td>
                                            <td className="white-space tdWidth">
                                              <p>
                                                {" "}
                                                <select
                                                  className="form-control p-0 notranslate"
                                                  data-id="test"
                                                  data-nested="nested"
                                                  name="user_id"
                                                  value={item.first_name}
                                                  onChange={(e) =>
                                                    handleInputPerson(
                                                      e,
                                                      i,
                                                      index
                                                    )
                                                  }
                                                  style={{
                                                    height: "40px",
                                                    width: "250px",
                                                  }}
                                                >
                                                  <option>
                                                    {lng === "/auto/de" ||
                                                    "/en/de"
                                                      ? "--Auswählen--"
                                                      : "--Select--"}
                                                  </option>
                                                  {bandmember.map(
                                                    (res, key) => {
                                                      return (
                                                        <option
                                                          value={res.user_id}
                                                          disabled={personSelectedList?.includes(
                                                            res.user_id
                                                          )}
                                                        >
                                                          {res.first_name}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </select>
                                              </p>
                                            </td>
                                            <td className="">
                                              <div className="d-flex">
                                                {" "}
                                                <input
                                                  type="text"
                                                  className="form-control p-0"
                                                  name="priority"
                                                  data-id="test"
                                                  data-nested="nested"
                                                  value={item.priority}
                                                  onChange={(e) =>
                                                    handleInputPerson(
                                                      e,
                                                      i,
                                                      index
                                                    )
                                                  }
                                                  placeholder="1"
                                                  style={{
                                                    height: "40px",
                                                    width: "150px",
                                                    border: "none",
                                                  }}
                                                />{" "}
                                                <div className="">
                                                  {/* <button className="btn add-new notiIcon filterIcon" style={{ width: "180px" }} onClick={(e) => handleAddPriority(e, res.role, i)}>add musician</button> */}
                                                </div>
                                              </div>
                                            </td>

                                            <td className="tdWidth">
                                              <div>
                                                {" "}
                                                <input
                                                  type="text"
                                                  className="form-control p-0"
                                                  name="additional_information"
                                                  value={
                                                    item.additional_information
                                                  }
                                                  onChange={(e) =>
                                                    handleInputPerson(
                                                      e,
                                                      i,
                                                      index
                                                    )
                                                  }
                                                  placeholder={tab45}
                                                  style={{
                                                    height: "40px",
                                                    width: "250px",
                                                  }}
                                                />
                                              </div>
                                            </td>
                                            <td
                                              onClick={() =>
                                                handleRemovePerson(i, index)
                                              }
                                            >
                                              <img
                                                src="assets/images/delete.png"
                                                width="25px"
                                                alt=""
                                              />
                                            </td>
                                          </tr>
                                        );
                                      }
                                    })}
                                  </>
                                );
                              })}

                              <button
                                className="btn add-new notiIcon filterIcon"
                                onClick={handleAddPerson}
                              >
                                {tab27}
                              </button>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3 align-items-center">
                      <div className="col-8 col-sm-9 col-md-9 col-lg-9">
                        <ul
                          className="nav nav-pills group-nav tabNabs"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a className="nav-link active">{tab29}</a>
                          </li>
                        </ul>
                      </div>
                      {/* <div className="col-4 col-sm-3 col-md-3 col-lg-3">
                                    <div className="d-flex align-items-center lg-justify-content-end">
                                        <button className="btn add-new notiIcon filterIcon" onClick={handleAddBandPerson} >add</button>
                                    </div>
                                </div> */}

                      <div className="col-sm-12">
                        <div className="pt-3 px-1 bg-white">
                          <div className="table-responsive">
                            <table className="table storetable">
                              <thead className="">
                                <tr>
                                  <th
                                    scope="col"
                                    title={
                                      lng === "/auto/de" || "/en/de"
                                        ? "Benenen Sie hier die Rolle, in welcher Sie das Bandmitglied, für diese Veranstaltungen einladen möchten. Zum Beispiel Gesang, Bass, Gitarre usw. Die Rolle wir dem jeweiligen Bandmitglied angezeigt."
                                        : "Name the role in which you want to invite the band member for this events. For example, vocals, bass, guitar, etc. The role is displayed to each band member."
                                    }
                                  >
                                    {tab22}
                                    <img
                                      src="assets/images/Infoblack.png"
                                      alt=""
                                      className="mx-1 mb-4"
                                    />
                                  </th>
                                  <th
                                    scope="col"
                                    title={
                                      lng === "/auto/de" || "/en/de"
                                        ? "Wählen Sie die Person aus, welche Sie für diese Rolle einladen möchten. Jede Person kann nur für jeweils eine Rolle eingeladen werden."
                                        : "Select the person you want to invite for this role. Each person can only be invited to one role at a time."
                                    }
                                  >
                                    Person
                                    <img
                                      src="assets/images/Infoblack.png"
                                      alt=""
                                      className="mx-1 mb-4"
                                    />
                                  </th>

                                  <th
                                    scope="col"
                                    title={
                                      lng === "/auto/de" || "/en/de"
                                        ? "Hier haben Sie die Möglichkeit, weitere Informationen mit der Terminanfrage, an die jeweiligen Bandmitglieder zu versenden. Diese Information ist nur für Sie und das jeweilige Bandmitglied sichtbar."
                                        : "Here you have the opportunity to send further information with the appointment request to the respective band members. This information is only visible to you and the respective band member."
                                    }
                                  >
                                    {tab45}
                                    <img
                                      src="assets/images/Infoblack.png"
                                      alt=""
                                      className="mx-1 mb-4"
                                    />
                                  </th>
                                  <th scope="col">Action</th>
                                  <th scope=""></th>
                                </tr>
                                <tr className="border-0 bg-none invisible">
                                  <td className="border-0 bg-none"></td>
                                </tr>
                              </thead>
                              <tbody>
                                {bandStaffList.map((res, i) => {
                                  return (
                                    <>
                                      <tr>
                                        {res?.role === "" || res?.role ? (
                                          <td className="white-space tdWidth">
                                            <input
                                              type="text"
                                              name="role"
                                              value={res.role}
                                              onChange={(e) =>
                                                handleInputBand(e, i)
                                              }
                                              className="form-control p-0"
                                              style={{
                                                height: "40px",
                                                width: "250px",
                                              }}
                                              placeholder="Role"
                                            />
                                          </td>
                                        ) : (
                                          <td>
                                            <p></p>
                                          </td>
                                        )}
                                        <td className="white-space tdWidth">
                                          <select
                                            className="form-control p-0 notranslate"
                                            data-id="test"
                                            data-nested="nested"
                                            name="user_id"
                                            value={res.user_id}
                                            onChange={(e) =>
                                              handleInputBand(e, i)
                                            }
                                            style={{
                                              height: "40px",
                                              width: "250px",
                                            }}
                                          >
                                            <option>
                                              {lng === "/auto/de" || "/en/de"
                                                ? "--Auswählen--"
                                                : "--Select--"}
                                            </option>
                                            {bandmember2.map((res, key) => {
                                              return (
                                                <option value={res.user_id}>
                                                  {res.first_name}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </td>
                                        {/* <td className="white-space tdWidth"><input type="text" name="name" value={res.name} onChange={(e) => handleInputBand(e, i)} className="form-control p-0" style={{ height: "40px", width: "250px" }} placeholder="Name" /></td> */}
                                        {/* <td className="white-space tdWidth"><input type="text" name="email" value={res.email} onChange={(e) => handleInputBand(e, i)} className="form-control p-0" style={{ height: "40px", width: "250px" }} placeholder="Email" /></td> */}
                                        {/* <td className="white-space tdWidth"><input type="text" name="amount" value={res.amount} onChange={(e) => handleInputBand(e, i)} className="form-control p-0" style={{ height: "40px", width: "250px" }} placeholder="150 Euro" /></td> */}
                                        <td className="tdWidth">
                                          <div>
                                            {" "}
                                            <input
                                              type="text"
                                              className="form-control p-0"
                                              name="description"
                                              value={res.description}
                                              onChange={(e) =>
                                                handleInputBand(e, i)
                                              }
                                              placeholder={tab45}
                                              style={{
                                                height: "40px",
                                                width: "250px",
                                              }}
                                            />
                                          </div>
                                        </td>
                                        <td
                                          onClick={() => handleRemoveStaff(i)}
                                        >
                                          <img
                                            src="assets/images/delete.png"
                                            width="25px"
                                            alt=""
                                          />
                                        </td>
                                        {/* <div className="d-flex justify-content-end my-3">
                                                                        <button className="btn add-new" onClick={() => handleSendInvite(res.user_id)} style={{ width: "200px" }}>Send Invitation</button>
                                                                    </div> */}
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                              <button
                                className="btn add-new notiIcon filterIcon mt-3"
                                onClick={handleAddBandPerson}
                              >
                                {tab26}
                              </button>
                            </table>
                          </div>
                        </div>
                        <div className="d-flex my-3">
                          <button
                            className="btn add-new1"
                            onClick={handleCancelEvent}
                          >
                            {tab83}
                          </button>
                          <button
                            className="btn add-new1"
                            onClick={handleShowLoadsetup}
                          >
                            {tab50}
                          </button>
                        </div>
                        {addMusicianShow ? (
                          <div className="d-flex justify-content-end my-3">
                            <button
                              className="btn add-new"
                              onClick={handleAddMusicians}
                            >
                              {lng === "/auto/de" || "/en/de"
                                ? "SPEICHERN"
                                : "Safe"}
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </article>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showCancelModal ? (
        <SafeSetup
          show={showCancelModal}
          handleShow={handleShowCancel}
          personList={personList}
        />
      ) : (
        ""
      )}
      {showLoadsetup ? (
        <LoadSetup
          show={showLoadsetup}
          handleShow={handleShowLoadsetup}
          setLoadsetupPersonList={setLoadsetupPersonList}
          setNewLoad={setNewLoad}
        />
      ) : (
        ""
      )}
      {invitationShow ? (
        <InvitationModal
          show={invitationShow}
          handleShow={handleShowInvitation}
          eventid={event_id}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AddRehearealEvent;
