import React from "react";
import { Link, useLocation } from "react-router-dom";
import EventNavbar from "./eventNavbar";

const EventBand = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        <div className="row mt-3 align-items-center">
          <div className="col-12 col-sm-9 col-md-9 col-lg-9">
            <ul
              className="nav nav-pills group-nav tabNabs"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item">
                <Link
                  to="/event-dates-info"
                  className={
                    splitLocation[1] === "event-dates-info"
                      ? "nav-link  active"
                      : "nav-link"
                  }
                >
                  information
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/event-band"
                  className={
                    splitLocation[1] === "event-band"
                      ? "nav-link  active"
                      : "nav-link"
                  }
                >
                  band
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/event-transport"
                  className={
                    splitLocation[1] === "event-transport"
                      ? "nav-link  active"
                      : "nav-link"
                  }
                >
                  transport
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/event-setlist"
                  className={
                    splitLocation[1] === "event-setlist"
                      ? "nav-link  active"
                      : "nav-link"
                  }
                >
                  setlist
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/event-attachment"
                  className={
                    splitLocation[1] === "event-attachment"
                      ? "nav-link  active"
                      : "nav-link"
                  }
                >
                  attachments
                </Link>
              </li>
            </ul>
          </div>
          <div class="col-12 col-sm-3 col-md-3 col-lg-3">
            <div class="d-flex align-items-center lg-justify-content-end">
              <button class="btn add-new notiIcon filterIcon">add</button>
              <button class="btn notiIcon filterIcon">
                <img src="assets/images/attachment2.png" alt="" />
              </button>
              <button class="btn notiIcon filterIcon">
                <img src="assets/images/chatBlackRed.png" alt="" />
              </button>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="pt-3 px-1 bg-white">
              <div className="table-responsive">
                <table className="table storetable">
                  <thead className="">
                    <tr>
                      <th scope="col">band</th>
                      <th scope="col">choose musician for role</th>
                      <th scope="col">additional information</th>
                    </tr>
                    <tr className="border-0 bg-none invisible">
                      <td className="border-0 bg-none"></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="white-space tdWidth">
                        <div>robert francline band</div>
                      </td>
                      <td className="white-space tdWidth">
                        <div>singing</div>
                      </td>
                      <td className="tdWidth">
                        <div>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="white-space tdWidth">
                        <div>robert francline band</div>
                      </td>
                      <td className="white-space tdWidth">
                        <div>singing</div>
                      </td>
                      <td className="tdWidth">
                        <div>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="white-space tdWidth">
                        <div>robert francline band</div>
                      </td>
                      <td className="white-space tdWidth">
                        <div>singing</div>
                      </td>
                      <td className="tdWidth">
                        <div>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="white-space tdWidth">
                        <div>robert francline band</div>
                      </td>
                      <td className="white-space tdWidth">
                        <div>singing</div>
                      </td>
                      <td className="tdWidth">
                        <div>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3 align-items-center">
          <div className="col-8 col-sm-9 col-md-9 col-lg-9">
            <ul
              className="nav nav-pills group-nav tabNabs"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item">
                <a className="nav-link active">staff</a>
              </li>
            </ul>
          </div>
          <div className="col-4 col-sm-3 col-md-3 col-lg-3">
            <div className="d-flex align-items-center lg-justify-content-end">
              <button className="btn add-new notiIcon filterIcon">add</button>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="pt-3 px-1 bg-white">
              <div className="table-responsive">
                <table className="table storetable">
                  <thead className="">
                    <tr>
                      <th scope="col">band</th>
                      <th scope="col">choose musician for role</th>
                      <th scope="col">additional information</th>
                    </tr>
                    <tr className="border-0 bg-none invisible">
                      <td className="border-0 bg-none"></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="white-space tdWidth">
                        <div>robert francline band</div>
                      </td>
                      <td className="white-space tdWidth">
                        <div>singing</div>
                      </td>
                      <td className="tdWidth">
                        <div>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-end my-3">
              <button className="btn add-new">send invitation</button>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default EventBand;
