import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSongs } from "../../app_redux/slice/songList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteSongModal from "./deleteSong";
import { requestForToken } from "../../firebase";
import ExcelUpload from "./excelUpload";
import DataLoader from "../Loader/dataLoader";
import { useTranslation } from "react-i18next";

const Songs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const lng = cookee.get("googtrans");
  const token = cookee.get("token");
  const { allSongs, loadingSongs } = useSelector((state) => state.song);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [songId, setSongId] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [excelShow, setExcelShow] = useState(false);
  const {
    tab1,
    tab2,
    tab3,
    tab4,
    tab11,
    tab12,
    tab13,
    tab25,
    tab26,
    tab28,
    tab29,
  } = t("songs");

  const [songs, setSongs] = useState([]);
  useEffect(() => {
    dispatch(getSongs({ pageno: pageNo }));
  }, []);
  useEffect(() => {
    if (allSongs) {
      setSongs(allSongs);
    }
  }, [allSongs]);
  useEffect(() => {
    SubscribeUser();
  }, []);

  const handleExcelShow = () => {
    setExcelShow(!excelShow);
  };

  const handleDeleteSelect = (e) => {
    const { name, checked } = e.target;

    if (name === "allSongs") {
      let tempSongs = songs?.map((song) => {
        return { ...song, isChecked: checked };
      });
      setSongs(tempSongs);
    } else {
      let tempSongs = songs?.map((song) =>
        song?.m_song_id == name ? { ...song, isChecked: checked } : song
      );
      setSongs(tempSongs);
    }
  };

  // console.log("Songs", songs)

  const SubscribeUser = () => {
    try {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          registerServiceWorker();
        } else if (permission !== "denied") {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              registerServiceWorker();
            }
          });
        }
      });
    } catch (error) {
      if (error instanceof TypeError) {
        console.log("Not supported");
      } else {
        throw error;
      }
    }
  };

  const registerServiceWorker = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("firebase-messaging-sw.js", {
          scope: `${process.env.PUBLIC_URL}/`,
          updateViaCache: "none",
        })
        .then(function (registration) {
          requestForToken(registration);
        })
        .catch(function (err) {
          console.log("Service worker registration failed, error:", err);
        });
    }
  };
  const handleShowDelete = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const handleNext = (e) => {
    e.preventDefault();
    setPageNo(pageNo + 1);
    dispatch(getSongs({ pageno: pageNo + 1 }));
  };
  const handlePrevious = (e) => {
    e.preventDefault();
    setPageNo(pageNo - 1);
    dispatch(getSongs({ pageno: pageNo - 1 }));
  };

  const handleEdit = (id) => {
    navigate("/edit-song", { state: { data: { id: id } } });

    // axios({
    //     method: 'post',
    //     headers: {
    //         "Accept": "/",
    //         "Content-Type": "application/json",
    //         "auth_token": token
    //     },
    //     url: `${process.env.REACT_APP_URL}/band-leader-song-details`,
    //     data:
    //     {
    //         "song_id": id
    //     }
    // })
    //     .then(res => {
    //         if (res.data.message === "Song Details Fetched Successfully!.") {
    //             localStorage.setItem("songData", JSON.stringify(res.data.data))
    //             navigate("/edit-song")
    //         }
    //     })
  };

  const handleDelete = (id) => {
    setSongId(id);
    handleShowDelete();
  };
  return (
    <article className="col-lg-9">
      <div className="article-body store-bd-gy">
        {loadingSongs ? (
          <DataLoader />
        ) : (
          <div className="row mt-3 align-items-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="d-flex align-items-center lg-justify-content-end">
                <Link to="/add-song">
                  <button className="btn add-new">{tab1}</button>
                </Link>
                {/* <Link to="/songs-upload"> */}
                <button className="btn add-new" onClick={handleExcelShow}>
                  Excel Upload
                </button>
                {/* </Link> */}
              </div>
            </div>
            <div className="col-sm-12">
              <div className="pt-3 px-1 bg-white">
                <div className="table-responsive">
                  <table className="table storetable text-center">
                    <thead className="">
                      <tr>
                        <th scope="col">name</th>
                        <th scope="col">{tab2}</th>
                        <th scope="col">
                          {tab3}{" "}
                          <img
                            src="assets/images/Infoblack.png"
                            alt=""
                            className="mx-1 mb-5"
                            title="Hier wird Ihnen signalisiert, ob Urheberrechte bei Bandmitglieder liegen. Ist das Icon grau hinterlegt, liegen keine Rechte bei Bandmitgliedern Here it is signaled to you whether copyrights belong to band members. If the icon has a gray background, the band members have no copyrights."
                          />{" "}
                        </th>
                        <th scope="col">
                          <label className="container-select  mt-3">
                            <input
                              type="checkbox"
                              className="mt- mx-2"
                              name="allSongs"
                              onChange={handleDeleteSelect}
                              checked={
                                songs.filter((song) => song?.isChecked !== true)
                                  .length < 1
                              }
                            />
                            <span className="checkmark"></span>
                          </label>

                          <img
                            src="assets/images/delete.png"
                            width="20px"
                            alt=""
                            className="mx-2"
                            onClick={() => handleDelete("id")}
                          />
                        </th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {songs?.length !== 0 ? (
                        songs.map((res, key) => {
                          return (
                            <tr>
                              <td
                                onClick={() => handleEdit(res.m_song_id)}
                                className="notranslate"
                                style={{ cursor: "pointer" }}
                              >
                                {res.song_name}
                              </td>
                              <td>{res.time_length?.substr(3, 6)}</td>
                              <td>
                                <img
                                  src={
                                    res.is_copyright === 1
                                      ? "assets/images/copyRed.png"
                                      : "assets/images/copyBlack.png"
                                  }
                                  width="25px"
                                  alt=""
                                />
                              </td>
                              {/* <td style={{ cursor: "pointer" }}><img src="assets/images/delete.png" width="25px" alt="" onClick={() => handleDelete(res.m_song_id)} />
                                                    </td> */}
                              <label className="container-select mt-3">
                                <input
                                  type="checkbox"
                                  className="mt-3"
                                  name={res?.m_song_id}
                                  checked={res?.isChecked || false}
                                  onChange={handleDeleteSelect}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </tr>
                          );
                        })
                      ) : (
                        <p className="mt-3">{tab4}</p>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {pageNo === 1 && allSongs?.length <= 9 ? (
              ""
            ) : (
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                <div className="d-flex align-items-center lg-justify-content-end">
                  <nav aria-label="...">
                    <ul className="pagination">
                      <li
                        className={
                          pageNo === 1 ? "page-item disabled" : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={handlePrevious}
                        >
                          {tab29}
                        </a>
                      </li>
                      <li class="page-item active">
                        <a className="page-link" href="#">
                          {pageNo} <span className="sr-only">(current)</span>
                        </a>
                      </li>
                      <li
                        class={
                          allSongs?.length <= 9
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <a className="page-link" href="#" onClick={handleNext}>
                          {tab28}
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {excelShow ? (
        <ExcelUpload
          show={excelShow}
          handleShow={handleExcelShow}
          pageNo={pageNo}
        />
      ) : (
        ""
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showDeleteModal ? (
        <DeleteSongModal
          show={showDeleteModal}
          handleShow={handleShowDelete}
          id={songs
            ?.filter((song) => song?.isChecked === true)
            .map((song) => {
              return song?.m_song_id;
            })}
          pageNo={pageNo}
          tab11={tab11}
          tab12={tab12}
          tab13={tab13}
          tab26={tab26}
        />
      ) : (
        ""
      )}
    </article>
  );
};

export default Songs;
