import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getBandStaff } from "../../app_redux/slice/bandstaffList";
import { useTranslation } from "react-i18next";

const ApproveModal = ({ show, handleShow, id, permissionId, tab8 }) => {
  const { t } = useTranslation();
  const { tab2, tab4, tab5, tab6, tab7 } = t("extra");
  const dispatch = useDispatch();
  const cookee = new Cookies();
  const token = cookee.get("token");
  const [permission, setPermission] = useState("");

  const handlePermission = (e) => {
    setPermission(e.target.value);
  };

  const handleUpdate = () => {
    axios({
      method: "post",
      headers: {
        Accept: "/",
        "Content-Type": "application/json",
        auth_token: token,
      },
      url: `${process.env.REACT_APP_URL}/change-band-staff-permission`,
      data: {
        staff_id: id,
        permission_type: permission,
        permission_detail_id: permissionId,
        is_approved_band: 1,
        is_rejected: 0,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            theme: "colored",
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          dispatch(getBandStaff({ pageno: 1, search: "" }));
          handleShow();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          theme: "colored",
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <>
      <Modal show={show} onHide={handleShow} size="md" centered>
        <Modal.Body className="p-0">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close close-Icon"
              onClick={handleShow}
            ></button>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mt-4">
                <div className="text-center mx-2">
                  <h6>{tab4}</h6>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-2 text-center mt-3">
                <p>{tab5}</p>
                <p>
                  <select
                    className="form-control  mt-1"
                    onChange={(e) => handlePermission(e)}
                  >
                    <option>{tab6}</option>
                    <option value="1">Musician</option>
                    <option value="2">Bandleader</option>
                    <option value="3">Staff</option>
                    <option value="4">Manager</option>
                    <option value="5">Booker</option>
                  </select>
                </p>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-2">
                <div className=" text-center my-3 mt-5">
                  <button className="btn add-new-cancel" onClick={handleShow}>
                    {tab2}
                  </button>
                  <button className="btn add-new-del" onClick={handleUpdate}>
                    {tab7}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ApproveModal;
